;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.storesettings')
    .service('DeliveryTimesService', DeliveryTimesService)
    .service('PreorderLimitService', PreorderLimitService)

  function DeliveryTimesService(CONFIG, $resource) {
    this.getDelay = getDelay
    this.setDelay = setDelay
    this.getTimes = getTimes
    this.setTimes = setTimes

    var API = $resource(CONFIG.API_URL + '/storesetting/:customerId/:action/:storeId', {
      customerId: 0,
      storeId: 0,
    })

    function getDelay(params) {
      params = angular.copy(params)
      params.action = 'delaymessage'
      return API.get(params).$promise
    }
    function setDelay(params, delay) {
      params = angular.copy(params)
      params.action = 'delaymessage'
      return API.update(params, delay).$promise
    }

    function getTimes(params) {
      params = angular.copy(params)
      params.action = 'deliverytime'
      return API.get(params).$promise
    }
    function setTimes(params, times) {
      params = angular.copy(params)
      params.action = 'deliverytime'
      return API.save(params, times).$promise
    }
  }

  function PreorderLimitService(CONFIG, $resource) {
    this.getSettings = getSettings

    var Settings = $resource(CONFIG.API_URL + '/preorder/:storeId/settings', { storeId: 0 })

    function getSettings(params) {
      return Settings.get(params).$promise
    }
  }
})()
