(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.servicecenter', [
      'angular-svg-round-progressbar',
      'om-faxjobs',
      'om-ordertable',
      'om-toolbar',
    ])
    .config(function ($stateProvider, moduleRegisterProvider) {
      $stateProvider.state('servicecenter', {
        url: '/servicecenter',
        abstract: true,
        views: {
          '@': {
            templateUrl: 'src/servicecenter/views/layout.html',
          },
        },
      }).state('servicecenter.attention', {
        url: '/attention',
        views: {
          colMain: {
            templateUrl: 'src/servicecenter/views/attention.html',
            controller: 'ServicecenterAttentionController',
          },
        },
      }).state('servicecenter.archive', {
        url: '/archive/:id',
        views: {
          colMain: {
            templateUrl: 'src/servicecenter/views/archive.html',
            controller: 'ServicecenterArchiveController',
          },
        },
        params: {
          id: {
            dynamic: true,
            squash: true,
            type: 'string',
            value: null,
          },
        },
      }).state('servicecenter.contacts', {
        url: '/contacts',
        views: {
          colMain: {
            templateUrl: 'src/servicecenter/views/contacts.html',
            controller: 'ServicecenterContactsController',
          },
        },
      }).state('servicecenter.pinboard', {
        url: '/pinboard',
        views: {
          colMain: {
            templateUrl: 'src/servicecenter/views/pinboard.html',
            controller: 'ServicecenterPinboardController',
          },
        },
      }).state('servicecenter.takeover', {
        url: '/takeover',
        views: {
          colMain: {
            templateUrl: 'src/servicecenter/views/takeover.html',
            controller: 'TakeoverController',
          },
        },
      }).state('servicecenter.testorder', {
        url: '/testorder',
        views: {
          colMain: {
            templateUrl: 'src/servicecenter/views/testorder.html',
            controller: 'TestorderController',
          },
        },
      })

      moduleRegisterProvider.add({
        id: 'servicecenter',
        title: 'Service Center',
        defaultUrl: 'servicecenter.attention',
        materialIcon: 'headset_mic',
        accessRoles: ['admin', 'servicecenter'],
        menu: [
          { name: 'SC Bestellungen', url: 'servicecenter.attention' },
          { name: 'Archiv', url: 'servicecenter.archive' },
          { name: 'Pinnwand', url: 'servicecenter.pinboard' },
          { name: 'Kontakt- und Kundendaten', url: 'servicecenter.contacts' },
          { name: 'Benutzer-Übernahme', url: 'servicecenter.takeover' },
          { name: 'Testbestellung', url: 'servicecenter.testorder' },
        ],
      })
    })
    .factory('ServicecenterService', function ($resource, CONFIG, helperService) {
      var allowRestartTimestamp = Date.now()
      var cancellable = {}
      var magentoBaseUrl = ''

      var service = {
        allowRestart: allowRestart,
        cancelAll: cancelAll,

        getContacts: getContacts,

        getIncidents: getIncidents,

        getMagentoBaseUrl: getMagentoBaseUrl,
        setMagentoBaseUrl: setMagentoBaseUrl,

        getAttentionOrders: getAttentionOrders,
        getByOrderId: getByOrderId,
        filterOrders: filterOrders,

        getOtsDetail: getOtsDetail,
        getOtsStatus: getOtsStatus,

        getServicecenterStatus: getServicecenterStatus,
        setServicecenterStatus: setServicecenterStatus,
      }

      function allowRestart (timestamp) {
        if (timestamp != null) {
          allowRestartTimestamp = timestamp
        }
        return allowRestartTimestamp
      }

      function cancelAll (status, page) {
        allowRestartTimestamp = Date.now()
        angular.forEach(cancellable, function (resources, key) {
          if (key === page || key === 'default' || page == null) {
            angular.forEach(resources, function (resource) {
              resource.$cancelRequest(status)
            })
          }
        })
        if (page == null) {
          cancellable = {}
        } else {
          delete cancellable[page]
          delete cancellable.default
        }
      }

      function registerCancellable (resource, page) {
        if (page == null) {
          page = 'default'
        }
        if (cancellable[page] == null) {
          cancellable[page] = []
        }
        cancellable[page].push(resource)
        return resource
      }

      function getMagentoBaseUrl () {
        return magentoBaseUrl
      }

      function setMagentoBaseUrl (url) {
        magentoBaseUrl = url
      }

      // RESOURCES

      var CONTACTS = $resource(CONFIG.API_URL + '/customers/:customerId/stores/:storeId/contact')

      function getContacts (customerId, storeId) {
        return CONTACTS.query({ customerId: customerId, storeId: storeId }).$promise
      }

      var INCIDENT = $resource(CONFIG.API_URL + '/incident', { hideGlobalSpinner: true })

      function getIncidents () {
        return INCIDENT.query().$promise
      }

      var orderTransformAction = {
        method: 'GET',
        cancellable: true,
        transformResponse: helperService.appendTransform(helperService.orderTransformation),
      }
      var ORDERS = {
        attention: $resource(CONFIG.API_URL + '/orders/attention/:customerId', { hideGlobalSpinner: true }, { query: orderTransformAction }),
        id: $resource(CONFIG.API_URL + '/orders/id/:orderId', {}, { query: orderTransformAction }),
        search: $resource(CONFIG.API_URL + '/orders/search', {}, { query: orderTransformAction }),
      }

      function getAttentionOrders (customerId, page, limit, storeId) {
        var params = {
          customerId: customerId,
          storeId: storeId,
          limit: limit,
          page: page,
        }
        return registerCancellable(ORDERS.attention.query(params), 'attention').$promise
      }

      function getByOrderId (paramsOrOrderId) {
        var params = {}
        if (angular.isObject(paramsOrOrderId)) {
          params = paramsOrOrderId
        } else {
          params.orderId = paramsOrOrderId
        }
        return registerCancellable(ORDERS.id.query(params)).$promise
      }

      function filterOrders (params) {
        return registerCancellable(ORDERS.search.query(params), 'archive').$promise
      }

      var OTS = {
        detail: $resource(CONFIG.API_URL + '/orders/otsdetail/:orderId', { hideGlobalSpinner: true }, {}, { cancellable: true }),
        status: $resource(CONFIG.API_URL + '/orders/otsstatus/:orderId', { hideGlobalSpinner: true }, {}, { cancellable: true }),
      }

      function getOtsDetail (orderId) {
        return registerCancellable(OTS.detail.get({ orderId: orderId })).$promise
      }

      function getOtsStatus (orderId) {
        return registerCancellable(OTS.status.get({ orderId: orderId })).$promise
      }

      var SERVICECENTER = $resource(CONFIG.API_URL + '/orders/servicecenterstatus/:orderId', { hideGlobalSpinner: true }, {}, { cancellable: true })

      function getServicecenterStatus (orderId) {
        return registerCancellable(SERVICECENTER.get({ orderId: orderId })).$promise
      }

      function setServicecenterStatus (orderId, data) {
        return SERVICECENTER.save({ orderId: orderId }, data).$promise
      }

      return service
    })
    .factory('PinboardService', function ($filter, $resource, CONFIG) {
      var Pinboard = $resource(CONFIG.API_URL + '/pinboard/:id')

      return {
        get: get,
        getAll: getAll,
        create: create,
        update: update,
        delete: remove,
      }

      function get (id) {
        return Pinboard.get({ id: id }).$promise
          .then(function (pinboard) {
            return postProcess(pinboard)
          })
      }

      function getAll (params) {
        return Pinboard.query(params).$promise
          .then(function (pinboard) {
            return postProcess(pinboard)
          })
      }

      function create (entry) {
        return Pinboard.save(preProcess(entry)).$promise
          .then(function (pinboard) {
            return postProcess(pinboard)
          })
      }

      function update (entry) {
        return Pinboard.update({ id: entry.id }, preProcess(entry)).$promise
          .then(function (pinboard) {
            return postProcess(pinboard)
          })
      }

      function remove (entry) {
        return Pinboard.delete({ id: entry.id }).$promise
      }

      function preProcess (entry) {
        if (entry.activeFrom) {
          entry.activeFrom = $filter('date')(entry.activeFrom, 'yyyy-MM-dd')
        }
        if (entry.activeTo) {
          entry.activeTo = $filter('date')(entry.activeTo, 'yyyy-MM-dd')
        }
        if (entry.customer) {
          entry.customers = [entry.customer]
          delete entry.customer
        }
        return entry
      }

      function postProcess (data) {
        if (angular.isArray(data)) {
          data.forEach(postProcess)
        } else {
          if (data.customers) {
            data.customer = data.customers[0]
            delete data.customers
          }
        }
        return data
      }
    })
    .factory('TakeoverService', function (CONFIG, $resource) {
      var Takeover = $resource(getUrl(), null, null, { cancellable: true })

      return {
        getUrl: getUrl,
        queryUsers: queryUsers,
        takeoverUser: takeoverUser,
      }

      function getUrl () {
        return CONFIG.BASE_URL + '/user/takeover'
      }

      var queryRequest
      function queryUsers (query) {
        if (queryRequest) {
          queryRequest.$cancelRequest('silent')
        }
        queryRequest = Takeover.query({ query: query })
        return queryRequest.$promise
      }

      function takeoverUser (user) {
        return Takeover.update({ userId: user.userId }).$promise
      }
    })
    .factory('PapiService', function (CONFIG, $resource) {
      var service = {
        placeTestOrder: placeTestOrder,
      }

      var PAPI = $resource(CONFIG.BASE_URL + '/papi/:customerId/:action', { RAW_URL: true })

      function placeTestOrder (customerId, storeId) {
        var params = {
          action: 'order/test',
          customerId: customerId,
          storeId: storeId,
        }
        return PAPI.get(params).$promise
      }

      return service
    })
    .controller('ServicecenterAttentionController', function ServicecenterAttentionController ($scope, $mdSidenav, ServicecenterService, CustomerService, $interval) {
      var ctrl = this

      $scope.btnOrderDetails = function (customer, order) {
        ServicecenterService.cancelAll('restart')
        $scope.$root.customer = customer
        $scope.$root.magentoBaseUrl = ServicecenterService.getMagentoBaseUrl()
        $scope.$root.order = order
        $mdSidenav('orderDetails').toggle().then(function () {
          ServicecenterService.allowRestart(Date.now())
          document.querySelector('#orderdetails > md-content').scrollTop = 0
        })
      }

      $scope.$on('$destroy', function () {
        ServicecenterService.cancelAll('silent', 'attention')
        $interval.cancel(ctrl.incidentInterval)
      })

      $scope.incidentTranslation = {
        WARNING: {
          text: 'Warnung',
          color: 'yellow',
        },
        CRITICAL: {
          text: 'Kritisch',
          color: 'red',
        },
        DOWN: {
          text: 'Nicht erreichbar',
          color: 'purple',
        },
      }

      $scope.hideStates = {}
      $scope.toggleHideState = function (state) {
        $scope.hideStates[state] = !$scope.hideStates[state]
        ctrl.filterIncidents()
      }

      ctrl.replaceIncidentText = function (text) {
        if (text === 'PING CRITICAL - Packet loss = 100%') {
          return 'OSM Station nicht erreichbar – möglicherweise ist der Store offline. Bitte telefonisch nachfragen!'
        }
        return text
      }

      ctrl.filterIncidents = function () {
        $scope.incidents = $scope.allIncidents.filter(function (incident) {
          return !$scope.hideStates[incident.state]
        })
      }

      ctrl.getIncidents = function () {
        ServicecenterService.getIncidents().then(function (incidents) {
          $scope.allIncidents = incidents.map(function (incident) {
            incident.message = ctrl.replaceIncidentText(incident.hoststatetype)
            return incident
          })
          ctrl.filterIncidents()
        })
      }

      ctrl.incidentInterval = $interval(ctrl.getIncidents, 15000)
      ctrl.getIncidents()

      CustomerService.getCustomers().$promise.then(function (customers) {
        $scope.customers = customers.filter(function (customer) {
          var show = false
          customer.customerSystemAssociations.forEach(function (association) {
            show = show || association.sc
          })
          return show
        })
      }, function () {
        $scope.noCustomers = true
      })
    })
    .controller('ServicecenterArchiveController', function ServicecenterArchiveController ($filter, $mdDialog, $mdSidenav, $scope, $state, $stateParams, $window, CustomerService, LocalStorageService, OrderService, ServicecenterService, StoreService, UserService) {
      $scope.STATUS_COMBINED_LOADING = true
      $scope.$root.showLinkPhonenumberCRM = UserService.hasRole(['admin', 'crm'])

      $scope.filter = {
        sidenavId: 'sc-archive-filter',
        collection: {},
        pagination: {
          limit: null,
          page: null,
          update: function (pagination) {
            var isInit = this.limit == null
            this.limit = pagination.limit
            this.page = pagination.page
            if (!isInit) {
              $scope.searchOrders()
            }
          },
        },
        init: function () {
          this.collection.dateFrom = {
            name: 'Start-Datum',
            type: 'date',
            quick: true,
            init: new Date(new Date() - 24 * 60 * 60 * 1000),
            max: new Date(),
            change: function () {
              var dateTo = $scope.filter.collection.dateTo
              if (!(this.value && dateTo.value) || dateTo.value < this.value) {
                dateTo.value = angular.copy(this.value)
              }
            },
            onCustomerSelect: function (customer) {
              if (customer) {
                this.min = new Date(customer.onlineSince)
              } else {
                this.min = undefined
              }
            },
          }

          this.collection.dateTo = {
            name: 'End-Datum',
            type: 'date',
            quick: true,
            init: new Date(),
            max: new Date(),
            change: function () {
              var dateFrom = $scope.filter.collection.dateFrom
              if (!(this.value && dateFrom.value) || this.value < dateFrom.value) {
                dateFrom.value = angular.copy(this.value)
              }
            },
            onCustomerSelect: function (customer) {
              if (customer) {
                this.min = new Date(customer.onlineSince)
              } else {
                this.min = undefined
              }
            },
          }

          this.collection.csaId = {
            name: 'Herkunft',
            type: 'select',
            options: [],
            multiple: true,
            quick: true,
            onCustomerSelect: function (customer) {
              if (!customer) {
                this.options = []
                return
              }
              this.options = customer.customerSystemAssociations
                .filter(function (csa) { return csa.active })
                .map(function (csa) { return { value: csa.customerSystemId, name: csa.system.name } })
            },
          }

          this.collection.shipping = {
            name: 'Lieferart',
            type: 'select',
            options: [
              { value: 'delivery', name: 'Lieferung' },
              { value: 'self_collector', name: 'Selbstabholer' },
              { value: 'restaurant', name: 'Restaurant' },
              { value: 'staff', name: 'Personalverzehr' },
            ],
            quick: true,
          }

          this.collection.payment = {
            name: 'Zahlungsmethode',
            type: 'select',
            options: [
              { value: 'offline%', name: 'Offline' },
              { value: 'offline:free', name: '  Gratis' },
              { value: 'offline:cash', name: '  Barzahlung' },
              { value: 'offline:ec', name: '  EC-Karte' },
              { value: 'offline:creditcard', name: '  Kreditkarte' },
              { value: 'offline:applepay', name: '  Apple Pay' },
              { value: 'offline:googlepay', name: '  Google Pay' },
              { value: 'online%', name: 'Online' },
              { value: 'online:paypal', name: '  PayPal' },
              { value: 'online:giropay', name: '  giropay' },
              { value: 'online:sofort', name: '  Klarna Sofort' },
              { value: 'online:amazonpay', name: '  Amazon Pay' },
            ],
            quick: true,
          }

          this.collection.redirected = {
            name: 'Weiterleitung',
            type: 'select',
            options: [
              { value: 2, name: 'empfangene Weiterl.' },
              { value: 3, name: 'gesendete Weiterl.' },
            ],
            quick: false,
          }

          this.collection.device = {
            name: 'Gerät',
            type: 'select',
            options: [
              { value: 'BROWSER_%', name: 'Browser' },
              { value: 'BROWSER_DESKTOP', name: '  Desktop Browser' },
              { value: 'BROWSER_PHONE', name: '  Phone Browser' },
              { value: 'BROWSER_TABLET', name: '  Tablet Browser' },
              { value: 'APP_%', name: 'App' },
              { value: 'APP_ANDROID', name: '  Android App' },
              { value: 'APP_IOS', name: '  iOS App' },
              { value: 'ASSISTANT_%', name: 'Sprachassistent' },
              { value: 'ASSISTANT_ALEXA', name: '  Alexa' },
              { value: 'ASSISTANT_GOOGLE', name: '  Google' },
            ],
            quick: false,
          }

          this.collection.customer = {
            name: 'Besteller',
            type: 'input',
            quick: true,
          }

          this.collection.phonenumber = {
            name: 'Telefon',
            type: 'input',
            quick: false,
          }

          this.collection.items = {
            name: 'Artikel',
            type: 'input',
            quick: true,
            isValid: function () {
              var dateFrom = $scope.filter.collection.dateFrom.value
              var dateTo = $scope.filter.collection.dateTo.value
              if (this.value && (dateFrom == null || dateTo == null)) {
                return 'Die Start- und End-Daten müssen gesetzt sein.'
              }
              var maxDays = 184
              if (this.value && Math.abs(moment(dateFrom).diff(dateTo, 'days')) + 1 > maxDays) {
                return 'Der ausgewählte Datumsbereich ist zu groß.<br>' +
                       'Es sind maximal ' + maxDays + ' Tage erlaubt.'
              }
              return true
            },
          }

          this.collection.total = {
            name: 'Summe',
            type: 'input',
            quick: false,
            isValid: function () {
              if (this.value && this.value.replace(/\s/, '').match(/^[<>]?=?-?\d+(?:,\d+)?$/) === null) {
                return 'Das Format erlaubt einen optionalen Vergleich (=,<,<=,>,>=)<br>' +
                       'gefolgt von einem optionalen Minuszeichen und einer Zahl.'
              }
              return true
            },
          }

          this.collection.preorder = {
            name: 'nur Vermittlungs-Vorbestellungen',
            type: 'checkbox',
            quick: false,
          }

          this.collection.deliveryTime = {
            name: 'nur Vorbestellungen',
            type: 'checkbox',
            quick: true,
          }

          this.collection.cancel = {
            name: 'nur Stornos',
            type: 'checkbox',
            quick: false,
          }

          this.collection.paypal = {
            name: 'nur offene PayPal-Fälle',
            type: 'checkbox',
            quick: false,
            addParam: function (params) {
              if (this.value) {
                if (!params.infos) {
                  params.infos = {}
                }
                params.infos.openPayPalDispute = true
              }
            },
          }

          $scope.$watch('filter.collection.paypal.value', function (paypal) {
            if (paypal) {
              var dateFrom = new Date()
              var dateTo = new Date()

              dateFrom.setDate(dateFrom.getDate() + 1)
              dateFrom.setMonth(dateFrom.getMonth() - 1)
              $scope.filter.collection.dateFrom.value = dateFrom
              $scope.filter.collection.dateTo.value = dateTo
            }
          })

          this.setInitValues()
        },
        setInitValues: function () {
          Object.keys($scope.filter.collection)
            .forEach(function (model) {
              var filter = $scope.filter.collection[model]
              filter.value = filter.init
            })
        },
        on: function (trigger, value) {
          if (typeof trigger !== 'string') {
            throw new Error('[filter.on()] Invalid argument: trigger must be a string, ' + (typeof trigger) + ' given')
          }
          trigger = 'on' + trigger.charAt(0).toUpperCase() + trigger.slice(1)
          Object.keys($scope.filter.collection)
            .forEach(function (model) {
              var filter = $scope.filter.collection[model]
              if (typeof filter[trigger] === 'function') {
                filter[trigger](value)
              }
            })
        },
        apply: function (event) {
          $mdSidenav(this.sidenavId).close()
          $scope.searchOrders()
        },
        reset: function (event) {
          this.setInitValues()
          $scope.customerSelect()
          $scope.storeSelect()
        },
        openMore: function (event) {
          $mdSidenav(this.sidenavId).open()
        },
      }

      $scope.settings = {
        sidenavId: 'sc-archive-settings',
        filter: [],
        init: function () {
          // load settings from localstorage
          var filter = LocalStorageService.get(this.sidenavId, [])

          // add missing keys
          var filterModels = filter.map(function (entry) {
            return entry.model
          })
          Object.keys($scope.filter.collection)
            .forEach(function (model) {
              if (filterModels.includes(model)) {
                return
              }
              filter.push({
                model: model,
                quick: $scope.filter.collection[model].quick || false,
              })
            })

          this.filter = filter
        },
        filterWithValue: function (_) {
          var value = $scope.filter.collection[_.model].value
          if (Array.isArray(value)) {
            return value.length > 0
          }
          return Boolean(value)
        },
        open: function (event) {
          this.reset(event)
          $mdSidenav(this.sidenavId).open()
        },
        apply: function (event) {
          $mdSidenav(this.sidenavId).close()
          this.filter = this.tempFilter
          delete this.tempFilter

          // save settings in localstorage
          LocalStorageService.set(this.sidenavId, this.filter)
        },
        reset: function (event) {
          this.tempFilter = angular.copy(this.filter)
        },
      }

      $scope.mark = {
        NONE: 0,
        SOME: 1,
        ALL: 2,

        length: 0,
        list: {},
        state: 0,
        getOrders: function (filterFn) {
          return ($scope.orders || [])
            .filter(function (order) {
              return Object.keys($scope.mark.list).includes(order.orderId)
            })
            .filter(filterFn || function () { return true })
        },
        toggle: function (order) {
          if (!this.list[order.orderId]) {
            delete this.list[order.orderId]
          }
          this.length = Object.keys(this.list).length
          if (this.length === 0) {
            this.state = this.NONE
            return
          }
          if (this.length === $scope.orders.length) {
            this.state = this.ALL
            return
          }
          this.state = this.SOME
        },
        toggleAll: function () {
          if (this.state === this.ALL) {
            this.reset()
            return
          }
          $scope.orders.forEach(function (order) {
            $scope.mark.list[order.orderId] = true
          })
          this.length = $scope.orders.length
          this.state = this.ALL
        },
        reset: function () {
          this.length = 0
          this.list = {}
          this.state = this.NONE
        },
        actions: [
          {
            title: 'Vermittlung',
            action: function (event) {
              var orders = $scope.mark.getOrders(function (order) {
                return order.customerSystemAssociation.system.hasTransmission
              })
              if (orders.length === 0) {
                return $scope.mark.showNoMatchingOrdersDialog(event, this.title)
              }
              OrderService.openDialogResend(event, orders)
            },
          },
          {
            title: 'Storno',
            class: 'md-warn',
            action: function (event) {
              var orders = $scope.mark.getOrders(function (order) {
                return !['closed', 'STORNO'].includes(order.orderStatus)
              })
              if (orders.length === 0) {
                return $scope.mark.showNoMatchingOrdersDialog(event, this.title)
              }
              OrderService.openDialogCancel(event, orders)
            },
          },
        ],
        showNoMatchingOrdersDialog: function (event, title) {
          $mdDialog.show(
            $mdDialog.alert()
              .title(title)
              .textContent('Es sind keine passenden Bestellungen ausgewählt.')
              .ok('Verstanden')
              .targetEvent(event)
          )
        },
      }

      $scope.searchOrderById = function (event) {
        if ($scope.loading || (event instanceof KeyboardEvent && event.key !== 'Enter')) {
          return
        }

        $state.go('servicecenter.archive', { id: $scope.searchId }, { inherit: false, location: 'replace' })

        $scope.loading = true
        return ServicecenterService.getByOrderId($scope.searchId)
          .then(searchResponseHandler)
          .finally(function () {
            $scope.loading = false
          })
      }

      $scope.resetSearchId = function () {
        if ($scope.searchId) {
          $scope.searchId = undefined
          $state.go('servicecenter.archive', {}, { inherit: false, location: 'replace' })
        }
      }

      var lastSearchParams
      $scope.searchOrders = function (event) {
        if ($scope.loading || !$scope.customerId) {
          return
        }

        $scope.resetSearchId()

        var params = {
          customerId: $scope.customerId,
        }
        if (Array.isArray($scope.storeId) && $scope.storeId.length > 0) {
          params.storeId = $scope.storeId
        }

        // loop over filter collection to add values to params
        var invalid = []
        Object.keys($scope.filter.collection)
          .forEach(function (model) {
            var filter = $scope.filter.collection[model]

            // [if present] check validity
            if (typeof filter.isValid === 'function') {
              var validCheckResult = filter.isValid()
              if (validCheckResult !== true) {
                invalid.push({
                  name: filter.name,
                  message: validCheckResult,
                })
              }
            }

            // [if present] add filter value by custom function
            if (typeof filter.addParam === 'function') {
              return filter.addParam(params)
            }

            // add filter value based on filter type
            switch (filter.type) {
              case 'date':
                if (filter.value) {
                  params[model] = $filter('date')(filter.value)
                }
                break
              case 'select':
                if (filter.multiple && Array.isArray(filter.value) && filter.value.length > 0) {
                  params[model] = filter.value.map(function (value) { return value.value })
                } else if (filter.value && filter.value.constructor === Object) {
                  params[model] = filter.value.value
                }
                break
              case 'input':
              case 'checkbox':
              default:
                if (filter.value) {
                  params[model] = filter.value
                }
            }
          })
        if (invalid.length > 0) {
          var content = [
            '<p class="bold">Die Suche wurde abgebrochen!</p>',
            '<p>Beachte die Meldungen folgender Filter:</p>',
            '<ul>',
            invalid.map(function (filter) {
              return '<li><b>' + filter.name + ':</b> ' + filter.message + '</li>'
            }).join(''),
            '</ul>'
          ]
          return $mdDialog.show(
            $mdDialog.alert()
              .title('Ungültige Filter')
              .htmlContent(content.join(''))
              .ok('OK')
              .targetEvent(event)
          )
        }

        // check for changed params to reset page to 1
        var thisSearchParams = angular.copy(params)
        if (lastSearchParams && !angular.equals(thisSearchParams, lastSearchParams)) {
          $scope.filter.pagination.page = 1
        }

        params.limit = $scope.filter.pagination.limit
        params.page = $scope.filter.pagination.page
        params.magentoBaseUrl = ServicecenterService.getMagentoBaseUrl() === '' ? true : undefined

        $scope.loading = true
        ServicecenterService.cancelAll('silent')
        ServicecenterService.filterOrders(params)
          .then(function (response) {
            lastSearchParams = thisSearchParams
            searchResponseHandler(response)
          })
          .finally(function () {
            $scope.loading = false
          })
      }

      $scope.stopSearch = function (event) {
        ServicecenterService.cancelAll('silent')
        UserService.killConnections()
      }

      function searchResponseHandler (response) {
        $scope.mark.reset()

        if (response.customer) {
          $scope.customerSelect(response.customer.customerId, response.customer, false)
        }
        if (response.magentoBaseUrl) {
          ServicecenterService.setMagentoBaseUrl(response.magentoBaseUrl)
        }

        $scope.distribution = response.distribution
        $scope.ordersTotal = response.recordTotal
        $scope.orders = response.records

        if ($scope.orders) {
          getCombinedOtsStatus()
          getCombinedServicecenterStatus()
        }
      }

      $scope.openOrderDetails = function (event, order, target) {
        if (target) {
          $window.open($state.href('servicecenter.archive', { id: order.orderId }), target)
          return
        }

        ServicecenterService.cancelAll('restart')
        $scope.$root.customer = $scope.customer
        $scope.$root.magentoBaseUrl = ServicecenterService.getMagentoBaseUrl()
        $scope.$root.order = order
        $mdSidenav('orderDetails').open()
          .then(function () {
            ServicecenterService.allowRestart(Date.now())
            document.querySelector('#orderdetails > md-content').scrollTop = 0
          })
      }

      // OTS & SC STATUS

      function getCombinedOtsStatus () {
        if (!$scope.STATUS_COMBINED_LOADING) {
          return
        }
        var orderIds = []
        $scope.orders.forEach(function (order) {
          if (order.customerSystemAssociation.system.hasTransmission) {
            orderIds.push(order.orderId)
          }
        })
        if (orderIds.length === 0) {
          return
        }
        ServicecenterService.getOtsStatus(orderIds.join())
          .then(function (response) {
            ($scope.orders || []).forEach(function (order) {
              if (!orderIds.includes(order.orderId)) {
                return
              }
              if (order.orderId in response) {
                order.otsstatus = response[order.orderId]
              } else {
                order.otsstatus = { error: true }
              }
            })
          })
          .catch(function (error) {
            switch (error.status) {
              case 943:
                var unbind = $scope.$watch(
                  function () {
                    return ServicecenterService.allowRestart()
                  },
                  function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                      getCombinedOtsStatus()
                      unbind()
                    }
                  }
                )
                break
              default:
                ($scope.orders || []).forEach(function (order) {
                  if (orderIds.includes(order.orderId)) {
                    order.otsstatus = { error: true, cancel: error.status === 942 }
                  }
                })
            }
          })
      }

      function getCombinedServicecenterStatus () {
        if (!$scope.STATUS_COMBINED_LOADING) {
          return
        }
        var orderIds = []
        $scope.orders.forEach(function (order) {
          if (order.customerSystemAssociation.sc) {
            orderIds.push(order.orderId)
          }
        })
        if (orderIds.length === 0) {
          return
        }
        ServicecenterService.getServicecenterStatus(orderIds.join())
          .then(function (response) {
            ($scope.orders || []).forEach(function (order) {
              if (!orderIds.includes(order.orderId)) {
                return
              }
              if ('sc_data' in response && 'current_user' in response && order.orderId in response.sc_data) {
                var scData = response.sc_data[order.orderId]
                var scStatus = {
                  orderId: scData.increment_id,
                  scStatus: scData.sc_status,
                  scUser: scData.sc_user,
                  scUpdatedAt: scData.sc_updated_at,
                  currentUser: response.current_user,
                }
                order.servicecenterstatus = scStatus
              } else {
                order.servicecenterstatus = { error: true }
              }
            })
          })
          .catch(function (error) {
            switch (error.status) {
              case 943:
                var unbind = $scope.$watch(
                  function () {
                    return ServicecenterService.allowRestart()
                  },
                  function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                      getCombinedServicecenterStatus()
                      unbind()
                    }
                  }
                )
                break
              default:
                ($scope.orders || []).forEach(function (order) {
                  if (orderIds.includes(order.orderId)) {
                    order.servicecenterstatus = { error: true, cancel: error.status === 942 }
                  }
                })
            }
          })
      }

      // INITIALIZE

      function initCustomerSelect () {
        $scope.customerId = undefined
        $scope.customer = undefined

        CustomerService.getCustomers().$promise
          .then(function (customers) {
            $scope.customers = customers
          })

        if (!$scope.customerSelect) {
          $scope.customerSelect = function (customerId, customer, trigger) {
            var changed = ($scope.customer || {}).customerId !== customerId
            if (trigger === undefined) {
              trigger = true
            }

            $scope.customerId = customerId
            $scope.customer = customer

            if (changed) {
              ServicecenterService.setMagentoBaseUrl('')
              initStoreSelect(customer)
              searchResponseHandler({})
            }
            if (trigger && customer) {
              $scope.filter.on('customerSelect', customer)
            }
          }
        }
      }

      function initStoreSelect (customer) {
        $scope.storeId = undefined
        $scope.store = undefined
        $scope.stores = undefined
        $scope.storegroups = undefined

        if (customer == null) {
          return
        }

        CustomerService.getStoresWithGroups(customer.customerId).$promise
          .then(function (response) {
            $scope.stores = response.stores
            $scope.storegroups = response.groups
          })

        if (!$scope.storeSelect) {
          $scope.storeSelect = function (storeId, store) {
            $scope.storeId = storeId
            $scope.store = store
          }
        }
      }

      $scope.filter.init()
      $scope.settings.init()
      initCustomerSelect()

      $scope.$on('$destroy', function () {
        ServicecenterService.cancelAll('silent', 'archive')
      })

      // handle state params

      if ($stateParams.id) {
        $scope.searchId = $stateParams.id
        $scope.searchOrderById()
          .then(function () {
            $scope.openOrderDetails(null, $scope.orders[0])
          })
      }
    })
    .controller('ServicecenterPinboardController', function ServicecenterPinboardController ($filter, $mdDialog, $scope, CustomerService, PinboardService, ReportingService) {
      var $ctrl = this
      $scope.mode = 'view'

      CustomerService.getCustomers().$promise
        .then(function (customers) {
          $scope.customers = customers
            .sort(function (a, b) { return a.name.localeCompare(b.name) })

          $scope.customersWithOttomatik = angular.copy($scope.customers)
          $scope.customersWithOttomatik.unshift({
            customerId: 999999999,
            name: 'Ottomatik',
            customerSystemAssociations: [],
          })
        })

      $scope.customerSelect = function (customerId, customer) {
        if (!customer) {
          $scope.stores = []
          $scope.storegroups = []
          $scope.systems = []
          $scope.topics = []
          return
        }

        $scope.topics = ReportingService.getTopics(customer)
          .filter(function (topic) {
            return topic.showPinboardDialog
          })

        CustomerService.getStoresWithGroups(customerId).$promise
          .then(function (response) {
            $scope.stores = response.stores
            $scope.storegroups = response.storegroups
          })

        $scope.systems = customer.customerSystemAssociations.map(function (association) {
          return association.system
        })
      }

      $scope.load = function (customerId) {
        if ($scope.loading) {
          return
        }
        $scope.loading = true
        PinboardService.getAll()
          .then(function (pinboard) {
            $scope.pinboard = pinboard
            $scope.filter(customerId)
          })
          .finally(function () {
            $scope.loading = false
          })
      }

      $scope.filter = function (customerId) {
        $scope.filteredPinboard = angular.copy($scope.pinboard || [])
          .filter(function (entry) {
            if (customerId == null) {
              return true
            }
            if (!entry.customer) {
              return false
            }
            return entry.customer.customerId === customerId
          })
        $scope.filteredCustomerId = customerId
      }

      $ctrl.resetForm = function () {
        $scope.form.$setPristine()
        $scope.form.$setUntouched()
      }

      $scope.new = function () {
        $ctrl.resetForm()
        $scope.mode = 'new'
        $scope.formBackup = {
          title: '',
          content: '',
        }
        $scope.formEntry = angular.copy($scope.formBackup)
      }

      $scope.edit = function (entry) {
        $ctrl.resetForm()
        $scope.mode = 'edit'

        var copy = angular.copy(entry)
        if (copy.customer) {
          copy.customer = copy.customer.customerId
        }
        copy.stores.forEach(function (store, index, array) {
          array[index] = store.storeId
        })
        copy.systems.forEach(function (system, index, array) {
          array[index] = system.systemId
        })

        $scope.formBackup = copy
        $scope.formEntry = angular.copy(copy)
        document.querySelector('.servicecenter-pinboard').scrollTop = 0
      }

      $scope.delete = function (entry) {
        if ($scope.loading) {
          return
        }

        $mdDialog.show(
          $mdDialog.confirm()
            .title('Eintrag löschen?')
            .textContent('Bist du sicher, dass der Eintrag "' + entry.title + '" gelöscht werden soll?')
            .ok('Ja')
            .cancel('Nein')
        ).then(function () {
          $scope.loading = true
          PinboardService.delete(entry)
            .finally(function () {
              $scope.loading = false
            })
            .then(function () {
              $scope.load($scope.filteredCustomerId)
            })
        })
      }

      $scope.reset = function () {
        $ctrl.resetForm()
        $scope.formEntry = angular.copy($scope.formBackup)
      }

      $scope.close = function () {
        $ctrl.resetForm()
        $scope.mode = 'view'
        $scope.formBackup = {}
        $scope.formEntry = {}
      }

      $scope.save = function () {
        if ($scope.loading) {
          return
        }
        $scope.loading = true

        var promise
        if ($scope.mode === 'new') {
          promise = PinboardService.create($scope.formEntry)
        } else {
          promise = PinboardService.update($scope.formEntry)
        }
        var selectCustomerId
        promise
          .then(function (response) {
            $scope.close()
            selectCustomerId = (response.customer || {}).customerId
          })
          .finally(function () {
            $scope.loading = false
          })
          .then(function () {
            $scope.load(selectCustomerId)
          })
      }

      // init load
      $scope.load()
    })
    .controller('ServicecenterContactsController', function ServicecenterContactsController ($scope, $filter, $q, CustomerService, ServicecenterService) {
      var promises = []

      $scope.search = ''
      $scope.searchHits = null

      $scope.searchClear = function (event) {
        $scope.search = ''
        $scope.searchFilter(event)
      }

      $scope.searchFilter = function (event) {
        var searchContext = {
          term: $scope.search.toLowerCase(),
          regex: new RegExp($scope.search.toLowerCase(), 'i'),
          hits: 0,
          states: {
            active: Object.keys($scope.states.active),
            empty: Object.keys($scope.states.active).length === 0,
          },
        }

        $q.all(promises)
          .then(function () {
            $scope.customers.forEach(function (customer) {
              $scope.storeContacts[customer.customerId] = $filter('filter')(
                angular.copy(customer.storeContacts),
                contactFilter.bind(searchContext)
              )
            })
            $scope.searchHits = searchContext.term ? searchContext.hits : null
          })

        if (event.target[0]) {
          event.target[0].blur()
        }
      }

      function contactFilter (contact) {
        if (contact.store) {
          if (!this.states.empty && (!contact.store.state || !this.states.active.includes(contact.store.state))) {
            return false
          }
          return storeFilter.bind(this)(contact)
        }
        if (!this.states.empty) {
          return false
        }
        return memberFilter.bind(this)(contact)
      }

      function storeFilter (contact) {
        if (
          contact.store.name.match(this.regex) ||
          (contact.store.foreignIdent || '').match(this.regex) ||
          (contact.store.foreignIdent || '').replace(' ', '').match(this.regex) ||
          contact.store.lieferandoId === +this.term
        ) {
          this.hits++
          return true
        }
        contact.contacts = $filter('filter')(
          contact.contacts,
          memberFilter.bind(this)
        )
        if (contact.contacts.length > 0) {
          return true
        }
        return false
      }

      function memberFilter (member) {
        if (
          !this.term ||
          member.name.match(this.regex) ||
          (member.addition || '').match(this.regex)
        ) {
          this.hits++
          return true
        }
        return false
      }

      $scope.downloadLieferandoIds = function (event, customer) {
        var statesActive = Object.keys($scope.states.active)
        var statesEmpty = statesActive.length === 0
        var data = []
        customer.storeContacts
          .filter(function (contact) {
            if (!contact.store || !contact.store.lieferandoId) {
              return false
            }
            return statesEmpty || (contact.store.state && statesActive.includes(contact.store.state))
          })
          .forEach(function (contact) {
            data.push(
              contact.store.lieferandoId + '\t' +
              (contact.store.foreignIdent ? contact.store.foreignIdent + ' ' : '') +
              contact.store.name
            )
          })
        var blob = new Blob([data.join('\n')], { type: 'text/plain;charset=utf-8' })
        var filename = customer.name
        if (!statesEmpty) {
          filename += ' ' + statesActive.sort().join('+')
        }
        filename += ' IDs.txt'
        saveAs(blob, filename)
      }

      $scope.storeContacts = {}

      $scope.headerPromise = ServicecenterService.getContacts(0)
        .then(function (header) {
          $scope.header = header
        })
      promises.push($scope.headerPromise)

      CustomerService.getCustomers().$promise
        .then(function (customers) {
          $scope.customers = $filter('orderBy')(customers, 'name')
          $scope.customers.forEach(function (customer) {
            customer.contactsPromise = ServicecenterService.getContacts(customer.customerId)
              .then(function (storeContacts) {
                customer.storeContacts = storeContacts
                $scope.storeContacts[customer.customerId] = customer.storeContacts
              })
            promises.push(customer.contactsPromise)
          })
        })

      $scope.states = {
        list: {
          BW: 'Baden-Württemberg',
          BY: 'Bayern',
          BE: 'Berlin',
          BB: 'Brandenburg',
          HB: 'Bremen',
          HH: 'Hamburg',
          HE: 'Hessen',
          MV: 'Mecklenburg-Vorpommern',
          NI: 'Niedersachsen',
          NW: 'Nordrhein-Westfalen',
          RP: 'Rheinland-Pfalz',
          SL: 'Saarland',
          SN: 'Sachsen',
          ST: 'Sachsen-Anhalt',
          SH: 'Schleswig-Holstein',
          TH: 'Thüringen',
        },
        active: {},
        toggle: function (event, state) {
          this.active[state] = !this.active[state]
          if (!this.active[state]) {
            delete this.active[state]
          }
          $scope.searchFilter(event)
        },
      }
    })
    .controller('TakeoverController', function TakeoverController ($scope, $window, TakeoverService) {
      $scope.queryUsers = function (query) {
        return TakeoverService.queryUsers(query)
          .then(function (users) {
            return users
          })
      }

      $scope.takeoverUser = function (user) {
        TakeoverService.takeoverUser(user)
          .then(function () {
            $window.open('/', 'takeover')
          })
      }
    })
    .controller('TestorderController', function TestorderController ($interval, $mdSidenav, $scope, CustomerService, PapiService, ServicecenterService, SessionStorageService) {
      CustomerService.getCustomers().$promise
        .then(function (customers) {
          $scope.customers = customers.filter(function (customer) {
            return customer.options.hasPapiConfig
          })
        })

      $scope.customerSelect = function (customerId, customer) {
        $scope.customerId = customerId
        $scope.customer = customer

        if (customerId) {
          CustomerService.getStores(customerId).$promise
            .then(function (stores) {
              $scope.stores = stores
            })
        }
      }

      $scope.storeSelect = function (storeId, store) {
        $scope.storeId = storeId
        $scope.store = store
      }

      $scope.orders = {}
      $scope.testorders = SessionStorageService.get('testorders', [])
      $scope.placeTestOrder = function () {
        if ($scope.loading) {
          return
        }
        $scope.loading = true
        PapiService.placeTestOrder($scope.customerId, $scope.storeId)
          .then(function (result) {
            $scope.testorders.push({
              orderId: result.order_id,
              time: (new Date()).toISOString(),
              store: {
                foreignIdent: $scope.store.foreignIdent,
                name: $scope.store.name,
              },
            })
            SessionStorageService.set('testorders', $scope.testorders)
          })
          .finally(function () {
            $scope.loading = false
          })
      }

      $scope.open = function (resource) {
        $scope.$root.customer = resource.customer
        $scope.$root.magentoBaseUrl = resource.magentoBaseUrl
        $scope.$root.order = resource.records[0]
        $mdSidenav('orderDetails').open()
          .then(function () {
            document.querySelector('#orderdetails > md-content').scrollTop = 0
          })
      }

      var loadOrdersInterval
      function setupLoadOrders () {
        function intervalFn () {
          $scope.testorders
            .filter(function (test) {
              return !$scope.orders[test.orderId]
            })
            .forEach(function (test) {
              var params = {
                hideGlobalSpinner: true,
                ignoreError: 404,
                orderId: test.orderId,
              }
              ServicecenterService.getByOrderId(params)
                .then(function (response) {
                  test.loaded = true
                  $scope.orders[test.orderId] = response
                  SessionStorageService.set('testorders', $scope.testorders)
                })
            })
        }

        loadOrdersInterval = $interval(intervalFn, 15 * 1000)
        intervalFn()

        $scope.$on('$destroy', function () {
          $interval.cancel(loadOrdersInterval)
        })
      }

      setupLoadOrders()
    })
    .run(function ($document, $window, TakeoverService) {
      if ($window.name === 'takeover') {
        if ($window.performance && $window.performance.navigation && $window.performance.navigation.type === $window.performance.navigation.TYPE_RELOAD) {
          $window.alert('Die Benutzer-Übernahme wird jetzt beendet!')
          $window.close()
          return
        }

        $document[0].body.classList.add('takeover')
        if (navigator.sendBeacon) {
          lifecycle.addEventListener('statechange', function (event) {
            if (event.newState === 'terminated') {
              var blob = new Blob([JSON.stringify({ action: 'delete' })], { type: 'application/json' })
              navigator.sendBeacon(TakeoverService.getUrl(), blob)
            }
          })
        }
      }
    })
})()
