(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.catalog', [])
    .config(function ($stateProvider, moduleRegisterProvider) {
      $stateProvider
        .state('catalog', {
          abstract: true,
          url: '/catalog',
          views: {
            '@': {
              templateUrl: 'src/catalog/views/layout.html',
            },
          },
        })
        .state('catalog.sellout', {
          url: '/sellout',
          views: {
            'colMain@catalog': {
              templateUrl: 'src/catalog/views/sellout.html',
              controller: 'CatalogSelloutController',
            },
          },
          params: {
            customerId: null,
            storeId: null,
          },
        })

      moduleRegisterProvider.add({
        id: 'catalog',
        title: 'Ausverkauft',
        defaultUrl: 'catalog.sellout',
        materialIcon: 'fastfood',
        accessRoles: ['admin', 'catalog_sellout'],
      })
    })
    .factory('CatalogService', function CatalogService (CONFIG, $resource) {
      var api = {
        sellout: $resource(CONFIG.API_URL + '/customers/:cid/catalog/:sid/sellout'),
        groups: $resource(CONFIG.API_URL + '/customers/:cid/catalog/groups'),
      }

      var service = {
        getGroups: groups,
        getSellout: sellout,
      }
      return service

      function groups (customerId) {
        return api.groups.query({ cid: customerId }).$promise
      }

      function sellout (customerId, storeId) {
        var params = {
          cid: customerId,
        }
        if (!storeId || Array.isArray(storeId)) {
          params.storeId = storeId
          return api.sellout.query(params)
        }
        params.sid = storeId
        return api.sellout.get(params)
      }
    })
    .controller('CatalogSelloutController', function CatalogSelloutController ($scope, $transition$, UserService, CustomerService) {
      $scope.triggerLoad = undefined
      $scope.load = function () {
        $scope.triggerLoad = new Date()
      }

      function initCustomerSelect () {
        CustomerService.getCustomers().$promise.then(
          function (customers) {
            $scope.customerOptions = customers.filter(function (customer) {
              return customer.customerSystemAssociations.filter(function (csa) {
                return csa.system.origin === 'ottomatik_magento'
              }).length > 0
            })

            if ($transition$.params().customerId) {
              $scope.customerSelect($transition$.params().customerId)
            }
          }
        )
        if (!$scope.customerSelect) {
          $scope.customerSelect = function (customerId, customer) {
            $scope.customerId = customerId
            $scope.customer = customer
            initStoreSelect()
          }
        }
      }

      function initStoreSelect () {
        CustomerService.getStores($scope.customerId).$promise.then(
          function (stores) {
            $scope.storeOptions = stores

            if ($transition$.params().storeId) {
              $scope.storeSelect([$transition$.params().storeId])
              $scope.load()
              return
            }

            if (stores.length === 1) {
              $scope.storeSelect([stores[0].storeId], [stores])
              $scope.load()
              return
            }
          }
        )
        if (!$scope.storeSelect) {
          $scope.storeSelect = function (storeIds, stores) {
            $scope.storeIds = storeIds
            $scope.stores = stores
          }
        }
      }

      $scope.showCustomerSelect = UserService.hasRole(['admin', 'storesall'])
      if ($scope.showCustomerSelect) {
        initCustomerSelect()
      } else {
        $scope.customerId = UserService.getCustomerId()
        CustomerService.getCustomer($scope.customerId).$promise.then(
          function (customer) {
            $scope.customer = customer
            initStoreSelect()
          }
        )
      }
    })
})()
