(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager')
    .component('omOrderannotationicons', {
      bindings: {
        order: '<',
      },
      controller: OrderAnnotationIconsController,
      controllerAs: '$ctrl',
      templateUrl: 'src/components/om-orderannotationicons.html',
    })

  function OrderAnnotationIconsController ($element, $filter, UserService) {
    var $ctrl = this

    $ctrl.$onInit = function () {
      this.showPreorderDetails = UserService.hasRole('admin') || UserService.hasRole('servicecenter')
    }

    $ctrl.$onChanges = function (changesObj) {
      setDeliverTime()
    }

    function setDeliverTime () {
      var deliveryTimeTitle

      if ($ctrl.order.deliveryTime) {
        if ($ctrl.order.shipping !== 'self_collector') {
          deliveryTimeTitle = 'Gewünschter Lieferzeitpunkt: '
        } else {
          deliveryTimeTitle = 'Gewünschter Abholzeitpunkt: '
        }
        if ($ctrl.order.receivedAt.substring(0, 10) !== $ctrl.order.deliveryTime.substring(0, 10)) {
          deliveryTimeTitle += $filter('date')($ctrl.order.deliveryTime, '(!) dd.MM.yyyy ')
        }
        deliveryTimeTitle += $filter('date')($ctrl.order.deliveryTime, 'HH:mm')
      }

      $ctrl.deliveryTime = !!$ctrl.order.deliveryTime
      $ctrl.deliveryTimeTitle = deliveryTimeTitle
    }

    $ctrl.$postLink = function () {
      $element[0].classList.add('layout-row', 'layout-wrap')
    }
  }
})()
