'use strict';

(function () {
  angular
  .module('ottomatikStoreManager')
  .component('omDpos', {
    templateUrl: 'src/components/om-dpos.html',
    bindings: {
      customer: '<',
      store: '<',
    },
    controllerAs: 'ctrl',
    controller: ['$scope', '$filter', 'CustomerService', function ($scope, $filter, CustomerService) {
      var self = this;

      $scope.loading = false;
      $scope.status = null;
      $scope.lastPoll = null;
      $scope.url = undefined;

      self.$onChanges = function (changes) {
        if (changes.customer) self.customerId = changes.customer.currentValue.customerId;
        if (changes.store) self.storeId = changes.store.currentValue.storeId;
        self.load();
      };

      self.load = function () {
        $scope.loading = true;
        CustomerService.getDPosStore(self.customerId, self.storeId).$promise.then(
          function (response) {
            $scope.status = response.status;
            $scope.lastPoll = $filter('date')(response.date, 'dd.MM.yyyy HH:mm:ss');
            $scope.url = response.url;
          },
          function () {
            $scope.status = null;
          }
        ).finally(
          function () {
            $scope.loading = false;
          }
        );
      };

      $scope.load = function (event) {
        self.load();
      };
    }],
  });
})();
