;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.statistics')
    .controller('StatisticsDeliverytimeController', StatisticsDeliverytimeController)

  function StatisticsDeliverytimeController($filter, $scope, StatisticService) {
    var vm = this
    vm.load = load

    function load(event) {
      var params = {
        customerId: $scope.filter.customerId,
        store: $scope.filter.storeId,
        dateFrom: $filter('date')($scope.filter.dates.from),
        dateTo: $filter('date')($scope.filter.dates.to),
      }

      vm.loading = true
      StatisticService.getDeliverytimes(params)
        .then(createChart)
        .finally(() => {
          vm.loading = false
        })
    }

    function createChart(response) {
      var stores = getStores(response)

      var data = new google.visualization.DataTable()
      data.addColumn('date', 'Tag')

      var storeIndices = stores.reduce((indices, store, index) => {
        indices[store.storeId] = index
        data.addColumn('timeofday', getStoreLabel(store))
        return indices
      }, {})

      response.forEach((entry) => {
        var row = []

        var date = new Date(entry.date)
        date.setHours(0, 0, 0)
        row.push({ v: date, f: $filter('date')(date) })

        var values = Array(stores.length).fill(null)
        Object.keys(entry.times).forEach((storeId) => {
          values[storeIndices[storeId]] = getTimeOfDayValue(entry.times[storeId])
        })

        row = row.concat(values)
        data.addRow(row)
      })

      vm.chart = {
        type: 'LineChart',
        data: data,
        options: {
          animation: {
            duration: 250,
            easing: 'out',
            startup: true,
          },
          backgroundColor: 'transparent',
          curveType: 'function',
          focusTarget: 'category',
          fontName: 'Roboto',
          fontSize: 14,
          hAxis: {
            minorGridlines: { count: 0 },
          },
          height: 500,
          legend: { position: 'top' },
          title: 'Durchschnittliche Bearbeitungszeit pro Tag (ohne Vorbestellung und ohne Selbstabholung)',
          titleTextStyle: {
            fontSize: 16,
          },
          vAxis: {
            viewWindow: { min: 0 },
          },
        },
      }
    }

    function getStores(response) {
      var storeIds = response.reduce((ids, entry) => {
        Object.keys(entry.times).forEach((storeId) => {
          storeId = parseInt(storeId)
          if (!ids.includes(storeId)) {
            ids.push(storeId)
          }
        })
        return ids
      }, [])

      return $filter('orderBy')(
        $scope.filter.stores.filter((store) => storeIds.includes(store.storeId)),
        ['foreignIdent', 'name']
      )
    }

    function getStoreLabel(store) {
      var label = store.name
      if (store.foreignIdent) {
        label = store.foreignIdent + ' ' + label
      }
      return label
    }

    function getTimeOfDayValue(seconds) {
      var hours = Math.floor(seconds / (60 * 60))
      seconds = seconds % (60 * 60)
      var minutes = Math.floor(seconds / 60)
      seconds = seconds % 60
      return [hours, minutes, seconds]
    }
  }
})()
