/**
 * @namespace Components
 */
(function () {
  'use strict'

  /**
   * @description Create a custom wrapper for mdSelect.
   * @example <om-select ng-model="myModel" options="myOptions" [...]></om-select>
   */
  angular
    .module('ottomatikStoreManager')
    .run(function ($templateRequest) {
      $templateRequest('src/components/om-select.html')
    })
    .component('omSelect', {
      template: function ($attrs, $templateCache, $mdUtil) {
        'ngInject' // ng-annotate this function

        var attrs = []
        attrs.push(Object.prototype.hasOwnProperty.call($attrs, 'mdNoAsterisk') ? 'md-no-asterisk' : undefined)
        attrs.push(Object.prototype.hasOwnProperty.call($attrs, 'multiple') ? 'multiple' : undefined)

        var template
        do {
          template = $templateCache.get('src/components/om-select.html')
        } while (template === undefined)
        return $mdUtil.supplant(
          template,
          {
            attrs: attrs.join(' '),
            mdContainerClass: $attrs.mdContainerClass || '',
          }
        )
      },
      require: {
        ngModelCtrl: 'ngModel',
      },
      transclude: true,
      bindings: {
        ngModel: '=',       // mixed
        label: '@?',        // string: input container label
        disabled: '<?',     // boolean: enable or disable this select (default: false)
        options: '<',       // array: array of objects which have properties defined in the mapping-attribute
        mapping: '<?',      // object: mapping used to fetch data from options (default: { id: 'id', display: 'display' })
        groups: '<?',       // array
        groupMapping: '<?', // object
        multiple: '<?',     // presence: show as multi-select
        required: '<?',     // presence: require a selection
        useReset: '<?',     // presence: show reset button above options
        useSearch: '<?',    // presence: show search bar above options
        onChange: '<?',     // function: callback function - function(id, value)
        onDblclick: '<?',   // function: callback function - function()
        noSort: '<?',       // presence: prevent sorting
      },
      controller: function omSelect ($element, $attrs, $interval, $filter, $mdSelect, helperService) {
        var $ctrl = this
        var initialized = false

        $ctrl.$onInit = function () {
          if (initialized) {
            return
          }
          $ctrl.isOpen = false
          $ctrl.multiple = Object.prototype.hasOwnProperty.call($attrs, 'multiple')
          $ctrl.useReset = Object.prototype.hasOwnProperty.call($attrs, 'useReset')
          $ctrl.useSearch = Object.prototype.hasOwnProperty.call($attrs, 'useSearch')
          $ctrl.useGroups = Object.prototype.hasOwnProperty.call($attrs, 'groups') && $ctrl.multiple
          $ctrl.noSort = Object.prototype.hasOwnProperty.call($attrs, 'noSort')
          initialized = true
        }

        $ctrl.selectOptions = []
        $ctrl.selectOptionsFull = []
        $ctrl.selectGroups = []
        $ctrl.selectGroupsFull = []

        $ctrl.$onChanges = function (changesObj) {
          if (!initialized) {
            $ctrl.$onInit()
          }
          if (!$ctrl.mapping) {
            $ctrl.mapping = {
              id: 'id',
              display: 'display',
            }
          }
          if (!$ctrl.groupMapping) {
            $ctrl.groupMapping = {
              id: 'id',
              display: 'display',
            }
          }

          var updateModel = false

          var outOpts = []
          var ids = []

          if (changesObj.options) {
            $ctrl.createOptions(changesObj.options.currentValue, outOpts, ids, $ctrl.mapping)
            $ctrl.selectOptions = angular.copy(outOpts)
            $ctrl.selectOptionsFull = angular.copy(outOpts)
            updateModel = true
          }

          if ($ctrl.useGroups && changesObj.groups) {
            $ctrl.createOptions(changesObj.groups.currentValue, outOpts, ids, $ctrl.groupMapping, true)
            $ctrl.selectGroups = angular.copy(outOpts)
            $ctrl.selectGroupsFull = angular.copy(outOpts)
          }

          if (updateModel) {
            $ctrl.updateModel(ids)
          }
        }

        $ctrl.$doCheck = function () {
          if ($ctrl.ngModel !== undefined && $ctrl.multiple !== angular.isArray($ctrl.ngModel)) {
            $ctrl.updateModel(getModelIds())
          }
          $ctrl.required = $attrs.required
          if ($ctrl.options && !$ctrl.isOpen) {
            $ctrl.notify()
          }
        }

        $ctrl.$onDestroy = function () {
          if ($ctrl.$$interval) {
            $interval.cancel($ctrl.$$interval)
            delete $ctrl.$$interval
          }
        }

        $ctrl.$postLink = function () {
          if ($ctrl.useSearch) {
            var menu, input
            $ctrl.$$interval = $interval(function () {
              menu = $element.find('md-select-menu')
              input = menu.find('input')
              if (input && input.length) {
                input = input[0]
                $interval.cancel($ctrl.$$interval)
                delete $ctrl.$$interval
                menu.on('keydown', function (event) {
                  if (event.key.length === 1) {
                    event.stopImmediatePropagation()
                    input.value = ''
                    input.focus()
                    $interval(
                      function () {
                        var len = input.value.length
                        input.setSelectionRange(len, len)
                      },
                      1,
                      1
                    )
                  }
                })
              }
            })
          }

          $ctrl.showTransclude = $element.find('ng-transclude')[0].children.length
        }

        var getModelIds = function () {
          return Array.isArray($ctrl.ngModel) ? $ctrl.ngModel : ($ctrl.ngModel ? [$ctrl.ngModel] : [])
        }

        $ctrl.createOptions = function (inOpts, outOpts, ids, mapping, isGroup) {
          if (!Array.isArray(inOpts)) {
            inOpts = []
          }
          outOpts.length = 0
          var i, len, option, optId, optDisplay, optTag
          for (i = 0, len = inOpts.length; i < len; i++) {
            optId = helperService.fetchFromObject(inOpts[i], mapping.id)
            optDisplay = helperService.fetchFromObject(inOpts[i], mapping.display)
            optTag = undefined
            if (mapping.tag) {
              var tag = helperService.fetchFromObject(inOpts[i], mapping.tag)
              if (tag) {
                optTag = tag
                tag = tag.toLowerCase()
              }
            }
            option = {
              id: optId,
              display: optDisplay,
              tag: optTag,
            }
            if (isGroup) {
              option.storeIds = inOpts[i].stores
                .map(function (store) {
                  return store.storeId
                })
                .filter(function (storeId) {
                  return ids.includes(storeId)
                })
            } else {
              ids.push(optId)
            }
            outOpts.push(option)
          }
        }

        $ctrl.updateModel = function (optionIds) {
          var modelIds = getModelIds()
          modelIds = $filter('intersection')(optionIds, modelIds)
          if (!modelIds.length) {
            $ctrl.ngModel = undefined
            return
          }
          if ($ctrl.multiple) {
            $ctrl.ngModel = modelIds
            return
          }
          $ctrl.ngModel = modelIds[0]
        }

        $ctrl.toggleModel = function (ids) {
          ids = Array.isArray(ids) ? ids : (ids ? [ids] : [])
          var modelIds = getModelIds()
          if ($ctrl.multiple) {
            for (var i = 0, len = ids.length; i < len; i++) {
              var index = modelIds.indexOf(ids[i])
              if (index !== -1) {
                modelIds.splice(index, 1)
              } else {
                modelIds.push(ids[i])
              }
            }
            modelIds = modelIds.sort(function (a, b) { return a - b })
          } else {
            modelIds = ids
          }
          if (!modelIds.length) {
            $ctrl.ngModel = undefined
            return
          }
          if ($ctrl.multiple) {
            $ctrl.ngModel = modelIds
            return
          }
          $ctrl.ngModel = modelIds[0]
        }

        $ctrl.filterOptions = function () {
          if (!$ctrl.searchFilter) {
            $ctrl.searchFilter = function () {
              var search = $ctrl.ngModelSearch.toLowerCase()
              return function (option) {
                if (option.id.toString() === search) {
                  return true
                }
                if (option.display.toLowerCase().indexOf(search) !== -1) {
                  return true
                }
                if (option.tag &&
                  (
                    option.tag.toLowerCase().startsWith(search) ||
                    option.tag.toLowerCase().replace(' ', '').startsWith(search)
                  )
                ) {
                  return true
                }
                return false
              }
            }
          }

          if ($ctrl.ngModelSearch) {
            $ctrl.selectOptions = $ctrl.selectOptionsFull.filter($ctrl.searchFilter())
            $ctrl.selectGroups = $ctrl.selectGroupsFull.filter($ctrl.searchFilter())
          } else {
            $ctrl.selectOptions = $ctrl.selectOptionsFull
            $ctrl.selectGroups = $ctrl.selectGroupsFull
          }
        }

        $ctrl.onSearchKeydown = function (event) {
          event.stopPropagation()
          if (event.keyCode === 13 && $ctrl.selectOptions.length === 1) {
            $ctrl.toggleModel($ctrl.selectOptions[0].id)
            if (!$ctrl.multiple) {
              $mdSelect.hide()
            }
          }
        }

        $ctrl.dblClick = function (event, option) {
          $ctrl.ngModel = $ctrl.multiple ? [option.id] : option.id
          $mdSelect.hide()
          $ctrl.triggerOnDblclick = true
        }

        $ctrl.onOpen = function () {
          $ctrl.isOpen = true
        }

        $ctrl.onClose = function () {
          $ctrl.isOpen = false
          $ctrl.ngModelSearch = undefined
          $ctrl.filterOptions()
        }

        $ctrl.reset = function () {
          $ctrl.ngModel = undefined
          $mdSelect.hide()
        }

        $ctrl.notify = function () {
          if ($ctrl.onChange && Object.prototype.toString.call($ctrl.onChange) === '[object Function]') {
            if (!$ctrl.optionsFilter) {
              $ctrl.optionsFilter = function () {
                var ids = getModelIds()
                return function (option) {
                  if (ids.includes(helperService.fetchFromObject(option, $ctrl.mapping.id))) {
                    return true
                  }
                  return false
                }
              }
            }

            if (!angular.equals($ctrl.ngModel, $ctrl.ngModelLast)) {
              var values = $ctrl.options ? $ctrl.options.filter($ctrl.optionsFilter()) : []
              $ctrl.onChange($ctrl.ngModel, Array.isArray($ctrl.ngModel) ? values : values[0])
              $ctrl.ngModelLast = angular.copy($ctrl.ngModel)
            }
          }

          if ($ctrl.triggerOnDblclick) {
            if ($ctrl.onDblclick && Object.prototype.toString.call($ctrl.onDblclick) === '[object Function]') {
              $ctrl.onDblclick()
            }
            $ctrl.triggerOnDblclick = false
          }
        }

        $ctrl.groupIsChecked = function (group) {
          if (group.storeIds.length === 0) {
            return false
          }
          var modelIds = getModelIds()
          modelIds = $filter('intersection')(group.storeIds, modelIds)
          group.isChecked = modelIds.length === group.storeIds.length
          return group.isChecked
        }

        $ctrl.groupIsIndeterminate = function (group) {
          var modelIds = getModelIds()
          modelIds = $filter('intersection')(group.storeIds, modelIds)
          group.isIndeterminate = modelIds.length !== 0 && modelIds.length !== group.storeIds.length
          return group.isIndeterminate
        }

        $ctrl.toggleGroup = function (group) {
          var modelIds = getModelIds()
          modelIds = $filter('complement')(modelIds, group.storeIds)
          if (!group.isChecked) {
            modelIds = modelIds.concat(group.storeIds).sort(function (a, b) { return a - b })
          }
          $ctrl.ngModel = modelIds
        }
      },
    })
})()
