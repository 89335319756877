;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.files', []).config(function ($stateProvider, moduleRegisterProvider) {
    $stateProvider.state({
      name: 'files',
      url: '/files?folder',
      params: {
        folder: {
          dynamic: true,
        },
      },
      views: {
        '@': {
          templateUrl: 'src/files/views/layout.html',
        },
        '@files': {
          templateUrl: 'src/files/views/explorer.html',
          controller: 'FilesExplorerController',
        },
      },
    })

    moduleRegisterProvider.add({
      name: 'Rechnungen',
      url: 'files',
      materialIcon: 'folder_special',
      accessRoles: ['admin', 'files'],
    })
  })
})()
