(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.decorators', [])
    .decorator('currencyFilter', currencyFilterDecorator)
    .decorator('mdInputContainerDirective', mdInputContainerDirectiveDecorator)
    .decorator('numberFilter', numberFilterDecorator)

  function currencyFilterDecorator ($delegate, numberFilter, helperService) {
    var currencyFormatRegex = /^([^#]*)#+([^#])(#+)([^#])(#+)([^#]*)$/
    var showedError = false
    return function (amount, customer) {
      if (amount == null || isNaN(amount)) {
        return $delegate(amount)
      }
      var match = currencyFormatRegex.exec(helperService.fetchFromObject(customer, 'currency.format') || '')
      if (!match) {
        if (!showedError) {
          void 0
          showedError = true
        }
        return '#ERR!'
      }
      var prefix = match[1].replace(/\s/g, '\u00a0')
      var groupSep = match[2]
      var groupSize = match[3].length // eslint-disable-line no-unused-vars
      var decimalSep = match[4]
      var fracSize = match[5].length
      var suffix = match[6].replace(/\s/g, '\u00a0')
      amount = numberFilter(amount, fracSize)
      amount = amount.replace(/[.,]/g, function (char) {
        return char === '.' ? groupSep : decimalSep
      })
      return prefix + amount + suffix
    }
  }

  function mdInputContainerDirectiveDecorator ($delegate, $compile) {
    var mdInputContainerDirective = $delegate[0]
    var compile = mdInputContainerDirective.compile
    mdInputContainerDirective.compile = function (tElement) {
      var postLinkOrig = compile(tElement)
      return function postLink (scope, iElement, iAttrs, controller) {
        postLinkOrig.apply(null, arguments)
        if (iAttrs.suffix) {
          addSuffix.apply(null, arguments)
        }
      }
    }

    function addSuffix (scope, iElement, iAttrs, controller) {
      var sElement = document.createElement('span')
      sElement.classList.add('md-input-suffix')
      sElement.style.cssText = 'pointer-events: none; position: absolute; top: 7px; right: 4px;'
      sElement.setAttribute('ng-show', controller.input[0].attributes['ng-model'].value + ' != null')
      sElement.textContent = iAttrs.suffix

      angular.element(iElement[0]).prepend($compile(sElement)(scope))

      var width = getTextDim('&nbsp;' + iAttrs.suffix, getComputedStyle(sElement).font).width
      var paddingRight = parseInt(getComputedStyle(controller.input[0]).paddingRight)
      controller.input[0].style.paddingRight = (paddingRight + width) + 'px'
    }

    function getTextDim (text, font) {
      var tmp = document.createElement('div')
      tmp.innerHTML = text
      tmp.style.cssText = 'position:absolute;top:-999px'
      tmp.style.font = font
      document.body.append(tmp)
      var rect = tmp.getBoundingClientRect()
      tmp.remove()
      return {
        width: rect.width,
        height: rect.height,
      }
    }

    return $delegate
  }

  function numberFilterDecorator ($delegate, $locale) {
    return function (number, fractionSize, options) {
      var result = $delegate(number, fractionSize)
      if (typeof options === 'object') {
        for (var key in options) {
          switch (key) {
            case 'sign':
              if (options.sign && number > 0) {
                result = '+' + result
              }
              break
            case 'zeroAs':
              if (number === 0) {
                result = options.zeroAs
              }
              break
          }
        }
      }
      if (typeof result === 'string') {
        result = result.replace(new RegExp('^' + $locale.NUMBER_FORMATS.PATTERNS[0].negPre), '\u2212')
      }
      return result
    }
  }
})()
