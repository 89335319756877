(function () {
  'use strict'

  angular
    .module('catalog.sellout', [])
    .component('omCatalogSellout', {
      templateUrl: 'src/components/om-catalog-sellout.html',
      bindings: {
        customer: '<',
        stores: '<?',
        titlePrefix: '@?',
        triggerLoad: '<?',
      },
      controller: CatalogSellout,
    })

  function CatalogSellout ($element, CatalogService, UserService) {
    var ctrl = this

    ctrl.isSc = UserService.hasRole(['admin', 'servicecenter'])

    ctrl.$onChanges = function (changes) {
      if (!$element[0].hasAttribute('trigger-load') || (changes.triggerLoad && changes.triggerLoad.currentValue)) {
        load()
      }
    }

    function load () {
      if (ctrl.loading) {
        return
      }
      ctrl.loading = true
      var promise = CatalogService.getSellout(ctrl.customer.customerId, ctrl.stores).$promise
      promise.then(
        function (data) {
          ctrl.data = data
        }
      ).finally(
        function () {
          ctrl.loading = false
        }
      )
    }
  }
})()
