/**
 * @namespace Directives
 */
(function () {
  'use strict'

  /**
   * @description Make an existing ngModel persistent with localStorage.
   * @example <... ng-model="scopeVariable" om-local-storage="storageName">
   */
  angular
    .module('ottomatikStoreManager')
    .directive('omLocalStorage', omLocalStorage)

  /**
   * @namespace LocalStorage
   * @description Get and set value from LocalStorageService.
   * @memberof Directives
   */
  function omLocalStorage (LocalStorageService) {
    function linkFn (scope, element, attrs) {
      var storageName = attrs.omLocalStorage
      if (!storageName) {
        return
      }

      var value = LocalStorageService.get(storageName)
      var model = scope.$eval(attrs.ngModel)
      if (value && model == null) {
        scope.$eval(function (s) {
          if (s.localStorageDisabled) {
            return
          }
          s.$eval(attrs.ngModel + '=' + angular.toJson(value))
        })
      }
      scope.$watch(attrs.ngModel, function (newValue, oldValue, s) {
        if (s.localStorageDisabled) {
          return
        }
        if (newValue &&
          (
            newValue.length ||
            (typeof newValue === 'number' && newValue) ||
            (typeof newValue === 'object' && Object.keys(newValue).length)
          )
        ) {
          LocalStorageService.set(storageName, newValue)
        } else {
          LocalStorageService.remove(storageName)
        }
      })
    }

    return {
      require: {
        ngModelCtrl: 'ngModel',
      },
      restrict: 'A',
      link: linkFn,
    }
  }
})()
