(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager')
    .component('omBonuspoints', {
      bindings: {
        customer: '<',
        params: '<',
      },
      controller: OmBonuspointsController,
      templateUrl: 'src/components/om-bonuspoints.html',
    })

  function OmBonuspointsController ($scope, StatisticService) {
    var $ctrl = this

    $ctrl.$onChanges = function () {
      $ctrl.load()
    }

    $ctrl.load = function () {
      $scope.loading = true

      var params = Object.assign(angular.copy($ctrl.params) || {}, {
        customerId: $ctrl.customer.customerId,
        hideGlobalSpinner: true,
      })

      StatisticService.getBonuspoints(params)
        .then(function (response) {
          $scope.data = response
        })
        .finally(function () {
          $scope.loading = false
        })
    }
  }
})()
