;(function () {
  'use strict'

  angular.module('ottomatikStoreManager').component('qrcode', {
    bindings: {
      data: '@',
      options: '<',
    },
    controller: QRCodeComponentController,
    controllerAs: '$ctrl',
    template: '<canvas ng-show="$ctrl.show"></canvas>',
  })

  function QRCodeComponentController($element, $log) {
    var $ctrl = this

    $ctrl.$onChanges = function (changesObj) {
      var canvas = $element[0].querySelector('canvas')
      if (!canvas) {
        $log.warn('qrcode component: Could not find canvas element')
        $ctrl.show = false
        return
      }

      var data = $ctrl.data
      if (changesObj.data) {
        data = changesObj.data.currentValue
      }
      var options = $ctrl.options
      if (changesObj.options) {
        options = changesObj.options.currentValue
      }

      if (!data) {
        $ctrl.show = false
        return
      }

      QRCode.toCanvas(canvas, data, options, (error) => {
        if (error) {
          $log.error('qrcode component:', error)
          $ctrl.show = false
          return
        }
        $ctrl.show = true
      })
    }
  }
})()
