;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.crm')
    .service('CRMService', CRMService)
    .service('CRMBookingService', CRMBookingService)
    .service('CRMCampaignService', CRMCampaignService)
    .service('PostManagerService', PostManagerService)

  function CRMService(
    CONFIG,
    $filter,
    $mdSidenav,
    $resource,
    $rootScope,
    googleChartApiPromise,
    helperService,
    ChartService,
    UserService
  ) {
    this.overview = overview
    this.customers = customer
    this.customer = customer

    this.csv = exportCsv
    this.mail = exportMail
    this.print = exportPrint
    this.xls = exportXls

    this.addCharts = addCharts
    this.openCustomerDetails = openCustomerDetails

    ////////////////

    var Overview = $resource(CONFIG.API_URL + '/crm/:customerId/overview', { customerId: 0 })
    var Customer = $resource(CONFIG.API_URL + '/crm/:customerId/customers/:phonenumber', {
      customerId: 0,
      phonenumber: '@id',
    })
    var Export = $resource(
      CONFIG.API_URL + '/crm/:customerId/export',
      { customerId: 0 },
      {
        csv: { params: { action: 'csv' }, responseType: 'arraybuffer', transformResponse: helperService.data2download },
        mail: { params: { action: 'mail' } },
        print: { params: { action: 'print' }, responseType: 'document', transformResponse: helperService.data2print },
        xls: { params: { action: 'xls' }, responseType: 'arraybuffer', transformResponse: helperService.data2download },
      }
    )

    function overview(params) {
      return Overview.get(params).$promise
    }

    function customer(params) {
      return Customer.get(params).$promise
    }

    function exportCsv(params) {
      return Export.csv(params).$promise
    }

    function exportMail(params) {
      return Export.mail(params).$promise
    }

    function exportPrint(params) {
      return Export.print(params).$promise
    }

    function exportXls(params) {
      return Export.xls(params).$promise
    }

    ////////////////

    function addCharts(stats) {
      googleChartApiPromise.then(function () {
        stats.charts = {
          newCustomers: getNewCustomersChart(stats.data),
        }
      })
    }

    function getNewCustomersChart(stats) {
      var chart = {
        type: 'ColumnChart',
        data: new google.visualization.DataTable(),
        options: {
          animation: {
            duration: 500,
            easing: 'out',
            startup: true,
          },
          backgroundColor: 'transparent',
          focusTarget: 'category',
          fontName: 'Roboto',
          fontSize: 14,
          height: 350,
          hAxis: {
            gridlines: {},
            minorGridlines: { count: 0 },
            textPosition: 'none',
          },
          vAxis: { viewWindow: { min: 0 } },
          legend: {
            position: 'top',
            textStyle: { fontSize: 12 },
          },
        },
      }

      chart.data.addColumn('date', 'Tag')
      var systems = Object.keys(stats.overview).filter(function (system) {
        if (stats.overview[system].csaId) {
          chart.data.addColumn('number', system)
          return true
        }
        return false
      })
      var rows = []

      var lastDate
      Object.entries(stats.newCustomers).forEach(function (_, i) {
        var date = new Date(_[0])
        date.setHours(12)

        // fill missing days
        if (lastDate) {
          var datediff = helperService.dateDiffDays(date, lastDate)
          while (datediff > 1) {
            var _date = new Date(lastDate)
            _date.setDate(_date.getDate() + 1)
            _date.setHours(12)
            rows.push(
              [{ v: _date, f: $filter('date')(_date, 'EEEE, d. MMMM yyyy') }].concat(
                Array.from(Array(systems.length), function () {
                  return 0
                })
              )
            )
            lastDate = _date
            datediff--
          }
        }

        var row = [{ v: date, f: $filter('date')(date, 'EEEE, d. MMMM yyyy') }]
        var values = _[1]
        systems.forEach(function (system) {
          row.push(values[system] || 0)
        })
        rows.push(row)
        lastDate = date
      })

      chart.data.addRows(rows)
      if (rows.length <= 1) {
        chart.options.hAxis.gridlines = { color: 'none' }
      } else if (rows.length <= 3) {
        chart.options.hAxis.gridlines = { count: 0 }
      } else {
        var count = 0
        for (var r = 0; r < rows.length; r++) {
          var date = new Date(chart.data.getValue(r, 0))
          if (moment(date).weekday() === 0) {
            count++
          }
        }
        chart.options.hAxis.gridlines = { count: count }
      }

      chart.options.colors = systems.map(function (system) {
        return ChartService.getColor(system)
      })

      return chart
    }

    function openCustomerDetails(customer, phonenumber) {
      var params = {
        customerId: customer.customerId,
        phonenumber: phonenumber,
      }

      return this.customer(params).then(function (response) {
        $rootScope.crm = generateDetails(response)
        $rootScope.crm.customer = customer
        $rootScope.crm.linkOrders = UserService.hasRole(['admin', 'servicecenter'])
        $rootScope.crm.onChange = function (values) {
          return Customer.update(params, values).$promise
        }
        return $mdSidenav('crm').open()
      })
    }

    function generateDetails(details) {
      details.hasAccount = false
      details.hasEmailAddress = false

      details.info.denyMailing = Boolean(details.info.denyMailing)
      details.info.deactivated = Boolean(details.info.deactivated)
      details.info.undeliverable = Boolean(details.info.undeliverable)

      details.addresses = []
      details.history = {
        aggregate: [],
        show: false,
        count: 0,
      }
      details.sales = {
        aggregate: [],
        show: false,
        sum: 0,
        list: [],
      }

      var indexes = {
        addresses: {},
        history: {},
        sales: {},
        salesList: {},
      }
      var addressFields = ['anonymized', 'firstname', 'lastname', 'street', 'street_number', 'postcode', 'city']
      details.orders.forEach(function (order) {
        details.hasAccount = details.hasAccount || Boolean(order.customer.hasAccount)
        details.hasEmailAddress = details.hasEmailAddress || Boolean(order.customer.hasEmailAddress)

        if (['closed', 'STORNO'].includes(order.orderStatus)) {
          order.rescinded = true
          return
        }
        if (!details.lastOrder) {
          details.lastOrder = order
        }
        details.firstOrder = order

        // ADDRESS

        if (!order.customer.anonymized || details.addresses.length === 0) {
          var address = {}
          addressFields.forEach(function (field) {
            if (Object.prototype.hasOwnProperty.call(order.customer, field)) {
              address[field] = order.customer[field]
            }
          })
          var addressKey = JSON.stringify(address).toLowerCase()
          if (!Object.prototype.hasOwnProperty.call(indexes.addresses, addressKey)) {
            indexes.addresses[addressKey] = details.addresses.length
            details.addresses.push(order.customer)
          }
        }

        // HISTORY

        var historyKey = order.customerSystemAssociation.system.name
        if (!Object.prototype.hasOwnProperty.call(indexes.history, historyKey)) {
          indexes.history[historyKey] = details.history.aggregate.length
          details.history.aggregate.push({
            system: order.customerSystemAssociation.system,
            count: 0,
          })
        }
        var historyIndex = indexes.history[historyKey]
        details.history.aggregate[historyIndex].count++
        details.history.count++

        // SALES

        var salesKey = order.customerSystemAssociation.system.name
        if (!Object.prototype.hasOwnProperty.call(indexes.sales, salesKey)) {
          indexes.sales[salesKey] = details.sales.aggregate.length
          details.sales.aggregate.push({
            system: order.customerSystemAssociation.system,
            sum: 0,
          })
        }
        var salesIndex = indexes.sales[salesKey]
        details.sales.aggregate[salesIndex].sum += order.total
        details.sales.sum += order.total

        var salesListKey = order.customerSystemAssociation.system.name + order.currentStore.name
        if (!Object.prototype.hasOwnProperty.call(indexes.salesList, salesListKey)) {
          indexes.salesList[salesListKey] = details.sales.list.length
          details.sales.list.push({
            system: order.customerSystemAssociation.system,
            store: order.currentStore,
            sum: 0,
            count: 0,
          })
        }
        var salesListIndex = indexes.salesList[salesListKey]
        details.sales.list[salesListIndex].sum += order.total
        details.sales.list[salesListIndex].count++
      })

      if (details.history.count > 1) {
        var datediff = helperService.dateDiffDays(new Date(details.firstOrder.receivedAt), new Date())
        details.frequency = datediff / (details.history.count - 1)
      }

      return details
    }
  }

  function CRMBookingService(CONFIG, $filter, $resource) {
    this.book = book
    this.export = exportBookings
    this.getBookings = getBookings
    this.getSubscribersCount = getSubscribersCount

    var Booking = $resource(CONFIG.API_URL + '/booking/:id/:action', { id: '@id' })

    function book(campaign, store, date, methods, addParams) {
      var params = {
        campaign: campaign ? campaign.id : undefined,
        store: store ? store.storeId : undefined,
        dispatchDate: $filter('date')(date),
        dispatchMethods: methods,
      }
      if (addParams) {
        Object.assign(params, addParams)
      }
      return Booking.create(params).$promise
    }

    function exportBookings(params) {
      params = Object.assign({ action: 'export' }, params)
      return Booking.export(params).$promise
    }

    function getBookings(params) {
      return Booking.query(params).$promise
    }

    function getSubscribersCount(store) {
      var params = {
        action: 'subscribersCount',
        store: store ? store.storeId : undefined,
      }
      return Booking.get(params).$promise
    }
  }

  function CRMCampaignService(CONFIG, $resource) {
    this.getSettingsList = getSettingsList
    this.createSettings = createSettings
    this.updateSettings = updateSettings

    this.getTypeList = getTypeList
    this.createType = createType
    this.updateType = updateType

    this.getCampaigns = getCampaigns
    this.createCampaign = createCampaign
    this.updateCampaign = createCampaign

    var Settings = $resource(CONFIG.API_URL + '/campaign_settings/:id', { id: '@id' })
    var Type = $resource(CONFIG.API_URL + '/campaign_type/:id', { id: '@id' })
    var Campaign = $resource(CONFIG.API_URL + '/campaign/:id', { id: '@id' })

    function getSettingsList(params) {
      return Settings.query(params).$promise
    }
    function createSettings(data) {
      return Settings.create(data).$promise
    }
    function updateSettings(data) {
      return Settings.update(data).$promise
    }

    function getTypeList(params) {
      return Type.query(params).$promise
    }
    function createType(data) {
      return Type.create(data).$promise
    }
    function updateType(data) {
      return Type.update(data).$promise
    }

    function getCampaigns(params) {
      return Campaign.query(params).$promise
    }
    function createCampaign(data) {
      return Campaign.create({ FILE_UPLOAD: data.imageFile instanceof File }, data).$promise
    }
  }

  function PostManagerService(CONFIG, $resource, PaginationService) {
    var PostManager = $resource(CONFIG.API_URL + '/crm-pm/:customerId/:action')

    this.getCustomers = customers
    this.exportCustomers = download
    this.uploadChanges = upload

    function customers(params) {
      params.action = 'customers'
      return PostManager.query(params, PaginationService.loadFromHeaders).$promise.then(function (list) {
        return {
          total: PaginationService.getCount(),
          list: list,
        }
      })
    }

    function download(params) {
      params.action = 'export'
      return PostManager.export(params).$promise
    }

    function upload(params, file) {
      params.action = 'upload'
      params.FILE_UPLOAD = true
      return PostManager.save(params, { file: file }).$promise
    }
  }
})()
