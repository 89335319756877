;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.crm', []).config(function ($stateRegistryProvider, moduleRegisterProvider) {
    var root = {
      abstract: true,
      name: 'crm',
      url: '/crm',
      templateUrl: 'src/crm/views/layout.html',
      controller: 'CRMController',
      data: {
        daysOffset: 1,
        showStoreSelect: true,
      },
    }

    var overview = {
      name: 'crm.overview',
      url: '/overview',
      views: {
        stats: {
          templateUrl: 'src/crm/views/overview.html',
        },
      },
      data: {
        icon: 'visibility',
        title: 'Übersicht',
      },
    }

    var customers = {
      name: 'crm.customers',
      url: '/customers',
      views: {
        stats: {
          templateUrl: 'src/crm/views/customers.html',
        },
      },
      data: {
        icon: 'contact_phone',
        title: 'Ansicht pro Kunde',
      },
      params: {
        frequency: null,
        system: null,
        type: null,
      },
    }

    var bookings = {
      name: 'crm.bookings',
      url: '/bookings',
      views: {
        '@': {
          templateUrl: 'src/crm/views/bookings.html',
          controller: 'CRMBookingController',
          controllerAs: 'bookings',
        },
      },
      data: {
        daysOffset: undefined,
        months: {
          from: '2022-01',
          to: moment().add(1, 'month').format('YYYY-MM'),
        },
      },
    }

    var bookingsList = {
      name: 'crm.bookings.list',
      url: '/list',
      views: {
        child: {
          templateUrl: 'src/crm/views/bookings.list.html',
          controller: 'CRMBookingListController',
          controllerAs: 'bookingsList',
        },
      },
      params: {
        store: null,
      },
    }

    var bookingsNew = {
      name: 'crm.bookings.new',
      url: '/new',
      views: {
        child: {
          templateUrl: 'src/crm/views/bookings.new.html',
          controller: 'CRMBookingNewController',
          controllerAs: 'bookingsNew',
        },
      },
      params: {
        store: null,
      },
    }

    var campaigns = {
      name: 'crm.campaigns',
      url: '/campaigns',
      views: {
        '@': {
          templateUrl: 'src/crm/views/campaigns.html',
          controller: 'CRMCampaignController',
          controllerAs: 'campaignCtrl',
        },
      },
    }

    var postmngr = {
      name: 'crm.postmngr',
      url: '/post-manager',
      views: {
        '@': {
          templateUrl: 'src/crm/views/postmngr.html',
          controller: 'CRMPostManagerController',
        },
      },
      data: {
        daysOffset: 0,
        showStoreSelect: true,
      },
    }

    $stateRegistryProvider.register(root)
    $stateRegistryProvider.register(overview)
    $stateRegistryProvider.register(customers)
    $stateRegistryProvider.register(bookings)
    $stateRegistryProvider.register(bookingsList)
    $stateRegistryProvider.register(bookingsNew)
    $stateRegistryProvider.register(campaigns)
    $stateRegistryProvider.register(postmngr)

    moduleRegisterProvider.add({
      name: 'Kundenmanagement',
      materialIcon: 'contact_phone',
      accessRoles: ['admin', 'crm'],
      menu: [
        { name: 'Auswertungen', url: 'crm.overview', activeStateCheck: 'crm.customers' },
        { name: 'Kampagnen', url: 'crm.campaigns', accessRoles: ['admin', 'crm_campaigns'] },
        { name: 'Buchungen', url: 'crm.bookings', activeStateCheck: ['crm.bookings.list', 'crm.bookings.new'] },
        { name: 'Post-Manager', url: 'crm.postmngr', accessRoles: ['admin', 'crm_postmngr'] },
      ],
    })
  })
})()
