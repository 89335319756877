/* eslint no-extend-native: "off" */
;(function () {
  'use strict'

  // https://stackoverflow.com/a/1985308/3087041
  if (!Array.prototype.rotate) {
    Object.defineProperty(Array.prototype, 'rotate', {
      configurable: true,
      enumerable: false,
      writable: true,
      value: function (n) {
        n = n % this.length
        while (this.length && n < 0) n += this.length
        this.push.apply(this, this.splice(0, n))
        return this
      },
    })
  }

  // https://github.com/tc39/proposal-accessible-object-hasownproperty/blob/main/polyfill.js
  if (!Object.hasOwn) {
    Object.defineProperty(Object, 'hasOwn', {
      value: function (object, property) {
        if (object == null) {
          throw new TypeError('Cannot convert undefined or null to object')
        }
        return Object.prototype.hasOwnProperty.call(Object(object), property)
      },
      configurable: true,
      enumerable: false,
      writable: true,
    })
  }
})()
