(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager')
    .component('omStatistics', {
      bindings: {
        customer: '<',
        stats: '<?',
        type: '@',
      },
      controller: ComponentController,
      controllerAs: '$ctrl',
      template: '<ng-include src="$ctrl.getTemplateUrl()"></ng-include>',
    })

  function ComponentController ($attrs, $scope, StatisticService) {
    var $ctrl = this

    $ctrl.getTemplateUrl = function () {
      return 'src/components/om-statistics-' + $ctrl.type + '.html'
    }

    $ctrl.$onInit = function () {
      if ($attrs.$attr.loadStats) {
        var params = {
          customerId: $ctrl.customer.customerId,
          type: $ctrl.type,
          hideGlobalSpinner: true,
        }

        $scope.promise = StatisticService.getOrders(params)
        $scope.promise
          .then(function (response) {
            $scope.statsDate = response.dateFirst
            $ctrl.stats = response.statistics
            $ctrl.calculateTotals($ctrl.stats)
          })
      }
    }

    $ctrl.$onChanges = function (changesObj) {
      $scope.customer = $ctrl.customer

      if (changesObj.stats && changesObj.stats.currentValue) {
        $ctrl.stats = angular.copy(changesObj.stats.currentValue)
        if ($ctrl.type === 'list') {
          $ctrl.calculateTotalsList($ctrl.stats)
          return
        }
        $ctrl.calculateTotals($ctrl.stats)
      }
    }

    $ctrl.calculateTotals = function (stats) {
      $scope.totals = {
        system: 'Gesamt',
      }
      stats.forEach(function (row, index) {
        for (var key in row) {
          if (typeof row[key] === 'string') {
            continue
          }
          if (Object.prototype.hasOwnProperty.call(row, key)) {
            // sum arrays
            if (angular.isArray(row[key]) && row[key].length > 0) {
              $scope.totals[key] = ($scope.totals[key] || []).concat(row[key])
              continue
            }

            // sum objects
            if (angular.isObject(row[key])) {
              if (!$scope.totals[key]) {
                $scope.totals[key] = {}
              }
              for (var subKey in row[key]) {
                $scope.totals[key][subKey] = ($scope.totals[key][subKey] || 0) + row[key][subKey]
              }
              continue
            }

            // sum numbers
            if (!$scope.totals[key]) {
              $scope.totals[key] = 0
            }
            $scope.totals[key] += row[key]

            if (key.startsWith('fee') && row[key] > 0) {
              stats[index].showFees = true
              $scope.totals.showFees = true
            }
          }
        }
      })
      if (stats.length >= 2) {
        stats.push($scope.totals)
      }

      $ctrl.generateDiscountTypes($ctrl.customer)
    }

    $ctrl.calculateTotalsList = function (stats) {
      stats.forEach(function (row) {
        Object.keys($scope.tableListFields).forEach(function (field) {
          if ($scope.tableListFields[field].calc) {
            row[field] = $scope.tableListFields[field].calc(row)
            return
          }

          var sum = 0
          row.stats.forEach(function (system) {
            sum += system[field]
          })
          row[field] = sum
        })
      })
    }

    $ctrl.generateDiscountTypes = function (customer) {
      var discountTypes = {
        app: 'App-Rabatt',
        product: 'Produkt-Rabatt',
        multiproduct: 'Multi-Produkt-Rabatt',
        selfcollector: 'Selbstabholer-Rabatt',
      }

      switch (customer.name) {
        case 'Call a Pizza':
          discountTypes.bonuspoints = 'VIP-Punkte'
          break
        case 'Tele Pizza':
          discountTypes.telepoints = 'Tele Points'
          break
      }

      // add misc discount
      discountTypes.misc = 'Sonstiges'
      var showMisc = false
      $ctrl.stats.forEach(function (row) {
        if (row.system === 'Gesamt') {
          if (showMisc) {
            row.discountTypes.misc = -1
          }
          return
        }
        if (row.discountTypes == null || Object.keys(row.discountTypes).length === 0) {
          return
        }
        var misc = row.discountAmount
        for (var discountType in discountTypes) {
          misc -= row.discountTypes[discountType] || 0
        }
        if (Number(misc).toFixed(0) < 0) {
          showMisc = true
          row.discountTypes.misc = misc
        }
      })

      $scope.discountTypes = discountTypes
    }

    $scope.sort = {
      field: 'ordersTotal',
      reverse: true,
      order: function (newField, reverse) {
        if (angular.equals(this.field, newField)) {
          this.reverse = !this.reverse
          return
        }
        this.field = newField
        this.reverse = reverse != null ? reverse : true
      },
    }

    $scope.tableFullFields = {
      Total: 'Gesamtanzahl aller Bestellungen',
      Servicecenter: 'Vermittelt durch Servicecenter',
      LoggedIn: 'Bestellungen als eingeloggter User',
      ShippingDelivery: 'Bestellungen mit Lieferung',
      ShippingSelfCollector: 'Bestellungen mit Selbstabholer',
      ShippingRestaurant: 'Bestellungen mit Verzehr im Restaurant',
      ShippingStaff: 'Bestellungen vom Personal',
      WebsiteDesktop: 'Bestellt über Website (Desktop)',
      WebsiteTablet: 'Bestellt über Website (Tablet)',
      WebsitePhone: 'Bestellt über Website (Phone)',
      AppIos: 'Bestellt über App (iOS)',
      AppAndroid: 'Bestellt über App (Android)',
      AppWindows: 'Bestellt über App (Windows)',
      AssistantAlexa: 'Bestellt über Amazon Alexa',
      AssistantGoogle: 'Bestellt über Google Assistant',
      PaymentCash: 'Bezahlt mit Barzahlung',
      PaymentEc: 'Bezahlt mit EC-Karte',
      PaymentCreditcard: 'Bezahlt mit Kreditkarte',
      PaymentApple: 'Bezahlt mit Apple Pay',
      PaymentGoogle: 'Bezahlt mit Google Pay',
      PaymentOnline: 'Bezahlt mit Online-Bezahldienst',
      PaymentPaypal: '  davon mit PayPal',
      PaymentGiropay: '  davon mit giropay',
      PaymentInstanttransfer: '  davon mit Klarna Sofort',
      PaymentAmazon: '  davon mit Amazon Pay',
    }

    $scope.tableMeanFields = {
      subTotal: 'Zwischensumme',
      discountAmount: 'Rabatte',
      depositAmount: 'Pfand',
      shippingAmount: 'Anfahrtskosten',
    }

    $scope.tableRescindedFields = {
      Rescinded: 'Stornierte Bestellungen',
      RescindedOnline: '  davon mit Online-Bezahldienst',
    }

    $scope.tableOnlinePaymentFields = {
      Paypal: 'PayPal',
      Giropay: 'giropay',
      Instanttransfer: 'Klarna Sofort',
      Amazon: 'Amazon Pay',
    }

    $scope.tableListFields = {
      ordersTotal: {
        title: 'Bestellungen',
        filter: 'number',
      },
      turnoverTotal: {
        title: 'Umsatz <i class="gross"></i>',
        filter: 'currency',
      },
      turnoverMean: {
        title: '&#x2300;-Bon <i class="gross"></i>',
        filter: 'currency',
        calc: function (data) {
          return data.turnoverTotal / data.ordersTotal
        },
      },
      ordersRescinded: {
        title: 'Stornos',
        filter: 'number',
      },
    }
  }
})()
