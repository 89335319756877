;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.statistics')
    .controller('StatisticsSubAccController', StatisticsSubAccController)

  function StatisticsSubAccController($compile, $filter, $scope, $templateRequest, $timeout, StatisticService) {
    var vm = this

    vm.export = exportCsv
    vm.load = load
    vm.month = createMonthSelect()

    ////////////////

    function createChartTooltip(collection, entry, typeKey) {
      var scope = $scope.$new(true)

      scope.customer = $scope.filter.customer
      scope.store = $scope.filter.store
      scope.typeKey = typeKey
      scope.type = vm.types[typeKey]
      scope.entry = entry
      scope.prev = {
        week: getPreviousWeek(collection, entry),
        month: getPreviousMonth(collection, entry),
      }

      return new Promise((resolve) => {
        var element = $compile(vm.tooltipTpl)(scope)
        $timeout(() => {
          resolve(element[0].outerHTML)
        })
      })
    }

    function createMonthSelect() {
      var format = 'MMMM YYYY'
      var min = moment('2022-04').toDate()
      var max = moment().startOf('month').toDate()
      return {
        min: min,
        max: max,
        from: min,
        to: max,
        dateLocale: {
          parseDate: (dateString) => {
            var m = moment(dateString, format, 'de')
            return m.isValid() ? m.toDate() : new Date(NaN)
          },
          formatDate: (date) => {
            var m = moment(date)
            return date && m.isValid() ? m.format(format) : ''
          },
          isDateComplete: (dateString) => {
            var m = moment(dateString, format, 'de')
            return m.isValid()
          },
        },
      }
    }

    function createTypesCheckboxes(customer) {
      var accountTitle = customer && customer.name == 'Call a Pizza' ? 'VIP-Konten' : 'Benutzerkonten'
      return {
        subscribersNewsletter: {
          active: true,
          title: 'Newsletter',
          titleLong: 'Newsletter-Abonnenten',
        },
        subscribersPush: {
          active: true,
          title: 'Push',
          titleLong: 'Push-Abonnenten',
        },
        accounts: {
          active: true,
          title: accountTitle,
          titleLong: accountTitle,
        },
      }
    }

    function drawChart(filter, result) {
      var datatable = new google.visualization.DataTable()

      datatable.addColumn('date', 'Tag')
      Object.keys(filter).forEach((typeKey) => {
        datatable.addColumn('number', vm.types[typeKey].title)
        datatable.addColumn({ type: 'string', role: 'tooltip', p: { html: true } })
      })

      result.forEach((entry, index) => {
        var row = [new Date(entry.date)]
        Object.keys(filter).forEach((typeKey, jndex) => {
          row.push(entry[typeKey])
          row.push('## TOOLTIP ##')

          createChartTooltip(result, entry, typeKey).then((tooltip) => {
            datatable.setCell(index, 2 * jndex + 2, tooltip)
          })
        })
        datatable.addRow(row)
      })

      if (datatable.getNumberOfColumns() <= 1) {
        delete vm.chart
        return
      }

      vm.chart = {
        type: 'LineChart',
        data: datatable,
        options: {
          backgroundColor: 'transparent',
          fontName: 'Roboto',
          fontSize: 14,
          height: 500,
          legend: { position: 'top' },
          lineWidth: 4,
          series: {
            0: { pointShape: 'circle', pointSize: 10 },
            1: { pointShape: 'triangle', pointSize: 16 },
            2: { pointShape: 'square', pointSize: 12 },
          },
          tooltip: { isHtml: true },
          hAxis: { gridlines: { count: datatable.getNumberOfColumns() - 1 }, minorGridlines: { count: 0 } },
          vAxis: { viewWindow: { min: 0 } },
        },
      }
    }

    function exportCsv() {
      if (vm.loading || vm.exportParams == null) {
        return
      }

      vm.loading = true
      return StatisticService.exportAccountsSubscribers(vm.exportParams).finally(() => {
        vm.loading = false
      })
    }

    function load() {
      if (vm.loading) {
        return
      }

      var monthFrom = moment(vm.month.from).startOf('month').toDate()
      var monthTo = moment(vm.month.to).endOf('month').toDate()
      var params = {
        customerId: $scope.filter.customerId,
        storeId: $scope.filter.storeId,
        dateFrom: $filter('date')(monthFrom),
        dateTo: $filter('date')(monthTo),
        filter: Object.keys(vm.types).reduce((filter, key) => {
          if (vm.types[key].active) {
            filter[key] = true
          }
          return filter
        }, {}),
      }

      vm.loading = true
      return StatisticService.getAccountsSubscribers(params)
        .then((response) => {
          vm.result = response
          vm.resultFrom = monthFrom
          vm.resultTo = monthTo
          vm.exportParams = params
          drawChart(params.filter, response)
        })
        .finally(() => {
          vm.loading = false
        })
    }

    ////////////////

    var deregistrators = []
    $scope.$on('$destroy', () => deregistrators.forEach((deregistrator) => deregistrator))

    deregistrators.push(
      $scope.$watch('filter.customer', (customer) => {
        vm.types = createTypesCheckboxes(customer)
      })
    )

    $templateRequest('src/statistics/views/subacc-tooltip.html').then((tpl) => {
      vm.tooltipTpl = tpl
    })
  }

  function getPreviousWeek(collection, entry) {
    var m = moment(entry.date).subtract(1, 'week')
    return collection.find((e) => moment(e.date).isSame(m, 'day'))
  }

  function getPreviousMonth(collection, entry) {
    var m = moment(entry.date).subtract(1, 'month')
    var element = collection.find((e) => Math.abs(moment(e.date).diff(m, 'days')) <= 3)
    if (element) {
      return element
    }
    m.startOf('month')
    return collection.find((e) => moment(e.date).isSame(m, 'day'))
  }
})()
