/**
 * @namespace Directives
 */
(function () {
  'use strict';

  var TIME_REGEX = /^(?:[01]?[0-9]|2[0-3]):[0-5][0-9]$/;

  var REL_OPERATORS = {
    eq: '==',
    is: '===',
    ne: '!=',
    ni: '!==',
    gt: '>',
    lt: '<',
    ge: '>=',
    le: '<=',
  };

  /**
   * @example <... om-time>
   */
  angular
    .module('ottomatikStoreManager')
    .directive('omTime', omTime);

  /**
   * @namespace Time
   * @memberof Directives
   */
  function omTime() {

    function linkFn(scope, element, attrs, ngModelCtrl) {

      // base validator
      ngModelCtrl.$validators['om-time'] = function (modelValue, viewValue) {
        if (ngModelCtrl.$isEmpty(viewValue)) {
          return true;
        }
        if (TIME_REGEX.test(viewValue)) {
          return true;
        }
        return false;
      };

      // relational validators
      var config = scope.$eval(attrs.omTime);
      if (config instanceof Object) {
        for (var prop in config) {
          if (!config.hasOwnProperty(prop)) {
            continue;
          }
          prop = prop.toLowerCase();
          if (!Object.keys(REL_OPERATORS).includes(prop)) {
            continue;
          }
          (function (prop) {
            var validator = 'om-time-' + prop;
            ngModelCtrl.$validators[validator] = function (modelValue, viewValue) {
              var otherValue = scope.$eval(attrs.omTime)[prop];
              if (ngModelCtrl.$isEmpty(viewValue) || ngModelCtrl.$isEmpty(otherValue)) {
                return true;
              }
              // check base validator first
              if (!ngModelCtrl.$validators['om-time'](modelValue, viewValue)) {
                return true;
              }
              viewValue = '0'.repeat(5 - viewValue.length) + viewValue;
              otherValue = '0'.repeat(5 - otherValue.length) + otherValue;
              var operator = REL_OPERATORS[prop];
              return scope.$eval('"' + viewValue + '"' + operator + '"' + otherValue + '"');
            };
          })(prop);
        }
      }

    }

    return {
      require: 'ngModel',
      restrict: 'A',
      link: linkFn,
    };
  }
})();
