;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.tutorials')
    .controller('TutorialAdminController', TutorialAdminController)
    .run(GlobalTutorialButton)

  function TutorialAdminController($mdDialog, TutorialService, CustomerService, $stateRegistry, moduleRegister) {
    var vm = this
    vm.new = (event) => openTutorialForm(event, {}, TutorialService.createTutorial)
    vm.edit = (event, tutorial) => openTutorialForm(event, tutorial, TutorialService.updateTutorial)
    vm.delete = deleteTutorial
    vm.showWatchers = showWatchers

    initCustomerSelect()

    ////////////////

    function initCustomerSelect() {
      if (!vm.customerSelect) {
        vm.customerSelect = (customerId, customer) => {
          vm.customerId = customerId
          vm.customer = customer
          loadTutorials(customer)
        }
      }
      return CustomerService.getCustomers().$promise.then((response) => {
        vm.customers = response
        return response
      })
    }

    function loadTutorials(customer) {
      delete vm.tutorials
      return TutorialService.getTutorials(customer).then((response) => {
        vm.tutorials = response
        return response
      })
    }

    function openTutorialForm(event, tutorial, saveHandler) {
      var isNew = angular.equals(tutorial, {})
      return $mdDialog
        .show({
          templateUrl: 'src/tutorials/views/admin.dialog-form.html',
          targetEvent: event,
          controller: TutorialAdminFormController,
          locals: {
            saveHandler: saveHandler,
            customer: vm.customer,
            tutorial: angular.copy(tutorial),
          },
          bindToController: true,
          controllerAs: 'dialog',
        })
        .then((savedTutorial) => {
          if (isNew) {
            vm.tutorials.push(savedTutorial)
          } else {
            angular.copy(savedTutorial, tutorial)
          }
        })
    }

    function deleteTutorial(event, tutorial) {
      var confirm = $mdDialog
        .confirm()
        .title('Video löschen')
        .textContent('Möchten Sie das Video löschen?')
        .ok('Ja, löschen')
        .cancel('Nein, abbrechen')
        .targetEvent(event)

      confirm._options.focusOnOpen = false

      $mdDialog.show(confirm).then(() => {
        tutorial.$delete({ customerId: vm.customer.customerId }).then(() => {
          var index = vm.tutorials.indexOf(tutorial)
          if (index !== -1) {
            vm.tutorials.splice(index, 1)
          }
        })
      })
    }

    function showWatchers(event, tutorial) {
      if (tutorial.watchers.length == 0) {
        return
      }
      $mdDialog.show({
        templateUrl: 'src/tutorials/views/admin.dialog-watchers.html',
        targetEvent: event,
        clickOutsideToClose: true,
        controller: () => {},
        locals: {
          watchers: tutorial.watchers,
          close: $mdDialog.hide,
        },
        bindToController: true,
        controllerAs: 'dialog',
      })
    }
  }

  function TutorialAdminFormController($mdDialog, moduleRegister) {
    var dialog = this

    dialog.states = moduleRegister.getMenuItems().reduce((states, root) => {
      if (root.hidden) {
        return states
      }

      var subContainsRootState = false
      var rootName = getItemARN(root, 'admin')

      var subStates = root.sub.reduce((states, sub) => {
        if (sub.hidden) {
          return states
        }
        if (root.url == sub.url) {
          subContainsRootState = true
        }

        var subName = getItemARN(sub, 'admin')

        states.push({ id: sub.url, display: rootName + ' → ' + subName })

        return states
      }, [])

      if (!subContainsRootState) {
        states.push({ id: root.url, display: rootName })
      }

      Array.prototype.push.apply(states, subStates)

      return states
    }, [])

    dialog.cancel = (event) => {
      $mdDialog.cancel()
    }

    dialog.save = (event) => {
      dialog.loading = true
      dialog
        .saveHandler(dialog.customer, dialog.tutorial)
        .then((response) => $mdDialog.hide(response))
        .finally(() => {
          dialog.loading = false
        })
    }

    function getItemARN(item, role) {
      return Object.entries(item.arn || {}).reduce((name, keyValue) => {
        var arName = keyValue[0]
        var accessRoles = keyValue[1]
        if (accessRoles.includes(role)) {
          return arName
        }
        return name
      }, item.name)
    }
  }

  function GlobalTutorialButton($compile, $rootScope, $transitions, TutorialService, UserService) {
    var $scope = $rootScope.$new()
    var wrapper
    var players = []

    $transitions.onSuccess({}, (transition) => {
      if (!$rootScope.globals.currentUser || !UserService.hasRole('tutorials')) {
        removeWrapper()
        wrapper = null
        return
      }

      if (!wrapper) {
        wrapper = createWrapper()
      }

      $scope.tutorials.isOpen = false
      TutorialService.getTutorialsForState(transition.to().name).then((response) => {
        loadVideos(response)
      })
    })

    function removeWrapper() {
      var div = document.querySelector('div.tutorials-wrapper')
      if (div) {
        div.parentNode.removeChild(div)
      }
    }

    function createWrapper() {
      var div = document.createElement('div')
      div.classList.add('tutorials-wrapper', 'ng-hide')
      div.setAttribute('ng-show', 'tutorials.show')
      div.setAttribute('ng-include', "'src/tutorials/views/global-button.html'")
      document.body.appendChild(div)

      $scope.tutorials = {
        show: false,
        isOpen: false,
        isNew: false,
        videos: [],
        toggle: function (event) {
          this.isOpen = !this.isOpen
          if (!this.isOpen) {
            players.forEach((player) => {
              player.pause()
            })
          }
        },
        openWindow: function (event) {
          this.videos.forEach((video) => {
            var url = video.url.replace(
              /(https?):\/\/player\.(vimeo\.com)\/video\/(\d+)(?:\?h=(\d+))?/,
              (match, protocol, domain, id, h) => protocol + '://' + domain + '/' + id + (h ? '/' + h : '')
            )
            window.open(url, '_blank').focus()
          })
        },
      }

      $compile(angular.element(div))($scope)

      return div
    }

    function loadVideos(tutorials) {
      $scope.tutorials.show = tutorials.length > 0
      $scope.tutorials.isNew = tutorials.some((tutorial) => tutorial.new)
      $scope.tutorials.videos = tutorials
      $scope.$$postDigest(() => {
        initVideoFrames(tutorials)
      })
    }

    function initVideoFrames(tutorials) {
      players = []

      var options = {
        byline: false,
        playsinline: false,
        portrait: false,
      }

      tutorials.forEach((tutorial) => {
        options.url = tutorial.url
        var containerId = 'tutorial-id-' + tutorial.id
        var player = new Vimeo.Player(containerId, options)
        players.push(player)

        if (tutorial.new) {
          player.on('play', () => {
            TutorialService.registerEvent(tutorial, 'play').then(() => {
              tutorial.new = false
              $scope.tutorials.isNew = $scope.tutorials.videos.some((tutorial) => tutorial.new)
              player.off('play')
            })
          })
        }
      })
    }
  }
})()
