;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.transmissions', [])
    .config(function ($stateProvider, moduleRegisterProvider) {
      $stateProvider.state('transmissions', {
        url: '/transmissions',
        views: {
          '@': {
            templateUrl: 'src/transmissions/views/layout.html',
          },
          'colMain@transmissions': {
            templateUrl: 'src/transmissions/views/transmissions.html',
            controller: 'TransmissionsController',
          },
        },
      })

      moduleRegisterProvider.add({
        name: 'Bestellannahme',
        materialIcon: 'receipt',
        url: 'transmissions',
        accessRoles: ['admin', 'transmissions', 'transmissions_readonly'],
      })
    })
    .constant('TRANSMISSIONS_NOTIFICATION_ID', 'notification-new-transmissions')
    .constant('TRANSMISSIONS_CHECK_SECONDS', 15)
    .constant('TRANSMISSIONS_RELOAD_SECONDS', 60)
    .constant('TRANSMISSIONS_WARN_THRESHOLD', 15)
    .run(function (
      $compile,
      $interval,
      $rootScope,
      $state,
      $templateRequest,
      CustomerService,
      TransmissionsService,
      UserService,
      TRANSMISSIONS_NOTIFICATION_ID,
      TRANSMISSIONS_CHECK_SECONDS
    ) {
      var checkInterval, div, scope

      $rootScope.$watch('globals.currentUser', () => {
        var userCustomerId = UserService.getCustomerId()
        var userHasRoleTransmissions = UserService.hasRole('transmissions')

        if (userCustomerId === 999999999 || !userHasRoleTransmissions) {
          $interval.cancel(checkInterval)
          if (div) {
            div.parentNode.removeChild(div)
            div = undefined
          }
          return
        }

        $templateRequest('src/templates/notification-new-transmissions.html').then((template) => {
          if (!div) {
            div = createElement(template)
            scope = createScope(div)
            $compile(angular.element(div))(scope)
          }

          CustomerService.getStores(userCustomerId).$promise.then((response) => {
            if (response.length != 1) {
              return
            }
            var storeId = response[0].storeId
            $interval.cancel(checkInterval)
            checkInterval = $interval(() => {
              if ($state.is('transmissions')) {
                scope.close()
                return
              }
              TransmissionsService.checkForNewTransmissions({ storeId: storeId }).then((response) => {
                scope.count = response.count
                if (scope.count > 0) {
                  scope.show()
                }
              })
            }, TRANSMISSIONS_CHECK_SECONDS * 1000)
          })
        })
      })

      function createElement(template) {
        var el = document.createElement('div')
        el.id = TRANSMISSIONS_NOTIFICATION_ID
        el.style.display = 'none'
        el.innerHTML = template
        document.body.appendChild(el)
        return el
      }

      function createScope(el) {
        var scope = $rootScope.$new()
        scope.count = 0
        scope.close = function () {
          el.style.display = 'none'
        }
        scope.show = function () {
          el.style.display = 'block'
        }
        scope.goto = function () {
          this.close()
          $state.go('transmissions')
        }
        return scope
      }
    })
})()
