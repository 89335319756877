(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.ranking')
    .controller('RankingController', RankingController)
    .controller('RankingCapController', RankingCapController)

  function RankingController ($filter, $scope, LocalStorageService, RankingService, UserService) {
    var self = this
    self.defaultSortField = 'avgSeconds'

    $scope.showScore = UserService.hasRole('ranking-score')

    $scope.sort = {
      field: self.defaultSortField,
      options: {
        avgSeconds: 'Gesamtzeit',
        countOrdersFinishedLate: 'Anzahl verspäteter Bestellungen',
        countOrders: 'Anzahl Bestellungen',
        sumTotal: 'Umsatz',
      },
    }

    $scope.load = function () {
      if ($scope.loading) {
        return
      }
      $scope.loading = true

      var params = {
        customerId: $scope.filter.customerId,
        dateFrom: $filter('date')($scope.filter.dates.from),
        dateTo: $filter('date')($scope.filter.dates.to),
      }

      if (self.customerId !== params.customerId) {
        $scope.ranking = undefined
      }

      RankingService.get(params)
        .then(function (ranking) {
          if (!$scope.ranking) {
            self.customerId = params.customerId
            $scope.rankByScore = Boolean($scope.filter.customer.options.transmissionStatusRankingFormula)
            if ($scope.rankByScore) {
              $scope.sort.field = 'score'
            } else {
              $scope.sort.field = LocalStorageService.get('ranking_field', self.defaultSortField)
            }
          }
          $scope.ranking = ranking
        })
        .finally(function () {
          $scope.loading = false
        })
    }

    $scope.quickSelect = function (type) {
      $scope.filter.month.select = $scope.filter.month.options[0]
      $scope.filter.month.change()
      switch (type) {
        case 'yesterday':
          $scope.filter.dates.from = moment().startOf('day').subtract(1, 'days').toDate()
          $scope.filter.dates.to = moment().startOf('day').subtract(1, 'days').toDate()
          break
        case 'last 7 days':
          $scope.filter.dates.from = moment().startOf('day').subtract(7, 'days').toDate()
          $scope.filter.dates.to = moment().startOf('day').subtract(1, 'days').toDate()
          break
        case 'current month':
          $scope.filter.dates.from = moment().startOf('month').toDate()
          $scope.filter.dates.to = moment().startOf('day').toDate()
          break
        default:
          throw new Error('Unknown quick select type: ' + type)
      }
      $scope.filter.dates.change()
      $scope.load()
    }
  }

  function RankingCapController ($filter, $interval, $mdDialog, $mdMedia, $scope, $state, $timeout, $transitions, CustomerService, RankingService) {
    var customerId = 2
    var chartsInitScope
    var chartsStore = {}
    var minHighlightWidth = 480
    var deregisterTransitionHook

    $scope.customer = undefined
    CustomerService.getCustomer(customerId).$promise.then(
      function (customer) {
        $scope.customer = customer
        $scope.sales.systems = customer.customerSystemAssociations.filter(
          function (csa) {
            return csa.active
          }
        ).map(
          function (csa) {
            return csa.system
          }
        )
        $scope.sales.system = $scope.sales.systems.map(
          function (system) {
            return system.systemId
          }
        )
      }
    )

    deregisterTransitionHook = $transitions.onSuccess(
      { to: 'ranking_cap.**' },
      function (transition) {
        $scope.stateTransition(transition.to())
      }
    )

    $scope.stateTransition = function (state) {
      var stateData = state.data || {}
      $scope.view = state.name.replace('ranking_cap.', '')
      $scope.currentTitle = stateData.title
      $scope.showDateRange = stateData.showDateRange
      $scope.showLastLoad = stateData.showLastLoad
      if ($scope.view === 'live') {
        $scope.load()
      } else {
        $scope.autoreload.stop()
      }
    }

    $scope.childStates = $state.get().filter(
      function (state) {
        return !state.abstract && state.name.indexOf('ranking_cap.') === 0
      }
    )

    $scope.sales = {
      metric: undefined,
      metrics: [
        {
          id: 'sales_count',
          title: 'Online-Bestellungen',
        },
        {
          id: 'sales_total',
          title: 'Online-Umsatz',
        },
      ],
      system: undefined,
      systems: [],
      charts: {
        init: false,
        show: false,
      },
    }
    $scope.sales.metric = $scope.sales.metrics[0]

    $scope.times = {
      metric: undefined,
      metrics: [
        {
          id: 'sum',
          title: 'Auslieferungszeit',
        },
        {
          id: 'preparation',
          title: 'Küchenzeit',
        },
        {
          id: 'delivery',
          title: 'Fahrzeit',
        },
      ],
    }
    $scope.times.metric = $scope.times.metrics[0]

    $scope.live = {
      metric: undefined,
      metrics: [
        {
          id: 'count',
          title: 'Online-Bestellungen',
        },
        {
          id: 'total',
          title: 'Online-Umsatz',
        },
        {
          id: 'time',
          title: 'Lieferzeit',
        }
      ],
    }
    $scope.live.metric = $scope.live.metrics[0]

    $scope.$watch('sales.metric.id', function (newValue, oldValue) {
      if (newValue !== oldValue) {
        $timeout(redrawCharts, 10)
      }
    })

    $scope.$watch('sales.system', function (newValue, oldValue) {
      if (newValue !== oldValue && Array.isArray(newValue)) {
        $scope.sales.systemIdString = newValue.join('_')
        chartSystemsInSync = false
        $timeout(redrawCharts, 10)
      }
    })

    $scope.$watch('sales.charts.show', function (show) {
      if (show && !$scope.sales.charts.init) {
        chartsInitScope = $scope.$new(true)
        chartsInitScope.title = 'Diagramme werden generiert'
        chartsInitScope.current = 0
        chartsInitScope.total = $scope.ranking.filter(
          function (rank) {
            return rank.visible
          }
        ).length
        $mdDialog.show({
          templateUrl: 'src/templates/dialog-progress.html',
          escapeToClose: false,
          scope: chartsInitScope,
        })
        $scope.sales.charts.init = true
      }
    })

    $scope.$on('$destroy', function () {
      $scope.autoreload.stop()
      deregisterTransitionHook()

      // cleanup charts debris
      $scope.sales.charts.init = false
      $scope.sales.charts.show = false
      document.querySelectorAll('body > div[style] > div[style*=infobackground]')
        .forEach(function (el) {
          el.parentNode.parentNode.removeChild(el.parentNode)
        })
    })

    $scope.load = function (filter) {
      if (!$scope.customer) {
        $timeout(
          function () {
            $scope.load(filter)
          },
          500
        )
        return
      }
      if ($scope.loading) {
        return
      }
      $scope.loading = true

      var params = {
        customerId: $scope.customer.customerId,
        type: $scope.view,
      }

      if (params.type !== 'live') {
        var dateFrom = filter.dates.from
        var dateTo = filter.dates.to
        params.dateFrom = $filter('date')(dateFrom)
        params.dateTo = $filter('date')(dateTo)
      }

      RankingService.get(params).then(
        function (ranking) {
          if (params.type === 'live') {
            $scope.rankingLive = ranking
          } else {
            RankingService.addCharts(ranking, $scope.customer)
            $scope.ranking = ranking
            $scope.currentDateFrom = dateFrom
            $scope.currentDateTo = dateTo
          }
          $scope.lastLoad = new Date()
        }
      ).finally(
        function () {
          $scope.loading = false
          if (params.type === 'live') {
            $scope.autoreload.start()
          }
        }
      )
    }

    $scope.autoreload = {
      enabled: true,
      current: 0,
      max: 60,
      interval: null,
      toggle: function () {
        this.enabled = !this.enabled
        if (this.enabled) {
          $scope.load()
        } else {
          this.current = 0
          this.stop()
        }
      },
      start: function () {
        if (this.enabled) {
          this.stop()
          this.current = this.max
          this.interval = $interval(
            function () {
              $scope.autoreload.current--
              if ($scope.autoreload.current === 0) {
                $scope.autoreload.stop()
                $scope.load()
              }
            },
            1000
          )
        }
      },
      stop: function () {
        $interval.cancel(this.interval)
        this.interval = null
      },
    }

    $scope.getBoxFlex = function (place) {
      if (place >= 1 && place <= 3) {
        if ($mdMedia('min-width: ' + (3 * minHighlightWidth) + 'px')) {
          return 33
        }
        if ($mdMedia('min-width: ' + (2 * minHighlightWidth) + 'px')) {
          return place === 1 ? 100 : 50
        }
        return 100
      }
      if ($mdMedia('gt-md')) {
        return 50
      }
      return 100
    }

    $scope.getBoxLayout = function (place) {
      if (place <= 3) {
        return 'column'
      }
      return 'row'
    }

    $scope.chartOnReady = function (rank, chartWrapper) {
      var isNew = !chartsStore[rank.store.storeId]
      chartsStore[rank.store.storeId] = chartWrapper
      if (isNew && chartsInitScope) {
        if (chartsInitScope.current < chartsInitScope.total) {
          chartsInitScope.current++
        }
        if (chartsInitScope.current === chartsInitScope.total) {
          $timeout($mdDialog.hide, 10)
          chartsInitScope = undefined
          chartSystemsInSync = true
        }
      }
    }

    $scope.chartBeforeDraw = function (chartWrapper) {
      if (chartSystemsInSync) {
        return
      }

      var data = chartWrapper.getDataTable()
      var view = chartWrapper.getView()
      var options = chartWrapper.getOptions()

      var columns = [0]
      Object.entries(options.$systemColumnIndexes).forEach(
        function (keyValue) {
          var systemId = parseInt(keyValue[0])
          var indexes = keyValue[1]
          var showSystem = $scope.sales.system.includes(systemId)
          indexes.forEach(
            function (index) {
              if (showSystem) {
                columns.push(index)
              } else {
                columns.push({
                  label: data.getColumnLabel(index),
                  type: data.getColumnType(index),
                  sourceColumn: index,
                  calc: function () {
                    return null
                  },
                })
              }
              options.series[index - 1].visibleInLegend = options.series[index - 1].labelInLegend != null && showSystem
            }
          )
        }
      )
      view.setColumns(columns)
    }

    var fullscreenRank
    function escapeListener (event) {
      if (event.key === 'Escape') {
        $scope.toggleChartFullscreen(fullscreenRank, false)
      }
    }

    $scope.toggleChartFullscreen = function (rank, turnOn) {
      var chartWrapper = chartsStore[rank.store.storeId]
      if (!chartWrapper) {
        return
      }
      var container = chartWrapper.getContainer().closest('.ranking-chart')
      var active = container.classList.contains('fullscreen')
      if (active !== turnOn) {
        container.classList.toggle('fullscreen')
        chartWrapper.draw()
      }
      if (turnOn) {
        fullscreenRank = rank
        document.body.addEventListener('keydown', escapeListener)
      } else {
        fullscreenRank = undefined
        document.body.removeEventListener('keydown', escapeListener)
      }
    }

    var chartSystemsInSync = false
    function redrawCharts () {
      Object.values(chartsStore).forEach(
        function (chartWrapper) {
          $scope.chartBeforeDraw(chartWrapper)
          chartWrapper.draw()
        }
      )
      if ($scope.sales.charts.init) {
        chartSystemsInSync = true
      }
    }

    $scope.stateTransition($state.current)
  }
})()
