;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.services', ['ngCookies'])
    .factory('httpInterceptor', function ($injector, $q, spinnerService) {
      return {
        request: function (config) {
          if (!config.hideGlobalSpinner && (!config.params || !config.params.hideGlobalSpinner)) {
            spinnerService.show('spinnerGlobal')
          }
          if (config.params) {
            if (config.params.FILE_UPLOAD) {
              config.headers['Content-Type'] = undefined
              config.transformRequest = objectToFormData
            }
            if (config.params.RAW_URL) {
              config.url = config.url.replaceAll('%2F', '/')
            }
            delete config.params.FILE_UPLOAD
            delete config.params.RAW_URL

            config.hideGlobalSpinner = config.params.hideGlobalSpinner
            delete config.params.hideGlobalSpinner

            config.ignoreError = config.params.ignoreError
            delete config.params.ignoreError
          }
          return config
        },
        response: function (response) {
          if (!response.config.hideGlobalSpinner) {
            spinnerService.hide('spinnerGlobal')
          }
          httpResponseNotification(response)
          if (Object.prototype.hasOwnProperty.call(response.data, 'payload')) {
            response.data = response.data.payload
          }
          return response
        },
        responseError: function (response) {
          if (response.config.timeout != null) {
            switch (response.config.timeout.$$state.value) {
              case 'silent':
                response.status = 942
                response.statusText = 'Silent Request Cancel'
                break
              case 'restart':
                response.status = 943
                response.statusText = 'Restart Request Later'
                break
              default:
            }
          }
          if (!response.config.hideGlobalSpinner) {
            spinnerService.hide('spinnerGlobal')
          }
          httpResponseNotification(response)
          return $q.reject(response)
        }
      }
      function httpResponseNotification (response) {
        return $injector.get('httpResponseNotification').handle(angular.copy(response))
      }
      function objectToFormData (object) {
        if (!(angular.isObject(object) && FormData)) {
          return object
        }
        var form = new FormData()
        for (var key in object) {
          if (Object.prototype.hasOwnProperty.call(object, key)) {
            form.append(key, object[key])
          }
        }
        return form
      }
    })
    .factory('httpResponseNotification', function (CONFIG, $rootScope, $state, $mdDialog, Notification, UserService) {
      var selforganizedRequestUrls = [
        CONFIG.BASE_URL + '/user/login',
        CONFIG.API_URL + '/orders/attention/',
        CONFIG.API_URL + '/orders/otsstatus/',
        CONFIG.API_URL + '/orders/servicecenterstatus/',
      ]
      return {
        handle: function (response) {
          var ignoreError = [].concat(response.config.ignoreError)
          if (response.status === 200 || ignoreError.includes(response.status)) {
            return
          }
          for (var i = selforganizedRequestUrls.length - 1; i >= 0; i--) {
            var url = selforganizedRequestUrls[i]
            if (response.config.url.includes(url)) {
              return
            }
          }
          var message
          if (response.data && response.data.messages) {
            function reduce (container) {
              if (angular.isObject(container) && !angular.isArray(container)) {
                container = Object.values(container)
              }
              var message = ''
              if (angular.isArray(container)) {
                container.forEach(
                  function (value) {
                    if (angular.isString(value)) {
                      message += '<p>' + value + '</p>'
                    } else {
                      message += reduce(value)
                    }
                  }
                )
              }
              return message
            }
            message = reduce(response.data.messages) || undefined
          }
          switch (response.status) {
            case -1: // no connection
              Notification.warning({ title: 'Achtung', message: 'Keine Verbindung!' })
              break

            case 201: // created
            case 202: // updated, deleted
              Notification.success({ title: 'Erfolg', message: message })
              break

            case 400: // invalid
              Notification.error({ title: 'Fehler', message: message })
              break

            case 403: // forbidden
              Notification.warning({ title: 'Achtung', message: message })
            /* falls through */
            case 401: // unauthorized
              if (response.status === 401) {
                UserService.setRedirectAfterLogin($state.current)
              }
              UserService.logout()
              break

            case 404: // notfound
              Notification.warning({ title: 'Achtung', message: message })
              break

            case 942: break // silent request cancel (DO NOTHING)
            case 943: break // restart request later (STAY PATIENT)

            default:
              var method = 'info'
              var title = 'Info'
              if (response.status >= 200 && response.status <= 299) {
                method = 'success'
                title = 'Erfolg'
              } else if (response.status >= 400 && response.status <= 499) {
                method = 'warning'
                title = 'Achtung'
              } else if (response.status >= 500 && response.status <= 599) {
                method = 'error'
                title = 'Fehler'
              }

              var options = {
                title: title + ' ' + response.status + ' (' + response.statusText + ')',
                message: message,
              }
              if (response.data.payload && (response.data.payload.length || Object.keys(response.data.payload).length)) {
                options.scope = $rootScope.$new()
                options.scope.details = function (event) {
                  event.stopPropagation()
                  var notification = this
                  $mdDialog.show({
                    templateUrl: 'src/templates/dialog-notification-details.html',
                    autoWrap: false,
                    targetEvent: event,
                    controller: function ($scope, $mdDialog) {
                      $scope.class = 'md-primary'
                      if (['s'].includes(notification.t)) {
                        $scope.class = 'md-accent'
                      } else if (['w', 'e'].includes(notification.t)) {
                        $scope.class = 'md-warn'
                      }
                      $scope.title = notification.title
                      $scope.message = notification.message
                      $scope.details = response.data.payload
                      $scope.close = function () {
                        $mdDialog.hide()
                      }
                    },
                    fullscreen: true,
                  })
                }
                options.templateUrl = 'src/templates/notification-details.html'
              }

              Notification[method](options)
          }
        },
      }
    })
    .factory('helperService', function (ENV, $http, $mdDialog, Notification) {
      var self = this

      return {
        appendTransform: appendTransform,
        data2download: data2download,
        data2print: data2print,
        dateDiffDays: dateDiffDays,
        fetchFromObject: fetchFromObject,
        getSelectMonthOptions: getSelectMonthOptions,
        isEmailAddress: isEmailAddress,
        orderTransformation: orderTransformation,
        selectUserSystem: selectUserSystem,
        setRefUser: setRefUser,
      }

      function appendTransform (transform) {
        var defaultHttpResponseTransform = $http.defaults.transformResponse
        if (!angular.isArray(defaultHttpResponseTransform)) {
          defaultHttpResponseTransform = [defaultHttpResponseTransform]
        }
        return defaultHttpResponseTransform.concat(transform)
      }

      function data2download (data, headers, status) {
        var disposition = headers('Content-Disposition') || ''
        var type = headers('Content-Type') || 'application/octet-stream'
        var html = Boolean(type.match(/html/i))

        var decodedData = {}
        if (TextDecoder && $http.defaults.transformResponse.length) {
          var decoder = new TextDecoder()
          decodedData = $http.defaults.transformResponse[0](decoder.decode(data), headers, status)
        }

        if (status !== 200) {
          return decodedData
        }

        if (ENV.DEVELOPMENT && html) {
          return $mdDialog.show(
            $mdDialog.alert()
              .title('Debug HTML')
              .htmlContent(decodedData)
              .ok('OK')
          )
        }

        var filename = 'download'
        var match = disposition.match(/filename\s*=\s*("?)(.*)\1/i)
        if (match) {
          filename = match[2]
        }

        var blob = new Blob([new Uint8Array(data)], { type: type })
        saveAs(blob, filename)

        return blob
      }

      function data2print (data, headers, status) {
        var type = headers('Content-Type') || ''

        if (type.includes('text/html')) {
          var win = window.open('')
          if (!win || win.closed || typeof win.closed === 'undefined') {
            Notification.error({
              title: 'Drucken blockiert!',
              message: 'Der Browser auf diesem Endgerät hat verhindert, dass ein Pop-up geöffnet werden konnte.<br>Das Drucken wurde dadurch blockiert!',
            })
          } else {
            win.document.write(data.documentElement.outerHTML)
            win.document.close()
          }
        }

        return data
      }

      function dateDiffDays (dateFrom, dateTo) {
        var diff = new Date(dateTo).setHours(12) - new Date(dateFrom).setHours(12)
        return Math.round(Math.abs(diff) / 8.64e7)
      }

      function fetchFromObject (obj, prop) {
        if (typeof obj === 'undefined') {
          return false
        }
        var _index = prop.indexOf('.')
        if (_index > -1) {
          return fetchFromObject(obj[prop.substring(0, _index)], prop.substr(_index + 1))
        }
        return obj[prop]
      }

      function getSelectMonthOptions (filter) {
        var FORMAT = 'YYYY-MM'

        filter = Object.assign(
          {
            from: moment('2015-10-01').format(FORMAT),
            to: moment().format(FORMAT),
          },
          filter
        )

        var start = moment(filter.from)
        var end = moment(filter.to)
        if (end.isBefore(start)) {
          throw new Error('SelectMonthOptions: filter.from (' + start.format(FORMAT) + ') can\'t be greater than filter.to (' + end.format(FORMAT) + ').')
        }
        end.add(1, 'month')

        var months = []
        while (start.isBefore(end)) {
          months.push({
            name: start.format('MMMM YYYY'),
            iso: start.format(FORMAT),
            first: start.date(1).toDate(),
            last: start.add(1, 'month').date(0).toDate(),
          })
          start.date(1).add(1, 'month')
        }

        months.reverse()
        return angular.copy(months)
      }

      function isEmailAddress(value) {
        var input = document.createElement('input')
        input.type = 'email'
        input.required = true
        input.value = value
        return typeof input.checkValidity === 'function' ? input.checkValidity() : /\S+@\S+\.\S+/.test(value)
      }

      function orderTransformation (property) {
        if (angular.isString(property)) {
          // property defined
          return transformation
        } else {
          // no property defined, pass `property` through as response
          return transformation(property)
        }
        function transformation (response) {
          if (!response) {
            return response
          }
          var array = []
          if (response.payload && angular.isArray(response.payload.records)) {
            array = response.payload.records
          } else if (angular.isArray(response)) {
            array = response
          }
          array.forEach(function (o) {
            var order = angular.isString(property) ? o[property] : o
            // PATCH REDIRECTED ORDERS
            if (order.raw.redirect_to_store != null && order.raw.redirect_to_store !== order.raw.store_id) {
              order.origStore = order.store
              order.store = order.redirectedToStore
            }
            // TRANSFORM ITEMS
            order.items.forEach(function (item) {
              if (item.product_options && item.product_options.length) {
                var groups = {}
                item.product_options.forEach(function (option, i) {
                  if (option.type === 'variation') {
                    item.variation_data = {
                      value: option.values[0].value,
                      price: option.values[0].price,
                      sku: option.values[0].sku,
                    }
                  }
                  if (!Object.prototype.hasOwnProperty.call(groups, option.group)) {
                    groups[option.group] = i
                    option.print_groupname = !!option.group
                  }
                  if (option.group) {
                    option.indent = true
                  }
                })
                item.product_options.sort(function (a, b) {
                  return groups[a.group] - groups[b.group]
                })
              }
            })
          })
          return response
        }
      }

      function selectUserSystem (user) {
        var oldIds = self.refUser.systems || []
        var newIds = user.systems || []
        var add = newIds.find(function (systemId) {
          return !oldIds.includes(systemId)
        })
        if (add != null) {
          var intern
          var system = self.refSystems.find(function (system) {
            return system.systemId === add
          })
          if (add === 0) { // Kategorie "alle"
            user.systems = [0]
          } else if (add < 0) { // Sub-Kategorie "alle internen/externen"
            intern = add === -1
            user.systems = user.systems.filter(function (systemId) {
              var system = self.refSystems.find(function (system) {
                return system.systemId === systemId
              })
              return !(
                systemId === 0 ||
                (intern && systemId > 0 && system.origin.startsWith('ottomatik_')) ||
                (!intern && systemId > 0 && !system.origin.startsWith('ottomatik_'))
              )
            })
            var bothAll = user.systems.length === 2
            user.systems.forEach(function (systemId) {
              bothAll &= systemId < 0
            })
            if (bothAll) {
              user.systems = [0]
            }
          } else { // Einzel "intern/extern"
            intern = system.origin.startsWith('ottomatik_')
            user.systems = user.systems.filter(function (systemId) {
              return !(
                systemId === 0 ||
                (intern && systemId === -1) ||
                (!intern && systemId === -2)
              )
            })
          }
        }
        self.refUser = angular.copy(user)
      }

      function setRefUser (user, systems) {
        self.refUser = angular.copy(user)
        self.refSystems = systems || []
      }
    })
    .provider('moduleRegister', function ($stateProvider) {
      var $filter
      var menuItems = []

      // provider functions
      this.add = add
      // service functions
      this.$get = function ($injector) {
        $filter = $injector.get('$filter')
        var UserService = $injector.get('UserService')
        return {
          getMenuItems: function (userRoles) {
            if (userRoles) {
              return processAccessRoles(menuItems, userRoles)
            }
            return menuItems
          },
          getViews: function () {
            return getStateRoles($stateProvider.stateService.href)
          },
          userCanAccessState: function (state) {
            var stateRoles = getStateRoles()[state]
            if (stateRoles == null) {
              return true
            }
            var userRoles = UserService.getRoles()
            return $filter('intersection')(stateRoles, userRoles).length > 0
          },
        }
      }

      function add (config) {
        // validation
        if (!angular.isObject(config)) {
          throw TypeError('[moduleRegisterProvider] Expected config to be a object, got ' + typeof config)
        }
        if ((config.name || config.title) == null) {
          throw Error('[moduleRegisterProvider] Expected config to contain a name or a title')
        }
        if (config.menu && !angular.isArray(config.menu)) {
          throw TypeError('[moduleRegisterProvider] Expected config.menu to be a array, got ' + typeof config.menu)
        }

        var itemUrl
        if (config.defaultUrl) {
          itemUrl = config.defaultUrl
        } else if (config.url) {
          itemUrl = config.url
        } else if (angular.isArray(config.menu) && config.menu.length > 0) {
          itemUrl = config.menu[0].url
        } else {
          throw Error('[moduleRegisterProvider] Expected config to contain a url or a menu')
        }

        menuItems.push({
          name: config.name || config.title,
          url: itemUrl,
          icon: config.icon || config.materialIcon,
          materialIcon: !config.icon && !!config.materialIcon,
          accessRoles: config.accessRoles,
          arn: config.arn,
          sub: config.menu || [],
        })
      }

      function getStateRoles (func) {
        func = angular.isFunction(func) ? func : function (i) { return i }
        var views = {}
        menuItems.forEach(function (item) {
          views[func(item.url)] = item.accessRoles
          item.sub.forEach(function (subItem) {
            views[func(subItem.url)] = subItem.accessRoles || item.accessRoles
          })
        })
        return angular.copy(views)
      }

      function processAccessRoles (items, userRoles) {
        var processedItems = []
        for (var i = 0, iLen = items.length; i < iLen; i++) {
          var item = angular.copy(items[i])
          var accessible = true
          if (angular.isArray(item.accessRoles)) {
            accessible =
              item.accessRoles.filter(function (r) { return !r.startsWith('^') }).filter(function (accessRole) { return userRoles.includes(accessRole) }).length > 0 &&
              item.accessRoles.filter(function (r) { return r.startsWith('^') }).filter(function (accessRole) { return userRoles.includes(accessRole.substring(1)) }).length === 0
          }
          if (accessible) {
            // ARN (access roles names)
            for (var name in item.arn) {
              if ($filter('intersection')(item.arn[name], userRoles).length > 0) {
                item.name = name
              }
            }
            // SUB
            if (angular.isArray(item.sub) && item.sub.length > 0) {
              item.sub = processAccessRoles(item.sub, userRoles)
              if (item.sub.filter(function (subItem) { return item.url === subItem.url }).length === 0) {
                item.url = item.sub[0].url
                if (item.sub.length === 1) {
                  item.name = item.sub[0].name
                  item.sub = []
                }
              }
              item.sub = item.sub.filter(function (sub) { return !sub.hidden })
            }
            processedItems.push(item)
          }
        }
        return processedItems
      }
    })
    .factory('AudioService', function ($rootScope, $compile, $templateRequest, UserAgentService) {
      var id = 'unmute'

      return init

      function init (file) {
        var audio = new Audio(file)
        return {
          play: playAudio,
        }
        function playAudio () {
          var promise = audio.play()
          if (promise !== undefined) {
            promise.then(hideUnmute).catch(showUnmute)
          }
          return promise
        }
      }

      function hideUnmute () {
        var element = document.getElementById(id)
        if (!element) {
          return
        }
        element.parentNode.removeChild(element)
      }

      function showUnmute () {
        if (document.getElementById(id) || UserAgentService.isOTTOPI()) {
          return
        }
        $templateRequest('src/templates/notification-unmute.html').then(
          function (template) {
            var div = document.createElement('div')
            div.id = id
            div.innerHTML = template
            document.body.appendChild(div)
            var $element = angular.element(div)
            var $scope = $rootScope.$new()
            $scope.unmute = hideUnmute
            $compile($element)($scope)
          }
        )
      }
    })
    .factory('ClipboardService', function () {
      return {
        copy: function (text) {
          var result
          try {
            var input = document.createElement('input')
            input.style.position = 'fixed'
            input.style.opacity = '0'
            input.value = text
            document.body.appendChild(input)
            input.select()
            result = document.execCommand('copy')
            document.body.removeChild(input)
          } catch (err) {
            result = false
          }
          return result
        }
      }
    })
    .factory('IframeService', function ($mdDialog) {
      return {
        open: function (options) {
          $mdDialog.show({
            templateUrl: 'src/templates/dialog-iframe.html',
            autoWrap: false,
            targetEvent: options.event,
            clickOutsideToClose: true,
            fullscreen: true,
            controller: function IframeController ($mdDialog, $scope, $sce, $window) {
              $scope.close = function () { $mdDialog.hide() }
              $scope.title = $sce.trustAsHtml(options.title)
              $scope.url = options.url

              $scope.resize = function () {
                var iframe = document.querySelector('.dialog-iframe iframe')
                var clientW = iframe.contentDocument.body.clientWidth
                var offsetW = iframe.contentDocument.body.offsetWidth
                var scrollW = iframe.contentDocument.body.scrollWidth
                var scrollH = iframe.contentDocument.body.scrollHeight
                var dataW = parseInt(iframe.getAttribute('data-scroll-width'))
                var dataH = parseInt(iframe.getAttribute('data-scroll-height'))
                if (dataW && dataH) {
                  scrollW = dataW
                  scrollH = dataH
                } else if (clientW < scrollW) {
                  iframe.setAttribute('data-scroll-width', scrollW)
                  iframe.setAttribute('data-scroll-height', scrollH)
                }
                var scale = Math.min(1, clientW / scrollW)
                iframe.contentDocument.body.style.height = Math.ceil(scrollH * scale) + 'px'
                iframe.contentDocument.body.style.transform = 'scale(' + scale + ')'
                iframe.contentDocument.body.style.transformOrigin = 'top left'
              }

              $scope.onload = function () {
                var iframe = document.querySelector('.dialog-iframe iframe')
                if (iframe.contentDocument.body.innerHTML.length) {
                  document.getElementById('dialog-iframe-spinner').style.display = 'none'
                  $scope.resize()
                }
              }

              angular.element($window).on('resize', $scope.resize)

              $scope.$on('$destroy', function () {
                angular.element($window).off('resize')
              })
            },
          })
        },
      }
    })
    .factory('LocalStorageService', function ($cookies, $window) {
      if (typeof Storage === 'undefined') {
        // browser doesn't support Web Storage (fallback via cookies)
        var prefix = 'localStorage.'
        return {
          get: function (name, defaultReturn) {
            var value = $cookies.get(prefix + name)
            if (undefined === value) {
              return defaultReturn
            }
            return $cookies.getObject(prefix + name)
          },
          set: function (name, value) {
            $cookies.putObject(prefix + name, value)
          },
          remove: function (name) {
            $cookies.remove(prefix + name)
          },
        }
      } else {
        // use localStorage API
        var storage = $window.localStorage
        return {
          get: function (name, defaultReturn) {
            var value = storage.getItem(name)
            if (value === null) {
              return defaultReturn
            }
            return angular.fromJson(value)
          },
          set: function (name, value) {
            storage.setItem(name, angular.toJson(value))
          },
          remove: function (name) {
            storage.removeItem(name)
          },
        }
      }
    })
    .factory('PaginationService', function () {
      var pagination = {
        count: undefined,
      }
      return {
        getCount: function () {
          return pagination.count
        },
        loadFromHeaders: function (value, responseHeaders, status, statusText) {
          pagination.count = undefined
          var count = responseHeaders('pagination-count')
          if (count) {
            pagination.count = parseInt(count)
          }
        },
      }
    })
    .factory('PasswordService', function () {
      return {
        generateRandomPassword: function (length, options) {
          length = length || 16
          options = Object.assign({
            incLower: true,
            incUpper: true,
            incDigits: true,
            incSymbols: true,
            startWithLetter: true,
            noSimilar: true,
            noDuplicate: true,
            noSequential: true,
          }, options)

          var incLower = options.incLower
          var incUpper = options.incUpper
          var incDigits = options.incDigits
          var incSymbols = options.incSymbols
          var startWithLetter = options.startWithLetter
          var noSimilar = options.noSimilar
          var noDuplicate = options.noDuplicate
          var noSequential = options.noSequential

          var lower = 'abcdefghijklmnopqrstuvwxyz'
          var upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
          var digits = '0123456789'
          var symbols = options.symbols || '!";#$%&\'()*+,-./:;<=>?@[]^_`{|}~'

          if (noSimilar) {
            lower = lower.replace(/i|l|o/g, '')
            upper = upper.replace(/I|O/g, '')
            digits = digits.replace(/0|1/g, '')
          }

          var dictionary = ''
          var sets = 0
          if (incLower) {
            dictionary += lower
            sets++
          }
          if (incUpper) {
            dictionary += upper
            sets++
          }
          if (incDigits) {
            dictionary += digits
            sets++
          }
          if (incSymbols) {
            dictionary += symbols
            sets++
          }

          var dictLength = dictionary.length
          var bufferLength = length - sets
          var buffer = ''

          if (length < 6) {
            throw new Error('Ein Passwort sollte mindestens 6 Zeichen lang sein!')
          }
          if (sets === 0) {
            throw new Error('Es muss mindestens eine Zeichengruppe ausgewählt werden!')
          }
          if (noDuplicate && dictLength < length) {
            throw new Error('Für die geforderte Länge wurden nicht genügend Zeichengruppen ausgewählt!')
          }
          if (startWithLetter && !incLower && !incUpper) {
            throw new Error('Es wurden keine Klein- oder Großbuchstaben ausgewählt!')
          }

          var i, pos, chr

          if (!noDuplicate) {
            for (i = 0; i < bufferLength; i++) {
              pos = Math.floor(Math.random() * dictLength)
              buffer += dictionary.substr(pos, 1)
            }
          } else {
            var remainDict = dictionary
            var remainLength
            var stop = false
            for (i = 0; i < bufferLength && stop === false; i++) {
              while (true) {
                remainLength = remainDict.length
                if (remainLength === 0) {
                  stop = true
                  break
                }
                pos = Math.floor(Math.random() * remainLength)
                chr = remainDict.substr(pos, 1)
                if (buffer.indexOf(chr) === -1) {
                  buffer += chr
                  break
                } else {
                  remainDict = remainDict.replace(chr, '')
                }
              }
            }
          }

          function insertChar (set, bufferLength, buffer) {
            var pos, insert
            if (!noDuplicate) {
              pos = Math.floor(Math.random() * set.length)
              insert = Math.floor(Math.random() * bufferLength)
              buffer = buffer.substring(0, insert) + set.substr(insert, 1) + buffer.substring(insert, bufferLength)
            } else {
              var remainSet = set
              var remainLength, chr
              while (true) {
                remainLength = remainSet.length
                if (remainLength === 0) {
                  break
                }
                pos = Math.floor(Math.random() * remainLength)
                chr = remainSet.substr(pos, 1)
                if (buffer.indexOf(chr) === -1) {
                  insert = Math.floor(Math.random() * bufferLength)
                  buffer = buffer.substring(0, insert) + chr + buffer.substring(insert, bufferLength)
                  break
                } else {
                  remainSet = remainSet.replace(chr, '')
                }
              }
            }
            return buffer
          }

          if (incLower) {
            buffer = insertChar(lower, bufferLength, buffer)
            bufferLength++
          }
          if (incUpper) {
            buffer = insertChar(upper, bufferLength, buffer)
            bufferLength++
          }
          if (incDigits) {
            buffer = insertChar(digits, bufferLength, buffer)
            bufferLength++
          }
          if (incSymbols) {
            buffer = insertChar(symbols, bufferLength, buffer)
            bufferLength++
          }

          do {
            var shuffle = false

            if (!shuffle && noSequential) {
              for (i = 0; i < bufferLength - 1; i++) {
                var codeA = buffer.charCodeAt(i)
                var codeB = buffer.charCodeAt(i + 1)
                if (codeB - codeA === 1 && ((codeA >= 48 && codeA < 57) || (codeA >= 65 && codeA < 90) || (codeA >= 97 && codeA < 122))) {
                  shuffle = true
                  break
                }
              }
            }

            if (!shuffle && startWithLetter) {
              var code0 = buffer.charCodeAt(0)
              if (!(code0 >= 65 && code0 <= 90) && !(code0 >= 97 && code0 <= 122)) {
                shuffle = true
              }
            }

            if (shuffle) {
              var arr = buffer.split('')
              arr.sort(function () {
                return 0.5 - Math.random()
              })
              buffer = arr.join('')
            }
          } while (shuffle)

          return buffer
        }
      }
    })
    .factory('SessionStorageService', function ($window) {
      if (typeof Storage === 'undefined') {
        return {
          get: function (_, defaultReturn) { return defaultReturn },
          set: function () {},
          remove: function () {},
        }
      } else {
        // use sessionStorage API
        var storage = $window.sessionStorage
        return {
          get: function (name, defaultReturn) {
            var value = storage.getItem(name)
            if (value === null) {
              return defaultReturn
            }
            return angular.fromJson(value)
          },
          set: function (name, value) {
            storage.setItem(name, angular.toJson(value))
          },
          remove: function (name) {
            storage.removeItem(name)
          },
        }
      }
    })
    .factory('SlackService', function (CONFIG, $resource) {
      var service = {
        getUsers: getUsers,
        getUser: getUser,

        getUsergroups: getUsergroups,
        getUsergroup: getUsergroup,
      }

      var Slack = $resource(CONFIG.API_URL + '/slack/:action/:id')

      function getUsers() {
        return Slack.query({ action: 'usersList' }).$promise
      }

      function getUser(handle) {
        return Slack.get({ action: 'user', id: handle }).$promise
      }

      function getUsergroups() {
        return Slack.query({ action: 'usergroupsList' }).$promise
      }

      function getUsergroup(handle) {
        return Slack.get({ action: 'usergroup', id: handle }).$promise
      }

      return service
    })
    .factory('StoreService', function (CONFIG, $resource) {
      var service = {
        getOpeningHours: getOpeningHours,
        updateOpeningHours: updateOpeningHours,
      }

      var OPH = $resource(CONFIG.API_URL + '/customers/:customerId/stores/:storeId/oph', { customerId: 0, storeId: 0 })

      function getOpeningHours (params) {
        return OPH.get(params).$promise
      }

      function updateOpeningHours (params, data) {
        return OPH.update(params, data).$promise
      }

      return service
    })
    .factory('UNPKG', function ($q) {
      var loaded = {}
      var dependency = {
        jspdf: function () {
          return loadScriptTag('fonts/jspdf/seguisym-normal.js')
        },
      }
      return {
        load: function (packageName, version, file) {
          var path = packageName + '@' + version + '/' + file
          var deferred = $q.defer()
          if (loaded[path]) {
            deferred.resolve()
          } else {
            var source = 'https://unpkg.com/' + path
            loadScriptTag(source)
              .then(function () {
                loaded[path] = true
                if (dependency[packageName]) {
                  dependency[packageName]().then(function () {
                    deferred.resolve()
                  })
                } else {
                  deferred.resolve()
                }
              })
              .catch(function () {
                deferred.reject()
              })
          }
          return deferred.promise
        },
      }

      function loadScriptTag(source) {
        var deferred = $q.defer()
        var scriptTag = document.createElement('script')
        scriptTag.onload = function () {
          deferred.resolve()
        }
        scriptTag.onerror = function () {
          deferred.reject()
        }
        scriptTag.src = source
        document.body.appendChild(scriptTag)
        return deferred.promise
      }
    })
    .factory('UserAgentService', function () {
      var n = navigator
      var ua = n.userAgent
      return {
        get: function () {
          return ua
        },
        isIE: function () {
          return Boolean(
            ua.match(/\bMSIE\b/) || // MSIE (Ver <= 10)
            ua.match(/\bTrident\b/) // Trident (Ver 11)
          )
        },
        isOTTOPI: function () {
          return ua.indexOf('OTTOPI') === 0
        },
        getDeviceColorScheme: function (replace) {
          replace = angular.extend({
            darkAs: 'dark',
            lightAs: 'light',
          }, replace)
          return (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? replace.darkAs : replace.lightAs
        },
        supportsDeviceColorScheme: function () {
          return Boolean(window.matchMedia && window.matchMedia('(prefers-color-scheme)').media !== 'not all')
        },
        watchDeviceColorScheme: function (replace, callback) {
          if (!this.supportsDeviceColorScheme() || !angular.isFunction(callback)) {
            return
          }
          replace = angular.extend({
            darkAs: 'dark',
            lightAs: 'light',
          }, replace)
          window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').addEventListener(
            'change',
            function (event) {
              callback(event.matches ? replace.darkAs : replace.lightAs)
            }
          )
        },
      }
    })
    .factory('UUID', function () {
      return {
        // https://stackoverflow.com/a/2117523/3087041
        v4: function () {
          return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (c) {
            return (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
          })
        },
      }
    })
    .factory('YubicoService', function () {
      var REGEX_OTP = /^(([cbdefghijklnrtuv]{12})([cbdefghijklnrtuv]{32}))$/
      var REGEX_OTP_DVORAK = /^(([jxe.uidchtnbpygk]{12})([jxe.uidchtnbpygk]{32}))$/
      return {
        parseOTP: function (otp) {
          // cope with user errors
          otp = otp.toLowerCase()
          otp = otp.replace(/\s/g, '')
          // check
          var match = otp.match(REGEX_OTP)
          if (match === null) {
            match = otp.match(REGEX_OTP_DVORAK)
            if (match === null) {
              return false
            }
          }
          return {
            otp: match[1],
            id: match[2],
            passcode: match[3],
          }
        },
      }
    })
})()
