;(function () {
  'use strict'

  angular.module('omPushNotification', []).component('omPushNotification', {
    templateUrl: 'src/components/om-push-notification.html',
    bindings: {
      data: '<',
      full: '<?',
    },
    controller: function () {
      var $ctrl = this

      $ctrl.mapping = {
        customer: {
          1: 'Tele Pizza',
          2: 'Call a Pizza',
        },
        system: {
          '-1': 'alle',
          2: 'Android',
          3: 'iOS',
          4: 'Browser',
        },
      }

      $ctrl.getSystemsFromOs = (os) =>
        String(os)
          .split(',')
          .map((id) => id.trim())
          .map((id) => $ctrl.mapping.system[id])
          .filter((sys) => Boolean(sys))
          .join(', ')
    },
  })
})()
