(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager')
    .component('omOrderpaymenticons', {
      templateUrl: 'src/components/om-orderpaymenticons.html',
      bindings: {
        payment: '<'
      }
    })
})()
