;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.storesettings').controller('DeliveryTimesController', DeliveryTimesController)

  function DeliveryTimesController($scope, DeliveryTimesService, UserService) {
    var $ctrl = this

    $scope.$on('STORE_SETTINGS_LOAD', (event, data) => {
      $ctrl.customer = data.customer
      $ctrl.store = data.store

      $ctrl.times.load()
      $ctrl.delay.load()
    })

    $ctrl.domain = {}
    $ctrl.portal = {}
    $ctrl.times = {
      min: 0,
      max: 120,
      step: 5,
      load: function () {
        var params = {
          customerId: $ctrl.customer.customerId,
          storeId: $ctrl.store.storeId,
        }

        $ctrl.domain.loading = true
        $ctrl.portal.loading = true
        DeliveryTimesService.getTimes(params)
          .then(this.onResponse.bind(this))
          .finally(() => {
            $ctrl.domain.loading = false
            $ctrl.portal.loading = false
          })
      },
      onResponse: function (response) {
        this.updateValues(response.ottomatik, $ctrl.domain)
        this.updateValues(response.portal, $ctrl.portal)
      },
      updateValues: function (from, to) {
        if (!from || from.result !== 'success') {
          to.show = false
          return
        }
        to.delivery = from.time
        to.pickup = from.pickup
        to.backup = {
          time: from.time,
          pickup: from.pickup,
        }
        to.dirty = false
        to.show = true
      },
      apply: function (key) {
        var params = {
          customerId: $ctrl.customer.customerId,
          storeId: $ctrl.store.storeId,
        }

        var data = {
          type: key == 'domain' ? 'ottomatik' : key,
          time: $ctrl[key].delivery,
          pickup: $ctrl[key].pickup,
        }

        $ctrl[key].loading = true
        DeliveryTimesService.setTimes(params, data)
          .then(() => {
            this.updateValues(Object.assign({ result: 'success' }, data), $ctrl[key])
          })
          .catch(() => {
            this.updateValues(Object.assign({ result: 'success' }, $ctrl[key].backup), $ctrl[key])
          })
          .finally(() => {
            $ctrl[key].loading = false
          })
      },
      checkDirty: function (key) {
        $ctrl[key].dirty = !angular.equals(
          {
            time: $ctrl[key].delivery,
            pickup: $ctrl[key].pickup,
          },
          $ctrl[key].backup
        )
      },
    }

    $ctrl.delay = {
      durationOptions: [1, 2, 3, 4, 5],
      timeOptions: [],
      getApiAccess: function () {
        return !this.loading && $ctrl.store.api === 'Magento'
      },
      load: function () {
        if (!this.getApiAccess()) {
          this.show = false
          return
        }

        if ($ctrl.customer.options.delayMessageTimeOptions) {
          this.timeOptions = $ctrl.customer.options.delayMessageTimeOptions.split(',')
        } else {
          this.timeOptions = []
        }

        var params = {
          customerId: $ctrl.customer.customerId,
          storeId: $ctrl.store.storeId,
        }

        this.show = true
        this.loading = true
        DeliveryTimesService.getDelay(params)
          .then(this.onResponse.bind(this))
          .finally(() => {
            this.loading = false
          })
      },
      apply: function (duration, time) {
        if (!this.getApiAccess()) {
          return
        }

        var params = {
          customerId: $ctrl.customer.customerId,
          storeId: $ctrl.store.storeId,
        }

        var data = {
          delayMessageEnabled: duration,
          expectedDeliveryTime: time,
        }

        this.loading = true
        DeliveryTimesService.setDelay(params, data)
          .then(this.onResponse.bind(this))
          .finally(() => {
            this.loading = false
          })
      },
      onResponse: function (response) {
        delete this.duration
        delete this.time
        this.activeUntil = response.activeUntil
        this.expectedDeliveryTime = response.expectedDeliveryTime
      },
    }

    $ctrl.info = {
      delay:
        'Aktivieren Sie einen Hinweis auf der Domain,\n' +
        'der den Besucher über die aktuelle Lieferzeit informiert.\n' +
        'Die auswählbaren Lieferzeitpunkte werden damit eingeschränkt.',
      domain:
        'Stellen Sie hier die Abhol- und Lieferzeit ein,\n' +
        'die für Bestellungen über die Domain gelten sollen.\n\n⚠\n' +
        'Dies ist nur für die Bestellannahme wichtig.\n' +
        'Es findet keine Rückmeldung an den Kunden statt.',
      portal:
        'Stellen Sie hier die Abhol- und Lieferzeit ein,\n' +
        'die den Kunden auf Lieferando zurückgemeldet werden sollen.',
    }
  }
})()
