;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.hrm')
    .controller('HRMController', HRMController)
    .controller('HRMDriversController', HRMDriversController)
    .controller('HRMStaffController', HRMStaffController)
    .controller('HRMRosterController', HRMRosterController)
    .controller('HRMShiftsController', HRMShiftsController)
    .value('SHIFTS', {
      day: {
        color: '#ffcd00',
        name: 'Tagschicht (bis 17 Uhr)',
      },
      evening: {
        color: '#ff6900',
        name: 'Abendschicht (ab 17 Uhr)',
      },
    })
    .value('WEEKS_PER_MONTH', 4.35)

  function HRMController(
    SHIFTS,
    WEEKS_PER_MONTH,
    $filter,
    $mdDateLocale,
    $mdDialog,
    $q,
    $scope,
    $timeout,
    CustomerService,
    HRMService,
    Notification,
    UserService
  ) {
    var ctrl = this

    var watchers = []
    $scope.$on('$destroy', () => watchers.forEach((watcher) => watcher()))

    $scope.showCustomerSelect = UserService.hasRole(['admin', 'storesall'])

    $scope.SHIFTS = SHIFTS
    $scope.WEEKDAYS = angular.copy($mdDateLocale.days).rotate($mdDateLocale.firstDayOfWeek)
    $scope.WEEKS_PER_MONTH = WEEKS_PER_MONTH

    // #region customer & store selects

    if ($scope.showCustomerSelect) {
      CustomerService.getCustomers().$promise.then(function (customers) {
        $scope.customers = customers
      })
    } else {
      CustomerService.getCustomer(UserService.getCustomerId()).$promise.then(function (customer) {
        $scope.selectCustomer(customer.customerId, customer)
      })
    }

    $scope.selectCustomer = function (customerId, customer) {
      $scope.customerId = customerId
      $scope.customer = customer

      if (!customerId) {
        return
      }

      CustomerService.getStores(customerId).$promise.then(function (stores) {
        $scope.stores = stores
        if (stores.length === 1) {
          $scope.selectStore(stores[0].storeId, stores[0])
        }
      })
    }

    $scope.selectStore = function (storeId, store) {
      $scope.storeId = storeId
      $scope.store = store
    }

    // #endregion

    watchers.push(
      $scope.$watch('storeId', (storeId) => {
        delete ctrl.settingsResource
        delete $scope.settings
        delete $scope.excel
        if (!storeId) {
          return
        }
        HRMService.getSettings(storeId).then((response) => {
          // save $resource object
          ctrl.settingsResource = response
          // unwrap $resource object
          var settings = angular.fromJson(angular.toJson(response))
          // transform values
          transformSettings(settings, settings, timeStringToDate)

          $scope.settings = settings
          calculateExcelValues(settings)
          $scope.weekSwitcher.update()
        })
      })
    )

    watchers.push(
      $scope.$watch(
        'settings',
        (settings, oldVal) => {
          if (settings && oldVal && settings.storeId === oldVal.storeId) {
            updateSettings(settings)
          }
        },
        true
      )
    )

    var updateMinGap = 1000
    var updateLast = 0
    var updatePromise
    function updateSettings(settings) {
      $timeout.cancel(updatePromise)
      updatePromise = $timeout(() => {
        if (!ctrl.settingsResource || settings.storeId !== ctrl.settingsResource.storeId) {
          return
        }

        updateLast = Date.now()
        // apply changes to $resource object
        angular.copy(settings, ctrl.settingsResource)
        // transform values
        transformSettings(settings, ctrl.settingsResource, dateToTimeString)
        // save changes
        ctrl.settingsResource.$update({ hideGlobalSpinner: true }).then(() => {
          Notification({
            message: '<center><em>Daten wurden gespeichert!</em></center>',
            delay: updateMinGap,
            positionX: 'center',
            positionY: 'bottom',
          })
        })
        calculateExcelValues(settings)
      }, Math.max(updateMinGap, updateLast + updateMinGap - Date.now()))
    }

    function calculateExcelValues(input) {
      // calculations are sped up by using multiple parallel promises

      void 0
      void 0

      var week = angular.copy(input).weeks[input.currentWeekIndex]
      var thisTime = Date.now()

      var pCalculateAdditionalDataFromRoster = $q(function (resolve, reject) {
        void 0

        var data = {
          staff: {},
          staffFooter: {
            sumMonthlyGrossWage: 0,
            sumMonthlyWorkingHours: 0,
            avgHourlyGrossWage: [],
            avgWageTaxes: [],
            avgHourlyWagePlusTaxes: [],
            sumWorkingHours: 0,
            sumUndertime: 0,
            sumOvertime: 0,
            sumTotalWage: 0,
            sumTotalSurcharge: 0,
            sumMonthlyWage: 0,
            sumMonthlyWageCosts: 0,
          },
          roster: {
            planHours: Object.keys(SHIFTS)
              .concat(['total'])
              .reduce(function (o, k) {
                o[k] = Array(7).fill(0)
                return o
              }, {}),
            shiftWage: Array(7).fill(0),
          },
        }
        // START

        var EMPLOYEE_DATA_STRUCT = {
          countShifts: 0,
          shifts: [],
          totalSurcharge: 0,
          totalWage: 0,
          workingDays: 0,
          workingHours: 0,
        }

        // calculations based on roster
        Object.keys(SHIFTS).forEach(function (shiftKey) {
          week.roster[shiftKey].forEach(function (row, r) {
            Object.keys(row)
              .filter(function (c) {
                return !isNaN(c)
              })
              .forEach(function (col) {
                var shift = row[col]

                // shift data not complete
                if (!(shift.employee && shift.from instanceof Date && shift.to instanceof Date)) {
                  return
                }

                var uuid = shift.employee
                var employee = week.staff.list[uuid]

                // delete shift if no employee was found
                if (employee == null) {
                  delete input.roster[shiftKey][r][col]
                  return
                }

                // shift working hours { total, day, night }
                var hours = HRMService.getHours(week, shift.from, shift.to)

                if (!data.staff[uuid]) {
                  data.staff[uuid] = angular.copy(EMPLOYEE_DATA_STRUCT)
                }

                data.staff[uuid].workingHours += hours.total
                data.staff[uuid].countShifts++
                if (!data.staff[uuid].shifts[col]) {
                  data.staff[uuid].workingDays++
                  data.staff[uuid].shifts[col] = {}
                }
                data.staff[uuid].shifts[col][shiftKey] = true

                var hoursSurcharged = hours.total
                if (employee.surcharges) {
                  // check holiday surcharge
                  if (week.roster.holidays[col] && week.staff.surcharge.holiday > 0) {
                    hoursSurcharged = hours.total * (1 + week.staff.surcharge.holiday / 100)
                  } else {
                    // check sunday surcharge
                    if (col === '6' && week.staff.surcharge.sunday > 0) {
                      hoursSurcharged = hours.total * (1 + week.staff.surcharge.sunday / 100)
                    } else {
                      // apply night surcharge
                      hoursSurcharged = hours.day + hours.night * (1 + (week.staff.surcharge.night || 0) / 100)
                    }
                  }
                }
                var shiftSurcharge = employee.hourlyGrossWage * (hoursSurcharged - hours.total)
                var shiftWage =
                  employee.hourlyGrossWage * (hoursSurcharged + (hours.total * (employee.wageTaxes || 0)) / 100)

                data.staff[uuid].totalSurcharge += shiftSurcharge
                data.staff[uuid].totalWage += shiftWage

                data.roster.planHours.total[col] += hours.total
                data.roster.planHours[shiftKey][col] += hours.total
                data.roster.shiftWage[col] += shiftWage
              })
          })
        })

        // final calculations and sum & average stats of staff
        Object.keys(week.staff.list).forEach(function (uuid) {
          var employee = week.staff.list[uuid]

          if (!data.staff[uuid]) {
            data.staff[uuid] = angular.copy(EMPLOYEE_DATA_STRUCT)
          }

          if (employee.monthlyGrossWage > 0 && employee.monthlyWorkingHours > 0) {
            data.staff[uuid].undertime = null
            data.staff[uuid].overtime = null
            var diff = employee.monthlyWorkingHours / WEEKS_PER_MONTH - data.staff[uuid].workingHours
            if (diff > 0) {
              data.staff[uuid].undertime = diff
            } else if (diff < 0) {
              data.staff[uuid].overtime = -diff
            }

            data.staff[uuid].monthlyWage =
              employee.monthlyGrossWage +
              ((data.staff[uuid].overtime * employee.monthlyGrossWage) / employee.monthlyWorkingHours +
                data.staff[uuid].totalSurcharge) *
                WEEKS_PER_MONTH
            data.staff[uuid].monthlyWageCosts =
              (employee.monthlyGrossWage +
                (data.staff[uuid].overtime * WEEKS_PER_MONTH * employee.monthlyGrossWage) /
                  employee.monthlyWorkingHours) *
                (1 + (employee.wageTaxes || 0) / 100) +
              data.staff[uuid].totalSurcharge * WEEKS_PER_MONTH
            data.staff[uuid].totalWage = data.staff[uuid].monthlyWageCosts / WEEKS_PER_MONTH
          } else {
            data.staff[uuid].monthlyWage =
              (employee.hourlyGrossWage * data.staff[uuid].workingHours + data.staff[uuid].totalSurcharge) *
              WEEKS_PER_MONTH
            data.staff[uuid].monthlyWageCosts = data.staff[uuid].totalWage * WEEKS_PER_MONTH
          }

          data.staffFooter.sumMonthlyGrossWage += employee.monthlyGrossWage
          data.staffFooter.sumMonthlyWorkingHours += employee.monthlyWorkingHours
          data.staffFooter.avgHourlyGrossWage.push(employee.hourlyGrossWage)
          data.staffFooter.avgWageTaxes.push(employee.wageTaxes)
          data.staffFooter.avgHourlyWagePlusTaxes.push(
            (employee.monthlyGrossWage > 0 && employee.monthlyWorkingHours > 0
              ? employee.monthlyGrossWage / employee.monthlyWorkingHours
              : employee.hourlyGrossWage) *
              (1 + employee.wageTaxes / 100)
          )
          data.staffFooter.sumWorkingHours += (data.staff[uuid] || {}).workingHours || null
          data.staffFooter.sumUndertime += (data.staff[uuid] || {}).undertime || null
          data.staffFooter.sumOvertime += (data.staff[uuid] || {}).overtime || null
          data.staffFooter.sumTotalWage += (data.staff[uuid] || {}).totalWage || null
          data.staffFooter.sumTotalSurcharge += (data.staff[uuid] || {}).totalSurcharge || null
          data.staffFooter.sumMonthlyWage += (data.staff[uuid] || {}).monthlyWage || null
          data.staffFooter.sumMonthlyWageCosts += (data.staff[uuid] || {}).monthlyWageCosts || null
        })

        function filterCallbackNonZero(value) {
          return value
        }

        data.staffFooter.avgHourlyGrossWage =
          $filter('avg')(data.staffFooter.avgHourlyGrossWage.filter(filterCallbackNonZero)) || 0
        data.staffFooter.avgWageTaxes = $filter('avg')(data.staffFooter.avgWageTaxes.filter(filterCallbackNonZero)) || 0
        data.staffFooter.avgHourlyWagePlusTaxes =
          $filter('avg')(data.staffFooter.avgHourlyWagePlusTaxes.filter(filterCallbackNonZero)) || 0

        // END
        void 0
        resolve(data)
      })

      var pCountAvailableEmployeesPerShift = $q(function (resolve, reject) {
        void 0

        var counts = []
        for (var i = 0; i < 7; i++) {
          counts[i] = {}
          Object.keys(SHIFTS).forEach(function (key) {
            counts[i][key] = 0
          })
        }
        // START

        Object.keys(week.staff.list).forEach(function (uuid) {
          week.staff.list[uuid].shifts.forEach(function (shifts, weekday) {
            if (!shifts) {
              return
            }
            Object.keys(shifts).forEach(function (key) {
              if (shifts[key]) {
                counts[weekday][key]++
              }
            })
          })
        })

        // END
        void 0
        resolve(counts)
      })

      return $q.all([pCalculateAdditionalDataFromRoster, pCountAvailableEmployeesPerShift]).then(function (result) {
        void 0
        if (ctrl.excelTime == null || ctrl.excelTime < thisTime) {
          ctrl.excelTime = thisTime
          $scope.excel = result[0]
          $scope.excel.shifts = result[1]
        }
        return result
      })
    }

    // #region transform functions

    function transformSettings(fromObj, toObj, callback) {
      fromObj.weeks.forEach((week, w) => {
        transformWeek(week, toObj.weeks[w], callback)
      })
    }

    function transformWeek(fromObj, toObj, callback) {
      toObj.staff.surcharge.nightFrom = callback(fromObj.staff.surcharge.nightFrom)
      toObj.staff.surcharge.nightTo = callback(fromObj.staff.surcharge.nightTo)
      Object.keys(SHIFTS).forEach((listKey) => {
        fromObj.roster[listKey].forEach((row, r) => {
          Object.keys(row)
            .filter((c) => !isNaN(c))
            .forEach((c) => {
              toObj.roster[listKey][r][c].from = callback(fromObj.roster[listKey][r][c].from)
              toObj.roster[listKey][r][c].to = callback(fromObj.roster[listKey][r][c].to)
            })
        })
      })
    }

    // time input transform helpers
    function timeStringToDate(time) {
      // 12:00 -> Date(now 12:00)
      if (typeof time === 'string') {
        var match = time.match(/^(\d{2}):(\d{2})$/)
        if (match) {
          var date = new Date(null)
          date.setHours(match[1], match[2])
          return date
        }
      }
      return null
    }
    function dateToTimeString(date) {
      // Date(12:00) -> 12:00
      if (date && date instanceof Date) {
        return $filter('date')(date, 'HH:mm')
      }
      return null
    }

    // #endregion

    $scope.weekSwitcher = {
      canPrev: false,
      canNext: false,
      delete: function (event) {
        var dateStart = $scope.settings.weeks[$scope.settings.currentWeekIndex].dateStart
        var weekStart = $filter('date')(dateStart)
        var weekEnd = $filter('date')(moment(dateStart).add(6, 'days').toDate())
        var kw = $filter('date')(dateStart, 'w')
        var dialogContent =
          '<p>Möchten Sie die Woche <b>' + weekStart + '–' + weekEnd + '</b> (KW ' + kw + ') endgültig löschen?</p>'
        if ($scope.settings.weeks.length == 1) {
          dialogContent +=
            '<p><u>Achtung:</u> Dies ist die letzte Woche mit Daten.' +
            '<br>Wenn Sie diese löschen, gehen alle Daten* verloren.</p>' +
            '<p class="muted">* <i>Mitarbeiter, Berufe, Zuschläge, Dienstplan, Verfügbarkeiten</i></p>'
        }

        var confirm = $mdDialog
          .confirm()
          .title('Woche löschen')
          .htmlContent(dialogContent)
          .ok('Ja, löschen')
          .cancel('Nein, abbrechen')
          .theme($scope.$root.appTheme)
          .targetEvent(event)

        $mdDialog.show(confirm).then(() => {
          var index = this._getWeekIndexBefore()
          if (index == -1) {
            index = this._getWeekIndexAfter()
          }
          if (index > $scope.settings.currentWeekIndex) {
            index -= 1
          }
          $scope.settings.weeks.splice($scope.settings.currentWeekIndex, 1)
          if ($scope.settings.weeks.length == 0) {
            var week = HRMService.newWeek()
            transformWeek(week, week, timeStringToDate)
            $scope.settings.weeks.push(week)
            index = 0
          }
          $scope.settings.currentWeekIndex = index
          this.update()
        })
      },
      switch: function (event) {
        var dialog = {
          templateUrl: 'src/hrm/views/week_switcher.dialog.html',
          targetEvent: event,
          controller: function () {
            this.abort = $mdDialog.cancel
            this.hide = function () {
              $mdDialog.hide(this.date)
            }
          },
          controllerAs: 'dialog',
        }

        $mdDialog.show(dialog).then((date) => {
          var dateStart = moment(date).startOf('week').toDate()
          var index = $scope.settings.weeks.findIndex((week) => moment(week.dateStart).isSame(dateStart, 'day'))
          if (index != -1) {
            $scope.settings.currentWeekIndex = index
            this.update()
            return
          }

          var index = this._getWeekIndexBefore(dateStart)
          if (index == -1) {
            index = this._getWeekIndexAfter(dateStart)
          }
          if (index != -1) {
            var week = angular.copy($scope.settings.weeks[index])
            week.dateStart = dateStart
            updateWeekPlanSales(week).then(() => {
              var length = $scope.settings.weeks.push(week)
              $scope.settings.currentWeekIndex = length - 1
              this.update()
            })
          }
        })
      },
      prev: function (event) {
        var index = this._getWeekIndexBefore()
        if (index != -1) {
          $scope.settings.currentWeekIndex = index
          this.update()
        }
      },
      next: function (event) {
        var index = this._getWeekIndexAfter()
        if (index != -1) {
          $scope.settings.currentWeekIndex = index
          this.update()
        }
      },
      update: function () {
        var week = $scope.settings.weeks[$scope.settings.currentWeekIndex]
        HRMService.getDailyTotals($scope.settings.storeId, week).then((response) => {
          response.forEach((entry) => {
            var diff = moment(week.dateStart).diff(entry.date, 'days')
            week.costs.actualSales[Math.abs(diff)] = entry.total
            if (!week.costs.actualSalesReadOnly) {
              week.costs.actualSalesReadOnly = []
            }
            week.costs.actualSalesReadOnly[Math.abs(diff)] = true
          })
        })

        if ($scope.settings.weeks.length == 1) {
          this.canPrev = false
          this.canNext = false
          return
        }

        this.canPrev = this._getWeekIndexBefore() != -1
        this.canNext = this._getWeekIndexAfter() != -1
      },
      _getWeekIndexBefore: function (date) {
        var index = -1
        var before = null
        var currentWeekStart = date || $scope.settings.weeks[$scope.settings.currentWeekIndex].dateStart
        $scope.settings.weeks.forEach((week, i) => {
          if (moment(week.dateStart).isBefore(currentWeekStart, 'day')) {
            if (before == null || moment(week.dateStart).isAfter(before.dateStart, 'day')) {
              index = i
              before = week
            }
          }
        })
        return index
      },
      _getWeekIndexAfter: function (date) {
        var index = -1
        var after = null
        var currentWeekStart = date || $scope.settings.weeks[$scope.settings.currentWeekIndex].dateStart
        $scope.settings.weeks.forEach((week, i) => {
          if (moment(week.dateStart).isAfter(currentWeekStart, 'day')) {
            if (after == null || moment(week.dateStart).isBefore(after.dateStart, 'day')) {
              index = i
              after = week
            }
          }
        })
        return index
      },
    }

    $scope.getAvgShiftTotals = function (event, qtyLastWeeks) {
      var week = $scope.settings.weeks[$scope.settings.currentWeekIndex]
      updateWeekPlanSales(week, qtyLastWeeks)
    }

    function updateWeekPlanSales(week, qtyLastWeeks) {
      var round = (f, d) => Math.round(f * Math.pow(10, d)) / Math.pow(10, d)

      return HRMService.getAvgShiftTotals($scope.settings.storeId, week, qtyLastWeeks).then((response) => {
        week.costs.planSales = []
        week.costs.planSalesDay = []
        week.costs.planSalesEvening = []

        response.forEach((entry) => {
          week.costs.planSales[entry.weekday] = round(entry.avgTotal, 2)
          week.costs.planSalesDay[entry.weekday] = round(entry.avgDayTotal, 2)
          week.costs.planSalesEvening[entry.weekday] = round(entry.avgNightTotal, 2)
        })
      })
    }
  }

  function HRMDriversController($scope, HRMService) {
    var watchers = []
    $scope.$on('$destroy', () => watchers.forEach((watcher) => watcher()))

    watchers.push(
      $scope.$watch('storeId', function (storeId) {
        delete $scope.data
      })
    )

    watchers.push(
      $scope.$watch('settings', function (settings) {
        if (settings) {
          $scope.loadDriversData()
        }
      })
    )

    $scope.loadDriversData = function () {
      if ($scope.loading) {
        return
      }
      $scope.loading = true
      return HRMService.getDriversData($scope.settings)
        .then(function (response) {
          var data = response
          data.dates = []
          data.total = []
          data.totalDelivery = 0
          data.totalInhouse = 0

          var i, len

          // add period separators
          data.periodsLengthCompensator = 0
          for (i = 0; i < data.periods.length; i++) {
            var name = data.periods[i]
            data.periods[i] = {
              name: name,
              separatorCompensator: data.periodsLengthCompensator,
            }

            if (i === 0) {
              continue
            }

            var prev = data.periods[i - 1].name.split(/[-–]/)[1]
            var next = data.periods[i].name.split(/[-–]/)[0]

            if (next !== moment(prev, 'HH:mm').add(1, 'minute').format('LT')) {
              data.periods[i].separatorCompensator++
              data.periods.splice(i, 0, { separator: true })
              data.periodsLengthCompensator++
              i++
            }
          }

          for (i = 0, len = data.result.length; i < len; i++) {
            var row = data.result[i]

            // collect dates
            if (data.dates[row.weekday] == null) {
              data.dates[row.weekday] = []
            }
            if (row.dates) {
              data.dates[row.weekday] = Array.from(new Set(data.dates[row.weekday].concat(row.dates))).sort()
            }

            // sum total (per weekday)
            if (data.total[row.weekday] == null) {
              data.total[row.weekday] = 0
            }
            data.total[row.weekday] += row.total

            // sum totals
            data.totalDelivery += row.total
            data.totalInhouse += row.totalInhouse
          }

          data.inhousePercentage = Math.round((data.totalInhouse / (data.totalDelivery + data.totalInhouse)) * 100)
          if ($scope.settings && $scope.settings.$init && data.inhousePercentage != null) {
            $scope.settings.drivers.inhousePercentage = data.inhousePercentage
          }

          $scope.data = data
          return data
        })
        .finally(function () {
          $scope.loading = false
        })
    }
  }

  function HRMStaffController($scope, UUID) {
    $scope.addEmployee = function (staff) {
      if (staff.list.length === 0) {
        staff.list = {}
      }
      staff.list[UUID.v4()] = {
        lastname: '',
        forename: '',
        contact: '',
        job: null,
        monthlyGrossWage: null,
        monthlyWorkingHours: null,
        hourlyGrossWage: null,
        wageTaxes: null,
        surcharges: false,
        shifts: [],
      }
    }

    $scope.remEmployee = function (staff, uuid) {
      delete staff.list[uuid]
    }

    $scope.addJob = function (staff) {
      if (staff.jobs.length === 0) {
        staff.jobs = {}
      }
      staff.jobs[UUID.v4()] = {
        tag: '',
        name: '',
      }
    }

    $scope.remJob = function (staff, uuid) {
      delete staff.jobs[uuid]
    }
  }

  function HRMRosterController($mdDateLocale, $scope, spinnerService, Notification, UNPKG) {
    $scope.addShift = function (list) {
      list.push({
        job: (list[list.length - 1] || {}).job || '',
      })
    }

    $scope.remShift = function (list, index) {
      list.splice(index, 1)
    }

    $scope.pdfExport = function (event) {
      $scope.loadingExport = true
      spinnerService.show('spinnerGlobal')
      UNPKG.load('jspdf', '2.5.1', 'dist/jspdf.umd.min.js')
        .then(function () {
          var week = $scope.settings.weeks[$scope.settings.currentWeekIndex]
          return createExportPDF(week, 'Dienstplan - ' + $scope.store.name + '.pdf')
        })
        .catch(function () {
          Notification.error('Fehler beim Generieren des PDFs')
        })
        .finally(function () {
          $scope.loadingExport = false
          spinnerService.hide('spinnerGlobal')
        })
    }

    function createExportPDF(week, fileName) {
      var marginX = 10
      var marginY = 10
      var pageFormat = 'a4'
      var pageOrient = 'landscape'
      var doc = new jspdf.jsPDF({ orientation: pageOrient, putOnlyUsedFonts: true })
      var colWidth = ((pageOrient[0] == 'l' ? 297 : 210) - 2 * marginX) / 8 / 0.75 // 0.75 := var px2pt from jspdf@2.5.1 (/src/modules/cell.js#43)

      Object.keys($scope.SHIFTS).forEach(function (shiftKey, index) {
        var data = getRosterRows(week, week.roster[shiftKey])
        var headers = getRosterHeaders(week, $scope.SHIFTS[shiftKey].name, colWidth)
        var config = {
          autoSize: false,
          headerBackgroundColor: $scope.SHIFTS[shiftKey].color,
          margins: { left: marginX, top: marginY, bottom: marginY, right: marginX },
        }

        if (index > 0) {
          doc.addPage(pageFormat, pageOrient)
        }
        doc.table(marginX, marginY, data, headers, config)
      })

      return doc.save(fileName)
    }

    function getRosterRows(week, data) {
      var result = []
      var timeFormatter = Date.prototype.toLocaleTimeString
      var timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
      }
      var timeArgArray = ['de-DE', timeOptions]

      data.forEach(function (dataRow) {
        var row = [dataRow.job]
        for (var i = 0; i <= 6; i++) {
          var shift = dataRow[i]
          if (!shift || !shift.employee) {
            row.push('')
            continue
          }

          var employee = week.staff.list[shift.employee]
          var cell = [[employee.forename, employee.lastname].join(' ').trim(), '']

          if (shift.from) {
            cell[1] += timeFormatter.apply(shift.from, timeArgArray)
            if (shift.to) {
              cell[1] += '–'
            }
          }
          if (shift.to) {
            cell[1] += timeFormatter.apply(shift.to, timeArgArray)
          }

          row.push(cell)
        }
        result.push(row)
      })
      return result
    }

    function getRosterHeaders(week, shiftName, colWidth) {
      var weekdays = angular.copy($mdDateLocale.shortDays).rotate($mdDateLocale.firstDayOfWeek)
      var start = moment(week.dateStart)
      for (var index = 0, length = weekdays.length; index < length; index++) {
        weekdays[index] += ', ' + start.add(index ? 1 : 0, 'days').format('D. MMM')
      }

      var result = [shiftName].concat(weekdays).reduce(function (headers, title, index) {
        headers.push({
          name: index,
          prompt: title,
          align: 'center',
          width: colWidth,
        })
        return headers
      }, [])
      return result
    }
  }

  function HRMShiftsController($scope, $transition$) {
    $scope.view = $transition$.params().view || 'overview'
  }
})()
