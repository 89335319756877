(function () {
  'use strict'

  // F**K YOU FF !
  // This changes the view value of time inputs from "HH:mm:ss.SSS" (in FF) to "HH:mm"

  angular
    .module('ottomatikStoreManager')
    .directive('ffTimeFix', ffTimeFixDirective)

  function ffTimeFixDirective () {
    return {
      link: link,
      require: 'ngModel',
      restrict: 'A',
    }

    function link (scope, element, attrs, ngModel) {
      ngModel.$render = function () {
        if (ngModel.$modelValue instanceof Date && element[0].type === 'time') {
          element[0].value = moment(ngModel.$modelValue).format('HH:mm')
        }
      }
    }
  }
})()
