(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager')
    .component('omOph', {
      bindings: {
        customer: '<',
        store: '<',
      },
      controller: OpeningHoursController,
      templateUrl: 'src/components/om-oph.html',
    })

  function OpeningHoursController ($scope, $mdDialog, StoreService) {
    var $ctrl = this

    $ctrl.$onChanges = function (changes) {
      var customer = $ctrl.customer
      if (changes.customer && changes.customer.currentValue) {
        customer = changes.customer.currentValue
      }
      var store = $ctrl.store
      if (changes.store && changes.store.currentValue) {
        store = changes.store.currentValue
      }
      if (customer != null && store != null) {
        $ctrl.load(customer, store)
      }
    }

    $ctrl.load = function (customer, store) {
      $scope.oph = null
      $scope.loading = true
      var params = {
        customerId: customer.customerId,
        storeId: store.storeId,
      }
      StoreService.getOpeningHours(params)
        .then(function (oph) {
          $scope.oph = oph
        })
        .finally(function () {
          $scope.loading = false
        })
    }

    $scope.openDialog = function (event) {
      $mdDialog.show({
        templateUrl: 'src/templates/dialog-opening-hours.html',
        autoWrap: false,
        targetEvent: event,
        scope: $scope,
        preserveScope: true,
        clickOutsideToClose: true,
      })
    }

    $scope.close = function () {
      $mdDialog.hide()
    }
  }
})()
