(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.orders', ['angular-svg-round-progressbar'])
    .config(function ($stateProvider, moduleRegisterProvider) {
      $stateProvider.state('orders', {
        url: '/orders',
        views: {
          '@': { templateUrl: 'src/orders/views/layout.html' },
          'colMain@orders': {
            templateUrl: 'src/orders/views/orders.html',
            controller: 'OrderController',
          },
        },
      })

      moduleRegisterProvider.add({
        id: 'orders',
        title: 'Bestelldetails',
        defaultUrl: 'orders',
        materialIcon: 'view_list',
        accessRoles: ['admin', 'orders'],
      })
    })
    .service('OrderService', function (CONFIG, $mdDialog, $mdSidenav, $q, $resource, $rootScope, helperService) {
      var service = this

      // region RESOURCES

      service.anonymize = function (orderId) {
        var parameters = {
          action: 'anonymize',
          orderId: orderId,
        }
        return OrderAction.get(parameters).$promise
      }

      service.cancelOrder = function (orderId, data) {
        var parameters = {
          action: 'cancel',
          orderId: orderId,
        }
        return OrderAction.create(parameters, data).$promise
      }

      service.getMammonInfo = function (orderId) {
        return Mammon.get({ orderId: orderId }).$promise
      }

      service.getOrders = function (params, exportCsv) {
        if (exportCsv) {
          return Order.export(params).$promise
        }
        return Order.query(params).$promise
      }

      service.redirect = function (params) {
        var parameters = Object.assign({}, params, {
          action: 'redirect',
          suffix: params.storeId,
        })
        delete parameters.storeId
        return OrderAction.get(parameters).$promise
      }

      service.resend = function (params) {
        var parameters = Object.assign({}, params, {
          action: 'resend',
          suffix: params.storeId,
        })
        delete parameters.storeId
        return OrderAction.get(parameters).$promise
      }

      service.sendMail = function (params, data) {
        var parameters = Object.assign({}, params, {
          action: 'sendmail',
          suffix: params.templateId,
        })
        delete parameters.templateId
        return OrderAction.create(parameters, data).$promise
      }

      // RESOURCES

      var Order = $resource(CONFIG.API_URL + '/orders', {}, {
        query: {
          transformResponse: helperService.appendTransform(helperService.orderTransformation),
        },
        export: {
          params: { export: true },
          responseType: 'arraybuffer',
          cache: false,
          transformResponse: helperService.data2download,
        },
      })

      var OrderAction = $resource(CONFIG.API_URL + '/orders/:action/:orderId/:suffix', { hideGlobalSpinner: true })

      var Mammon = $resource(CONFIG.API_URL + '/mammon/:orderId', { hideGlobalSpinner: true })

      // endregion

      // region DIALOGS

      service.openDialogAnonymize = function (event, order) {
        var confirm = $mdDialog.confirm()
          .title('Anonymisieren?')
          .htmlContent(
            '<p class="alert-warning bold center">!!! ACHTUNG !!! VORSICHT !!!</p>' +
            '<p class="center">Bist du sicher, dass die Kunden-Daten<br>zu <b>Bestellung ' + order.orderId + '</b><br><u>entgültig gelöscht</u> werden sollen?</p>'
          )
          .ok('Ja, bitte')
          .cancel('Nein, abbrechen')
          .targetEvent(event)
        confirm._options.escapeToClose = false
        confirm._options.focusOnOpen = false

        return $mdDialog.show(confirm)
          .then(function () {
            return service.anonymize(order.orderId)
          })
          .then(function (update) {
            order.customer = update.customer
          })
      }

      service.openDialogCancel = function (event, orders) {
        orders = angular.isArray(orders) ? orders : [orders]
        return $mdDialog.show(getDialogOptions(event, {
          templateUrl: 'src/orders/views/dialog-order-cancel.html',
          controller: 'OrderCancelController',
          locals: {
            orders: orders,
          },
        }))
      }

      service.openDialogMammon = function (event, customer, order) {
        return $mdDialog.show(getDialogOptions(event, {
          templateUrl: 'src/orders/views/dialog-order-mammon.html',
          controller: 'OrderMammonController',
          locals: {
            customer: customer,
            order: order,
          },
        }))
      }

      service.openDialogOrderRaw = function (event) {
        return $mdDialog.show(getDialogOptions(event, {
          contentElement: '#dialogOrderRaw',
          clickOutsideToClose: true,
          escapeToClose: true,
        }))
      }

      service.openDialogRedirect = function (event, customer, order) {
        return $mdDialog.show(getDialogOptions(event, {
          templateUrl: 'src/orders/views/dialog-order-redirect.html',
          controller: 'OrderRedirectController',
          locals: {
            customer: customer,
            orders: [order],
          },
        }))
          .then(function (result) {
            if (result && result.followUp === 'cancel') {
              return service.openDialogCancel(event, order)
            }
            return $q.when(result)
          })
      }

      service.openDialogResend = function (event, orders) {
        orders = angular.isArray(orders) ? orders : [orders]
        return $mdDialog.show(getDialogOptions(event, {
          templateUrl: 'src/orders/views/dialog-order-redirect.html',
          controller: 'OrderRedirectController',
          locals: {
            onlyResend: true,
            orders: orders,
          },
        }))
      }

      service.openDialogSendMail = function (event, order) {
        return $mdDialog.show(getDialogOptions(event, {
          templateUrl: 'src/orders/views/dialog-order-sendmail.html',
          controller: 'OrderSendMailController',
          locals: {
            order: order,
          },
        }))
      }

      // HELPERS

      function getDialogOptions (event, options) {
        return angular.extend({
          targetEvent: event,
          escapeToClose: false,
          focusOnOpen: false,
          bindToController: true,
        }, options)
      }

      // endregion

      service.openReporting = function (event, customer, order, topic) {
        $rootScope.reportingEditReport = {
          customer: { customerId: customer.customerId },
          store: { storeId: order.currentStore.storeId },
          type: topic,
          note: order.orderId,
        }
        $mdSidenav('reporting').open()
      }
    })
    .controller('OrderController', function ($scope, $filter, $interval, $mdDialog, $mdSidenav, helperService, CustomerService, OrderService, ServicecenterService, UserService) {
      var self = this

      $scope.storesall = UserService.hasRole('admin') || UserService.hasRole('storesall')
      $scope.showDaterange = !UserService.hasRole('orders-limited')
      $scope.showExport = $scope.storesall || UserService.hasRole('orders_export')
      $scope.showFilterCustomer = $scope.storesall
      $scope.showFilterPayment = false
      $scope.showFilterTip = false
      $scope.showTipInsteadOfTotal = false
      $scope.showOtsdetail = UserService.hasRole('orders_otsdetail')
      $scope.$root.showLinkPhonenumberCRM = UserService.hasRole(['admin', 'crm'])

      self.reset = function () {
        $scope.orders = undefined
        $scope.ordersCount = undefined
        $scope.ordersTotal = undefined
        $scope.distribution = undefined
        $scope.filter.page = 1
      }

      $scope.view = {
        active: null,
        change: function (id) {
          self.reset()
          this.active = id
          this.orderId = ''
          if (id !== 'today') {
            $scope.reloadCountdown.stop()
          }
        },
        options: {
          today: 'Heute',
          daterange: 'Zeitraum',
        },
        orderId: '',
        orderKeypress: function (event) {
          if (event && event.key === 'Enter') {
            event.target.blur()
            this.showOrder()
          }
        },
        showOrder: function () {
          if ($scope.loading) {
            return
          }
          this.active = null
          self.reset()
          $scope.loading = true
          $scope.reloadCountdown.stop()
          ServicecenterService.getByOrderId(this.orderId).then(
            function (data) {
              $scope.orders = data.records
              $scope.ordersCount = data.recordTotal
              $scope.ordersTotal = $scope.orders.length ? $scope.orders[0].total : 0
              $scope.showTipInsteadOfTotal = false
              self.getCombinedOtsStatus()
            }
          ).finally(
            function () {
              $scope.loading = false
            }
          )
        },
      }

      if (UserService.hasRole('orders-limited-today')) {
        delete $scope.view.options.daterange
      }

      $scope.filter = {
        date: {
          from: new Date(),
          to: new Date(),
          change: function (field) {
            if (!this.to || !this.from || this.to < this.from) {
              if (field === 'from') {
                this.to = this.from
              } else {
                this.from = this.to
              }
            }
          },
          _min: $scope.showDaterange ? undefined : moment().subtract(1, 'day').toDate(),
          _max: new Date(),
          _update: function (customer) {
            if ($scope.showDaterange && customer) {
              this._min = new Date(customer.onlineSince)
            }
          },
        },
        time: {
          range: undefined,
          onChangeRange: function (event) {
            this.range = (this.range || '').replace(/[^\d: -]/g, '')

            var range = this.range.match(/^(\d{0,2}):?(\d{0,2})[ -]*(\d{0,2}):?(\d{0,2})$/)
            if (range === null || !this.range.length) {
              this.range = ''
              return
            }

            var h1 = range[1]; var m1 = range[2]; var h2 = range[3]; var m2 = range[4]

            var fill = event && (event.key === 'Enter' || event.type === 'blur')
            if (fill) {
              h1 = '0'.repeat(2 - h1.length) + h1
              m1 = '0'.repeat(2 - m1.length) + m1
              h2 = '0'.repeat(2 - h2.length) + h2
              m2 = '0'.repeat(2 - m2.length) + m2
              if (h1 > '23') { h1 = '23' }
              if (m1 > '59') { m1 = '59' }
              if (h2 > '23') { h2 = '23' }
              if (m2 > '59') { m2 = '59' }
              this.range = h1 + ':' + m1 + ' - ' + h2 + ':' + m2
              event.target.blur()
            }
          },
        },
        customer: undefined,
        items: undefined,
        total: undefined,
        payment: {
          selected: { value: undefined },
          options: [
            { value: 'offline%', name: 'Offline' },
            { value: 'offline:free', name: '  Gratis' },
            { value: 'offline:cash', name: '  Barzahlung' },
            { value: 'offline:ec', name: '  EC-Karte' },
            { value: 'offline:creditcard', name: '  Kreditkarte' },
            { value: 'offline:applepay', name: '  Apple Pay' },
            { value: 'offline:googlepay', name: '  Google Pay' },
            { value: 'online%', name: 'Online' },
            { value: 'online:paypal', name: '  PayPal' },
            { value: 'online:giropay', name: '  giropay' },
            { value: 'online:sofort', name: '  Klarna Sofort' },
            { value: 'online:amazonpay', name: '  Amazon Pay' },
          ],
        },
        redirect: {
          selected: { value: undefined },
          options: [
            { value: 2, name: 'empfangene Weiterl.' },
            { value: 3, name: 'gesendete Weiterl.' },
          ],
        },
        tip: false,
        limit: undefined,
        page: undefined,
        updatePagination: function (pagination) {
          var init = this.limit === undefined
          this.limit = pagination.limit
          this.page = pagination.page
          if (!init) {
            this.reload()
          }
        },
        reload: function () {
          self.loadOrders()
        },
        reset: function () {
          this.date.from = new Date()
          this.date.to = new Date()
          this.time.range = undefined
          this.customer = undefined
          this.items = undefined
          this.total = undefined
          this.payment.selected = { value: undefined }
          this.redirect.selected = { value: undefined }
          this.tip = false
          this.page = 1
        },
        export: function () {
          self.loadOrders(true)
        },
        keypress: function (key) {
          if (key.which === 13) { // on pressing ENTER (13)
            $scope.filter.reload()
          }
        },
      }

      self.initCustomerSelect = function () {
        CustomerService.getCustomers().$promise.then(
          function (customers) {
            $scope.customers = customers.filter(
              function (customer) {
                return customer.customerSystemAssociations.length > 0
              }
            )
          }
        )

        if (!$scope.filter.customerSelect) {
          $scope.filter.customerSelect = function (customerId, customer) {
            $scope.filter.customerId = customerId
            $scope.customer = customer
            $scope.filter.date._update(customer)
            $scope.reloadCountdown.stop()
            self.reset()
            self.initStoreSelect()
          }
        }
      }

      self.initStoreSelect = function () {
        $scope.filter.reset()
        CustomerService.getStoresWithGroups($scope.customer.customerId).$promise.then(
          function (response) {
            $scope.storegroups = response.groups
            $scope.stores = response.stores
            if ($scope.stores.length) {
              UserService.filterCustomerSystems($scope.customer)
              $scope.showFilterPayment = $scope.customer.options.filterPayment
              if (!$scope.showFilterPayment) {
                $scope.filter.payment.selected.value = undefined
              }
              $scope.showFilterTip = $scope.customer.options.hasTip
              if (!$scope.showFilterTip) {
                $scope.filter.tip = false
              }
            }
            if ($scope.stores.length === 1) {
              $scope.filter.storeSelect($scope.stores[0].storeId, $scope.stores[0])
            }
            self.loadOrders()
          }
        )

        if (!$scope.filter.storeSelect) {
          $scope.filter.storeSelect = function (storeId, store) {
            $scope.filter.storeId = storeId
            $scope.store = store
          }
        }
      }

      $scope.filter.systemSelect = function (systemId, system) {
        $scope.filter.systemId = systemId
        $scope.system = system
      }

      self.loadOrders = function (download) {
        if ($scope.loading) {
          return
        }
        var alert

        var from = $filter('date')(new Date())
        var to = $filter('date')(new Date())
        var dialogTitle, dialogText
        if ($scope.view.active === 'daterange') {
          var maxDays = 184

          dialogTitle = 'Ungültiger Datumsbereich'
          if ($scope.filter.date.from == null || $scope.filter.date.to == null) {
            dialogText = 'Die Start- und End-Daten müssen gesetzt sein.'
          } else if (Math.abs(moment($scope.filter.date.from).diff($scope.filter.date.to, 'days')) + 1 > maxDays) {
            dialogText = 'Der ausgewählte Datumsbereich ist zu groß.<br>Es sind maximal ' + maxDays + ' Tage erlaubt.'
          }

          if (dialogText) {
            alert = $mdDialog.alert().title(dialogTitle).ariaLabel(dialogTitle).htmlContent(dialogText).ok('OK')
            return $mdDialog.show(alert)
          }
          from = $filter('date')($scope.filter.date.from)
          to = $filter('date')($scope.filter.date.to)
        }

        if ($scope.filter.total && $scope.filter.total.replace(/\s/, '').match(/^(|=|<|<=|>|>=)(-?\d+(?:,\d{1,2})?)$/) === null) {
          dialogTitle = 'Ungültige Summe'
          dialogText = 'Keine gültige Summen-Abfrage!'
          alert = $mdDialog.alert().title(dialogTitle).ariaLabel(dialogTitle).textContent(dialogText).ok('OK')
          return $mdDialog.show(alert)
        }

        var params = {
          customerId: $scope.filter.customerId,
          storeId: $scope.filter.storeId,
          csaId: $scope.system ? $scope.system.customerSystemId : undefined,
          dateFrom: from,
          dateTo: to,
          timeRange: $scope.filter.time.range || undefined,
          customer: $scope.filter.customer || undefined,
          items: $scope.filter.items || undefined,
          total: $scope.filter.total || undefined,
          payment: $scope.filter.payment.selected.value,
          redirected: $scope.filter.redirect.selected.value,
          tip: $scope.filter.tip || undefined,
          page: $scope.filter.page,
          limit: $scope.filter.limit,
        }

        $scope.loading = true

        var promise
        if (download) {
          promise = OrderService.getOrders(params, true)
        } else {
          promise = OrderService.getOrders(params)
          promise.then(
            function (data) {
              $scope.orders = data.records
              $scope.ordersCount = data.recordTotal
              $scope.ordersTotal = data.distribution.reduce(
                function (sum, row) {
                  return sum + row.total
                },
                0
              )
              $scope.distribution = data.distribution
              $scope.showTipInsteadOfTotal = Boolean(params.tip)
              self.getCombinedOtsStatus()
            }
          ).finally(
            function () {
              if ($scope.view.active === 'today') {
                $scope.reloadCountdown.start()
              }
            }
          )
        }
        promise.finally(
          function () {
            $scope.loading = false
          }
        )
      }

      $scope.reloadCountdown = {
        enabled: true,
        max: 60,
        current: 0,
        showLabel: false,
        interval: undefined,
        toggle: function () {
          if (this.enabled) {
            this.disable()
          } else {
            this.enable()
          }
        },
        start: function () {
          if (!this.enabled) {
            return
          }
          this.current = this.max
          this.showLabel = true
          if (!this.interval) {
            this.interval = $interval(
              function () {
                if ($scope.reloadCountdown.current === 0) {
                  $scope.reloadCountdown.stop()
                  self.loadOrders()
                } else {
                  $scope.reloadCountdown.current--
                }
              },
              1000
            )
          }
        },
        stop: function () {
          $interval.cancel(this.interval)
          this.interval = undefined
          this.current = 0
          this.showLabel = false
        },
        enable: function () {
          this.enabled = true
          this.start()
        },
        disable: function () {
          this.enabled = false
          this.stop()
        },
      }

      $scope.statusCombinedLoading = true
      self.getCombinedOtsStatus = function () {
        if (!$scope.statusCombinedLoading || !$scope.showOtsdetail || !Array.isArray($scope.orders)) {
          return
        }
        var orderIds = []
        angular.forEach($scope.orders, function (order) {
          if (order.customerSystemAssociation.system.hasTransmission) {
            orderIds.push(order.orderId)
          }
        })
        if (orderIds.length) {
          ServicecenterService.getOtsStatus(orderIds.join()).then(
            function (response) {
              angular.forEach($scope.orders, function (order) {
                if (order.orderId in response) {
                  order.otsstatus = response[order.orderId]
                } else {
                  order.otsstatus = { error: true }
                }
              })
            },
            function (error) {
              switch (error.status) {
                case 943:
                  var unbind = $scope.$watch(
                    function () {
                      return ServicecenterService.allowRestart()
                    },
                    function (newVal, oldVal) {
                      if (newVal !== oldVal) {
                        self.getCombinedOtsStatus()
                        unbind()
                      }
                    }
                  )
                  break
                default:
                  angular.forEach($scope.orders, function (order) {
                    order.otsstatus = { error: true, cancel: error.status === 942 }
                  })
              }
            }
          )
        }
      }

      $scope.btnOrderDetails = function (order) {
        $scope.$root.customer = $scope.customer
        $scope.$root.hideSku = $scope.customer.options.hideSku === 1 && UserService.getCustomerId() !== 999999999
        $scope.$root.order = order
        $mdSidenav('orderDetails').toggle()
      }

      if ($scope.showFilterCustomer) {
        self.initCustomerSelect()
      } else {
        CustomerService.getCustomer(UserService.getCustomerId()).$promise.then(
          function (customer) {
            $scope.customer = customer
            $scope.filter.date._update(customer)
            self.initStoreSelect()
          }
        )
      }

      $scope.view.change('today')

      var deregisterCustomerWatch = $scope.$watch('customer', function (customer) {
        if (customer) {
          $scope.customerSystemAssociations = customer.customerSystemAssociations.filter(
            function (csa) {
              return csa.active
            }
          )
        } else {
          $scope.customerSystemAssociations = []
        }
      })

      $scope.$on('$destroy', function () {
        $scope.reloadCountdown.stop()
        deregisterCustomerWatch()
      })
    })
    .controller('OrderDetailsController', function ($scope, $mdSidenav, $mdDialog, OrderService, UserService) {
      $scope.userIsAdmin = UserService.hasRole('admin')
      $scope.showOtsdetail = UserService.hasRole('orders_otsdetail')

      $scope.prev = function () {
        $scope.$root.current--
      }

      $scope.next = function () {
        $scope.$root.current++
      }

      $scope.openDialogAnonymize = OrderService.openDialogAnonymize
      $scope.openDialogCancel = OrderService.openDialogCancel
      $scope.openDialogMammon = OrderService.openDialogMammon
      $scope.openDialogOrderRaw = OrderService.openDialogOrderRaw
      $scope.openDialogRedirect = OrderService.openDialogRedirect
      $scope.openDialogResend = OrderService.openDialogResend
      $scope.openDialogSendMail = OrderService.openDialogSendMail
      $scope.openReporting = OrderService.openReporting
    })
    .controller('OrderCancelController', function ($mdDialog, $q, $scope, OrderService) {
      var self = this

      $scope.orders = self.orders.slice().reverse()
      $scope.limited = $scope.orders.filter(function (order) {
        return order.customerSystemAssociation.system.origin !== 'ottomatik_magento'
      }).length

      $scope.close = function () {
        $scope.submitted ? $mdDialog.hide() : $mdDialog.cancel()
      }

      $scope.cancel = true
      $scope.sendEmail = true
      $scope.createReport = true
      $scope.reason = null

      $scope.reasons = {
        customer_not_in_deliveryarea: 'Adresse des Bestellers liegt außerhalb des Liefergebietes',
        customer_not_reachable: 'Besteller nicht angetroffen/erreichbar',
        customer_canceled: 'Besteller hat abbestellt',
        double_order: 'Doppelte Bestellung',
        fake_order: 'Fake-Bestellung',
        none: 'Nicht angegeben',
        problem_customer: 'Problemkunde',
        store_closed: 'Store geschlossen',
        store_no_delivery: 'Store hat nicht ausgeliefert',
        store_busy: 'Zu hohes Bestellaufkommen (lange Lieferzeiten)',
      }

      $scope.submit = function () {
        if ($scope.submitted) {
          return
        }
        $scope.submitted = true

        var params = {
          cancel: $scope.cancel,
          send_email: $scope.sendEmail,
          create_report: $scope.createReport,
          reason: $scope.reason,
          reason_title: $scope.reasons[$scope.reason],
        }

        $scope.loading = true
        $scope.response = {}

        var deferred = $q.defer()
        deferred.resolve()

        // loop through orders and cancel them sequentially
        return $scope.orders.reduce(function (promise, order) {
          $scope.response[order.orderId] = { init: true }

          // chain .then to last promise
          return promise.then(function () {
            $scope.response[order.orderId] = { loading: true }

            // cancel order
            return OrderService.cancelOrder(order.orderId, params)
              .then(function (result) {
                $scope.response[order.orderId] = result
                if (result.success) {
                  order.orderStatus = 'closed'

                  // close paypal dispute
                  if (order.infos.openPayPalDispute) {
                    $scope.$root.updateOrderInfo(order, 'openPayPalDispute', false)
                  }
                }
              })
          })
        }, deferred.promise)
          .finally(function () {
            $scope.loading = false

            // show result for a single order
            if ($scope.orders.length === 1) {
              $scope.result = $scope.response[$scope.orders[0].orderId]
            }
          })
      }
    })
    .controller('OrderMammonController', function ($scope, $mdDialog, OrderService) {
      var self = this

      $scope.orderId = self.order.orderId
      $scope.customer = self.customer

      $scope.close = function () {
        $mdDialog.cancel()
      }

      $scope.load = function () {
        if ($scope.loading) {
          return
        }
        $scope.loading = true

        OrderService.getMammonInfo($scope.orderId)
          .then(function (result) {
            $scope.result = result
          })
          .finally(function () {
            $scope.loading = false
          })
      }

      $scope.load()
    })
    .controller('OrderRedirectController', function ($interval, $mdDialog, $q, $scope, AudioService, CustomerService, OrderService, StoreService) {
      var self = this

      $scope.orders = self.orders.slice().reverse()
      $scope.onlyResend = Boolean(self.onlyResend)
      $scope.step = $scope.onlyResend ? 'resend' : 'redirect'

      $scope.close = function (followUp) {
        self.soundnotify.cancel()

        var result
        if (followUp) {
          result = { followUp: followUp }
        }

        ($scope.submitted || result) ? $mdDialog.hide(result) : $mdDialog.cancel()
      }

      $scope.sendRedirectEmail = true
      $scope.createReport = true

      if (!$scope.onlyResend) {
        if ((self.customer || {}).customerId == null) {
          void 0
          return
        }

        CustomerService.getStores(self.customer.customerId).$promise
          .then(function (stores) {
            $scope.stores = stores
          })

        $scope.storeSelect = function (storeId, store) {
          $scope.store = store

          // load opening hours for selected store
          delete $scope.storeOph
          var params = {
            customerId: self.customer.customerId,
            storeId: storeId,
          }
          $scope.loadingOph = true
          StoreService.getOpeningHours(params)
            .then(function (oph) {
              $scope.storeOph = oph
            })
            .finally(function () {
              $scope.loadingOph = false
            })
        }

        $scope.redirect = function () {
          if ($scope.submittedRedirect) {
            return
          }
          $scope.submittedRedirect = true
          $scope.submitted = true

          var params = {
            orderId: $scope.orders[0].orderId,
            storeId: $scope.store.storeId,
            sendRedirectEmail: $scope.sendRedirectEmail || undefined,
            createReport: $scope.createReport || undefined,
          }

          $scope.loading = true
          OrderService.redirect(params)
            .then(function (result) {
              self.parseMagentoResult(result)
              $scope.result = {
                magento: result,
              }

              if (!result || !result.success) {
                return
              }
              $scope.step = 'resend'

              // set the new store
              if ($scope.orders[0].store.storeId !== $scope.store.storeId) {
                self.soundnotify.start()

                // save original store
                if ($scope.orders[0].origStore == null) {
                  $scope.orders[0].origStore = $scope.orders[0].store
                }

                // delete original store, if redirect back to it
                if ($scope.orders[0].origStore.storeId === $scope.storeId) {
                  delete $scope.orders[0].origStore
                }

                $scope.orders[0].store = $scope.store
              }
            })
            .finally(function () {
              $scope.loading = false
            })
        }
      }

      $scope.resend = function () {
        if ($scope.submittedResend) {
          return
        }
        $scope.submittedResend = true
        $scope.submitted = true
        self.soundnotify.cancel()

        var params = {
          createReport: ($scope.onlyResend && $scope.createReport) || undefined
        }

        $scope.loading = true
        if (!$scope.response) {
          $scope.response = {}
        }

        var deferred = $q.defer()
        deferred.resolve()

        // loop through orders and cancel them sequentially
        return $scope.orders.reduce(function (promise, order) {
          if (!$scope.response[order.orderId]) {
            $scope.response[order.orderId] = {}
          }
          $scope.response[order.orderId].resend = { init: true }

          // chain .then to last promise
          return promise.then(function () {
            $scope.response[order.orderId].resend = { loading: true }

            // set params
            params.orderId = order.orderId
            params.storeId = order.store.storeId

            // resend order
            return OrderService.resend(params)
              .then(function (result) {
                $scope.response[order.orderId].resend = result
              })
          })
        }, deferred.promise)
          .finally(function () {
            $scope.loading = false

            // show result for a single order
            if ($scope.orders.length === 1) {
              if (!$scope.result) {
                $scope.result = {}
              }
              $scope.result.resend = $scope.response[$scope.orders[0].orderId].resend
            }
          })
      }

      self.soundnotify = {
        sound: AudioService('sound/sc.mp3'),
        fnStartMs: 2 * 60 * 1000,
        fnPlayMs: 5000,
        interval: null,
        start: function () {
          self.soundnotify.interval = $interval(
            function () {
              self.soundnotify.interval = $interval(
                self.soundnotify.play,
                self.soundnotify.fnPlayMs
              )
            },
            self.soundnotify.fnStartMs,
            1
          )
        },
        play: function () {
          self.soundnotify.sound.play()
        },
        cancel: function () {
          $interval.cancel(self.soundnotify.interval)
          self.soundnotify.interval = null
        },
      }

      var WEEKDAYS = {
        monday: 'Montag',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        thursday: 'Donnerstag',
        friday: 'Freitag',
        saturday: 'Samstag',
        sunday: 'Sonntag',
      }

      self.parseMagentoResult = function (result) {
        if (result && result.data) {
          if (result.data.ophText) {
            for (var weekday in WEEKDAYS) {
              result.data.ophText = result.data.ophText.replace(new RegExp(weekday, 'g'), WEEKDAYS[weekday])
            }
          }
          var ophArray = ['ophText', 'ophTextToday']
          for (var i = 0, len = ophArray.length; i < len; i++) {
            var ophField = ophArray[i]
            if (result.data[ophField]) {
              result.data[ophField] = result.data[ophField].replace(/<br>$/, '').split('<br>')
            }
          }
        }
      }
    })
    .controller('OrderSendMailController', function ($scope, $mdDialog, OrderService) {
      var self = this

      $scope.orderId = self.order.orderId
      $scope.createReport = true

      $scope.close = function () {
        $mdDialog.cancel()
      }

      $scope.template = null

      var TEMPLATES = {
        sales_email_creditmemo_template: {
          display: 'Rückfrage zur Bestellung',
          data: {
            hideStorno: true,
          },
        },
      }

      $scope.templates = []
      Object.keys(TEMPLATES).forEach(function (type) {
        $scope.templates.push({ id: type, display: TEMPLATES[type].display })
      })
      if (Object.keys(TEMPLATES).length === 1) {
        $scope.template = Object.keys(TEMPLATES)[0]
      }

      $scope.sendMail = function () {
        if ($scope.loading) {
          return
        }

        var params = {
          orderId: $scope.orderId,
          templateId: $scope.template,
          templateName: TEMPLATES[$scope.template].display,
          createReport: $scope.createReport || undefined,
        }

        $scope.loading = true
        OrderService.sendMail(params, TEMPLATES[$scope.template].data)
          .then(function (result) {
            $scope.result = result
          })
          .finally(function () {
            $scope.loading = false
          })
      }
    })
})()
