;(function () {
  'use strict'

  var ID_SEPARATOR = '#'
  var AVATAR_BLANK = 'https://secure.gravatar.com/avatar/00000000000000000000000000000000.jpg?s=48&d=blank'

  angular
    .module('ottomatikStoreManager.administration')
    .controller('ReportingConfigController', ReportingConfigController)

  function ReportingConfigController(
    $mdDialog,
    $q,
    $scope,
    $transitions,
    preloader,
    CustomerService,
    Notification,
    ReportingService,
    SlackService,
    UserAdminService
  ) {
    var promises = []
    $scope.loading = true
    $scope.unsavedChanges = false

    $scope.customersById = {}
    $scope.storesById = {}

    promises.push(
      CustomerService.getCustomers({ all: true }).$promise.then(function (customers) {
        $scope.customers = customers

        customers.forEach(function (customer) {
          $scope.customersById[customer.customerId] = customer

          promises.push(
            CustomerService.getStoresWithGroups(customer.customerId).$promise.then(function (response) {
              customer.stores = response.stores
              customer.storegroups = response.groups

              customer.stores.forEach(function (store) {
                $scope.storesById[store.storeId] = store
              })
            })
          )
        })

        $q.all(promises).finally(function () {
          $scope.loading = false
        })
      })
    )

    $scope.sources = ReportingService.getSources().reduce(function (sources, source) {
      sources[source.id] = source.title
      return sources
    }, {})
    $scope.topics = ReportingService.getAllTopics()

    promises.push(
      ReportingService.getConfig().then(function (data) {
        $scope.data = data
        saveConfigState()

        function mapFn(user) {
          return {
            method: user.method,
            id: user.id.join(ID_SEPARATOR),
            name: user.name,
            info: user.info,
            image: AVATAR_BLANK,
          }
        }
        $scope.custom = (data.custom || []).map(mapFn)
        preloadImages($scope.custom)
      })
    )

    promises.push(
      UserAdminService.getUsers().then(function (users) {
        function mapFn(user) {
          return {
            id: ['user', user.userId].join(ID_SEPARATOR),
            name: user.member.name,
            info: user.member.email || user.member.telephone,
            image: AVATAR_BLANK,
          }
        }
        $scope.users = users.map(mapFn)
        preloadImages($scope.users)
      })
    )

    promises.push(
      SlackService.getUsers().then(function (users) {
        function mapFn(user) {
          return {
            id: ['slack', 'user', user.id].join(ID_SEPARATOR),
            name: user.real_name,
            info: '@' + user.name,
            image: user.profile.image_48,
          }
        }
        $scope.slackUsers = users.map(mapFn)
        preloadImages($scope.slackUsers)
      })
    )

    promises.push(
      SlackService.getUsergroups().then(function (usergroups) {
        function mapFn(usergroup) {
          return {
            id: ['slack', 'usergroup', usergroup.id].join(ID_SEPARATOR),
            name: usergroup.name,
            info: '@' + usergroup.handle,
            image: AVATAR_BLANK,
          }
        }
        $scope.slackUsergroups = usergroups.map(mapFn)
        preloadImages($scope.slackUsergroups)
      })
    )

    $scope.add = function (event, newData) {
      var data = angular.copy(newData)
      var ok = true

      // check methods
      data.methods = Object.keys(newData.methods)
        .filter(function (method) {
          return data.methods[method]
        })
        .reduce(function (methods, method) {
          methods[method] = true
          return methods
        }, {})

      if (Object.keys(data.methods).length == 0) {
        Notification.info('Mindestens eine Benachrichtigungsart muss ausgewählt sein!')
        ok = false
      }

      // check users
      data.users = newData.users.map(function (user) {
        return {
          id: user.id,
          name: user.name,
        }
      })

      if (data.users.length == 0) {
        Notification.info('Mindestens ein Benutzer muss ausgewählt sein!')
        ok = false
      }

      // iff `ok == true` add data to config
      if (ok) {
        $scope.data.config.push(data)
        checkUnsavedChanges()
        resetForm()
      }
    }

    $scope.delete = function (event, index) {
      $scope.data.config.splice(index, 1)
      checkUnsavedChanges()
    }

    $scope.update = function (event) {
      $scope.data.$update().then(function () {
        saveConfigState()
      })
    }

    $scope.customerSelect = function (customerId, customer) {
      $scope.customerSelected = customer
    }

    function resetForm() {
      $scope.new = {
        methods: {},
        users: [],
        customerId: undefined,
        storeId: undefined,
        topics: [],
        source: undefined,
      }
    }
    resetForm()

    $scope.querySearch = function (query) {
      if (!query) {
        return []
      }

      var result = []
      var customUsers = $scope.custom.filter(function (user) {
        return $scope.new.methods[user.method]
      })

      result = result.concat(customUsers.filter(createFilterFor(query)))
      if ($scope.new.methods.mail || $scope.new.methods.sms) {
        result = result.concat($scope.users.filter(createFilterFor(query)))
      }
      if ($scope.new.methods.slack) {
        result = result.concat($scope.slackUsergroups.filter(createFilterFor(query)))
        result = result.concat($scope.slackUsers.filter(createFilterFor(query)))
      }
      return result
    }

    function createFilterFor(query) {
      var lcQuery = query.toLowerCase()
      var fields = ['name', 'info']
      return function filterFn(contact) {
        var ok = false
        fields.every(function (field) {
          if (contact[field] && contact[field].toLowerCase().indexOf(lcQuery) !== -1) {
            ok = true
            return false
          }
          return true
        })
        return ok
      }
    }

    function preloadImages(entries) {
      var images = entries.map(getImage)
      preloader.preloadImages(images)

      function getImage(entry) {
        return entry.image
      }
    }

    function saveConfigState() {
      $scope.configState = angular.toJson($scope.data.config)
      checkUnsavedChanges()
    }

    function checkUnsavedChanges() {
      var state = angular.toJson($scope.data.config)
      $scope.unsavedChanges = !angular.equals($scope.configState, state)
    }

    var deregisterUnsavedChangesHook = $transitions.onBefore({}, function (transition) {
      if ($scope.unsavedChanges) {
        return $mdDialog.show(
          $mdDialog
            .confirm()
            .title('Nicht gespeicherte Änderungen')
            .htmlContent(
              'Sie haben ungespeicherte Änderungen. Sind Sie sicher, dass Sie diese Seite<br>' +
                'verlassen wollen? Ungespeicherte Änderungen gehen verloren.'
            )
            .ok('Änderungen verwerfen und diese Seite verlassen')
            .cancel('Auf dieser Seite bleiben')
        )
      }
    })

    $scope.$on('$destroy', function () {
      deregisterUnsavedChangesHook()
    })
  }
})()
