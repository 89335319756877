;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.hrm', []).config(function ($stateProvider, moduleRegisterProvider) {
    $stateProvider.state({
      abstract: true,
      name: 'hrm',
      url: '/hrm',
      templateUrl: 'src/hrm/views/layout.html',
      controller: 'HRMController',
    })

    $stateProvider.state({
      name: 'hrm.start',
      url: '/start',
      views: {
        '@hrm': {
          templateUrl: 'src/hrm/views/start.html',
        },
      },
    })

    $stateProvider.state({
      name: 'hrm.costs',
      url: '/costs',
      views: {
        'switcher@hrm': {
          templateUrl: 'src/hrm/views/week_switcher.html',
        },
        '@hrm': {
          templateUrl: 'src/hrm/views/costs.html',
        },
      },
    })

    $stateProvider.state({
      name: 'hrm.drivers',
      url: '/drivers',
      views: {
        '@hrm': {
          templateUrl: 'src/hrm/views/drivers.html',
          controller: 'HRMDriversController',
        },
      },
    })

    $stateProvider.state({
      name: 'hrm.roster',
      url: '/roster',
      views: {
        'switcher@hrm': {
          templateUrl: 'src/hrm/views/week_switcher.html',
        },
        '@hrm': {
          templateUrl: 'src/hrm/views/roster.html',
          controller: 'HRMRosterController',
        },
      },
    })

    $stateProvider.state({
      name: 'hrm.shifts',
      url: '/shifts',
      views: {
        'switcher@hrm': {
          templateUrl: 'src/hrm/views/week_switcher.html',
        },
        '@hrm': {
          templateUrl: 'src/hrm/views/shifts.html',
          controller: 'HRMShiftsController',
        },
      },
      params: {
        view: { value: undefined },
      },
    })

    $stateProvider.state({
      name: 'hrm.staff',
      url: '/staff',
      views: {
        'switcher@hrm': {
          templateUrl: 'src/hrm/views/week_switcher.html',
        },
        '@hrm': {
          templateUrl: 'src/hrm/views/staff.html',
          controller: 'HRMStaffController',
        },
      },
    })

    moduleRegisterProvider.add({
      name: 'Personalmanagement',
      materialIcon: 'groups',
      accessRoles: ['admin', 'hrm'],
      menu: [
        { name: 'Start', url: 'hrm.start' },
        { name: 'Fahreroptimierung', url: 'hrm.drivers' },
        { name: 'Mitarbeiterverwaltung', url: 'hrm.staff' },
        { name: 'Kennzahlen & Kosten', url: 'hrm.costs' },
        { name: 'Dienstplan', url: 'hrm.roster' },
        { name: 'Verfügbarkeit', url: 'hrm.shifts' },
      ],
    })
  })
})()
