;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.storesettings', []).config(function ($stateProvider, moduleRegisterProvider) {
    $stateProvider.state('storesettings', {
      url: '/storesettings',
      views: {
        '@': {
          templateUrl: 'src/storesettings/views/layout.html',
        },
        'colMain@storesettings': {
          templateUrl: 'src/storesettings/views/storesettings.html',
          controller: 'StoreSettingsController',
          controllerAs: 'settingCtrl',
        },
        'deliverytimes@storesettings': {
          templateUrl: 'src/storesettings/views/deliverytimes.html',
          controller: 'DeliveryTimesController',
          controllerAs: 'timesCtrl',
        },
        'openinghours@storesettings': {
          templateUrl: 'src/storesettings/views/openinghours.html',
          controller: 'OpeningHoursController',
          controllerAs: 'ophCtrl',
        },
        'preorderlimit@storesettings': {
          templateUrl: 'src/storesettings/views/preorderlimit.html',
          controller: 'PreorderLimitController',
          controllerAs: 'preorderCtrl',
        },
      },
    })

    moduleRegisterProvider.add({
      title: 'Einstellungen',
      url: 'storesettings',
      materialIcon: 'settings',
      arn: { 'Store Einstellungen': ['admin'] },
      accessRoles: ['admin', 'storesettings'],
    })
  })
})()
