(function () {
  'use strict'

  function OrderCustomerController ($scope, $element, $attrs) {
    this.$onChanges = function (changes) {
      if (changes.customer) {
        cleanUp(this.customer)
      }
    }

    function cleanUp (customer) {
      if (customer && Object.keys(customer).length && !customer.anonymized) {
        Object.keys(customer).forEach(function (key) {
          if (!angular.isString(customer[key])) {
            return
          }

          // remove too many escape backslashes
          customer[key] = customer[key].replace(/\\\\/g, '').replace(/\\(["/]|$)/g, '$1').trim()
        })

        // clear fullname as company or department
        var fullname = customer.firstname + ' ' + customer.lastname
        var checkFields = ['company', 'department']
        checkFields.forEach(function (field) {
          if (customer[field] && customer[field].toLowerCase() === fullname.toLowerCase()) {
            customer[field] = null
          }
        })

        // clear "Bitte wählen" as company
        if (customer.company === 'Bitte wählen') {
          customer.company = null
        }
      }
    }
  }

  angular
    .module('ottomatikStoreManager')
    .component('omOrderCustomer', {
      bindings: {
        customer: '<',
        noPhone: '@',
        noPhoneSpace: '@',
      },
      controller: OrderCustomerController,
      templateUrl: 'src/components/om-order-customer.html',
    })
})()
