;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.files').service('FileService', FileService)

  function FileService(CONFIG, $resource, helperService) {
    this.getFiles = getFiles
    this.download = download

    var File = $resource(CONFIG.API_URL + '/files/:path', null, { download: createDownloadAction() })

    function getFiles() {
      return File.query().$promise
    }

    function download(path) {
      return File.download({ path: path }).$promise
    }

    function createDownloadAction() {
      return {
        responseType: 'arraybuffer',
        transformResponse: helperService.data2download,
      }
    }
  }
})()
