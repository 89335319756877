(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager')
    .directive('omShowDecimals', ShowDecimals)

  function ShowDecimals () {
    return {
      link: link,
      require: 'ngModel',
      restrict: 'A',
      scope: {
        omShowDecimals: '@'
      },
    }

    function link (scope, element, attrs, ngModel) {
      element[0].step = 1 / Math.pow(10, scope.omShowDecimals)
      element[0].onchange = function () {
        if (this.value) {
          this.value = (+this.value).toFixed(scope.omShowDecimals)
        }
      }

      ngModel.$render = function () {
        if (ngModel.$modelValue != null) {
          element[0].value = ngModel.$modelValue.toFixed(scope.omShowDecimals)
        } else {
          element[0].value = ngModel.$modelValue || null
        }
      }
    }
  }
})()
