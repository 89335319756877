;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.storesettings').controller('StoreSettingsController', StoreSettingsController)

  function StoreSettingsController($scope, $timeout, CustomerService, UserService) {
    var $ctrl = this

    $ctrl.showCustomerSelect = UserService.hasRole(['admin', 'storesall'])
    $ctrl.showOpeningHours = UserService.hasRole(['admin', 'storesall'])
    $ctrl.showPreorderLimit = UserService.hasRole(['admin', 'preorderlimit'])

    function triggerLoad() {
      if ($ctrl.customer && $ctrl.store) {
        $timeout(() => {
          $scope.$broadcast('STORE_SETTINGS_LOAD', { customer: $ctrl.customer, store: $ctrl.store })
        })
      }
    }

    function initCustomerSelect() {
      CustomerService.getCustomers().$promise.then((customers) => {
        $ctrl.customers = customers.filter((customer) => customer.active)
      })

      $ctrl.customerSelect = (customerId, customer) => {
        $ctrl.customerId = customerId
        $ctrl.customer = customer
        if (customer) {
          initStoreSelect(customer)
        }
      }
    }

    function initStoreSelect(customer) {
      CustomerService.getStores(customer.customerId).$promise.then((stores) => {
        $ctrl.stores = stores
        if (stores.length == 1) {
          $ctrl.storeSelect(stores[0].storeId, stores[0])
        }
      })

      $ctrl.storeSelect = (storeId, store) => {
        var change = !angular.equals($ctrl.store, store)
        $ctrl.storeId = storeId
        $ctrl.store = store
        if (change) {
          triggerLoad()
        }
      }
    }

    if ($ctrl.showCustomerSelect) {
      initCustomerSelect()
    } else {
      $ctrl.customerId = UserService.getCustomerId()
      CustomerService.getCustomer($ctrl.customerId).$promise.then((customer) => {
        $ctrl.customer = customer
        initStoreSelect(customer)
      })
    }
  }
})()
