(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager')
    .component('omAccordion', {
      bindings: {},
      controller: AccordionController,
      template: '<ng-transclude></ng-transclude>',
      transclude: true,
    })
    .component('omAccordionPart', {
      bindings: {
        title: '@partTitle',
        sub: '@partSub',
        open: '<',
      },
      controller: AccordionPartController,
      require: {
        accordion: '^^omAccordion',
      },
      templateUrl: 'src/components/om-accordion-part.html',
      transclude: true,
    })

  function AccordionController () {
    var $ctrl = this
    var parts = []

    $ctrl.register = function (part) {
      var nextId = parts.length
      parts.push(part)
      return nextId
    }

    $ctrl.toggle = function (id) {
      for (var i = 0, len = parts.length; i < len; i++) {
        var part = parts[i]
        if (part.getId() === id) {
          part.toggle()
        } else {
          part.setOpen(false)
        }
      }
    }
  }

  function AccordionPartController ($element, $timeout) {
    var $ctrl = this
    var contentElement
    var id

    $ctrl.$onInit = function () {
      id = $ctrl.accordion.register(this)
    }

    $ctrl.$postLink = function () {
      $ctrl.setOpen(Boolean($ctrl.open), true)
    }

    $ctrl.getId = function () {
      return id
    }

    $ctrl.setOpen = function (open, initial) {
      $ctrl.open = open
      var contentHeight = getContentHeight(open)

      if ((open && initial) || contentHeight === 'auto') {
        // delay setting of height
        $timeout(function () {
          $ctrl.setOpen(open)
        }, 100)
        return
      }

      getContentElement().style.height = contentHeight
    }

    $ctrl.toggle = function (event) {
      if (event instanceof Event) {
        $ctrl.accordion.toggle(id)
      } else {
        $ctrl.setOpen(!$ctrl.open)
      }
    }

    function getContentElement () {
      if (!contentElement) {
        contentElement = angular.element($element[0].querySelector('.accordion-part-content'))[0]
      }
      return contentElement
    }

    function getContentHeight (open) {
      var height = 0
      if (open) {
        height = getContentElement().scrollHeight || 'auto'
      }
      return angular.isNumber(height) ? height + 'px' : height
    }
  }
})()
