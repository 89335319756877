;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.storesettings').controller('PreorderLimitController', PreorderLimitController)

  function PreorderLimitController($mdDialog, $scope, PreorderLimitService) {
    var $ctrl = this

    $scope.$on('STORE_SETTINGS_LOAD', (event, data) => {
      $ctrl.customer = data.customer
      $ctrl.store = data.store

      var params = {
        storeId: data.store.storeId,
      }

      $ctrl.loading = true
      PreorderLimitService.getSettings(params)
        .then((settings) => {
          $ctrl.settings = settings
        })
        .finally(() => {
          $ctrl.loading = false
        })
    })

    $ctrl.edit = (event) => {
      $mdDialog
        .show({
          templateUrl: 'src/storesettings/views/preorderlimit-dialog.html',
          targetEvent: event,
          controller: PreorderLimitFormController,
          controllerAs: 'formCtrl',
          locals: {
            store: $ctrl.store,
            settings: angular.copy($ctrl.settings),
          },
        })
        .then((settings) => {
          $ctrl.settings = settings
        })
    }

    $scope.$watch('preorderCtrl.settings', (settings) => {
      $ctrl.lookahead = 10

      if (!settings || settings.default) {
        delete $ctrl.preview
        return
      }

      var today = new Date()
      today.setHours(0, 0, 0, 0)
      var preview = {
        weekday: [],
        custom: [],
      }

      var indexCache = {}
      var dayFormat = moment.HTML5_FMT.DATE
      var day, intervals

      // iterate weekdays
      for (var d = 0; d < 10; d++) {
        day = moment(today).add(d, 'days')
        indexCache[day.format(dayFormat)] = d
        var weekday = settings.weekday[day.weekday()]
        intervals = []
        if (weekday.active) {
          intervals = weekday.intervals.filter((interval) => interval.from)
        }
        preview.weekday.push({
          date: day.toDate(),
          intervals: intervals,
        })
      }

      // iterate custom days
      for (var c = 0, cLen = settings.custom.length; c < cLen; c++) {
        var custom = settings.custom[c]
        day = moment(custom.date)
        var index = indexCache[day.format(dayFormat)]
        intervals = []
        if (custom.active) {
          intervals = custom.intervals.filter((interval) => interval.from)
        }
        if (index !== undefined) {
          preview.weekday[index].intervals = intervals
        } else {
          preview.custom.push({
            date: day.toDate(),
            intervals: intervals,
          })
        }
      }

      $ctrl.preview = preview
    })
  }

  function PreorderLimitFormController($mdDialog, store, settings) {
    'ngInject'

    var $ctrl = this

    $ctrl.settings = settings
    $ctrl.limitMin = 2
    $ctrl.today = new Date()

    $ctrl.cancel = () => {
      $mdDialog.cancel()
    }

    $ctrl.update = () => {
      $ctrl.loading = true
      $ctrl.settings
        .$update({ storeId: store.storeId })
        .then((settings) => {
          $mdDialog.hide(settings)
        })
        .finally(() => {
          $ctrl.loading = false
        })
      return true
    }

    $ctrl.addInterval = (property, index) => {
      $ctrl.settings[property][index].intervals.push({})
    }

    $ctrl.delInterval = (property, index, interval) => {
      $ctrl.settings[property][index].intervals.splice(interval, 1)
      if ($ctrl.settings[property][index].intervals.length === 0) {
        $ctrl.addInterval(property, index)
      }
    }

    $ctrl.addCustom = () => {
      var len = $ctrl.settings.custom.push({
        date: null,
        active: false,
        intervals: [],
      })
      $ctrl.addInterval('custom', len - 1)
    }

    $ctrl.delCustom = (index) => {
      $ctrl.settings.custom.splice(index, 1)
    }

    var dateFilterFns = {}
    $ctrl.dateFilter = (index) => {
      if (!dateFilterFns[index]) {
        dateFilterFns[index] = (date) => {
          for (var c = 0, cLen = $ctrl.settings.custom.length; c < cLen; c++) {
            if (index === c) {
              continue
            }
            var customDate = $ctrl.settings.custom[c].date
            if (!(customDate instanceof Date)) {
              customDate = new Date(customDate)
              customDate.setHours(0, 0, 0, 0)
            }
            if (customDate.getTime() === date.getTime()) {
              return false
            }
          }
          return true
        }
      }
      return dateFilterFns[index]
    }
  }
})()
