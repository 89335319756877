;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.statistics').controller('StatisticsTrendController', StatisticsTrendController)

  function StatisticsTrendController($filter, $scope, ChartService, StatisticService) {
    var $ctrl = this
    var deregistrators = []

    $ctrl.month = {
      init: function (customer) {
        this.from = undefined
        this.to = undefined
        var options = []

        var now = new Date()
        now.setDate(now.getDate() - 1)
        var date = new Date(customer.onlineSince)
        date.setDate(1)
        var intl = new Intl.DateTimeFormat('de-DE', { month: 'long', year: 'numeric' })

        do {
          options.push({
            id: date.getFullYear() * 100 + date.getMonth(),
            name: intl.format(date),
            firstDay: new Date(date),
            lastDay: new Date(date.getFullYear(), date.getMonth() + 1, 0),
          })

          date = new Date(date.setMonth(date.getMonth() + 1))
        } while (date < now)

        this.options = options
      },
      select: function (prop) {
        if (this.from && this.to && this.to.lastDay < this.from.firstDay) {
          this[prop == 'from' ? 'to' : 'from'] = undefined
        }
      },
    }

    $ctrl.load = () => {
      var params = {
        customerId: $scope.filter.customerId,
        storeId: $scope.filter.storeId,
        systemId: $ctrl.systemId,
        dateFrom: $filter('date')($ctrl.month.from.firstDay),
        dateTo: $filter('date')($ctrl.month.to.lastDay),
      }

      $ctrl.loading = true
      StatisticService.getTrend(params)
        .then(handleResponse)
        .finally(() => {
          $ctrl.loading = false
        })
    }

    function handleResponse(response) {
      var accumulation = []

      $ctrl.systems = Object.keys(response[0]).reduce((systems, key) => {
        if (key.includes('Bestellungen')) {
          systems.push(key.replace('Bestellungen', '').trim())
          accumulation.push(0)
        }
        return systems
      }, [])

      $ctrl.table = response.reduce((table, data) => {
        var date = new Date(data.Monat.slice(3), data.Monat.slice(0, 2) - 1, 1)
        table.push({
          id: date.getFullYear() * 100 + date.getMonth(),
          month: date,
          values: $ctrl.systems.reduce((values, system) => {
            var index = values.length
            var count = data['Bestellungen ' + system]
            var sales = data['Umsatz ' + system]

            accumulation[index] += sales

            values.push({
              system: system,
              count: count,
              sales: sales,
              average: sales / count,
              accumulation: accumulation[index],
            })
            return values
          }, []),
        })
        return table
      }, [])

      createChart()
    }

    function createChart() {
      var options = {
        backgroundColor: 'transparent',
        curveType: 'function',
        focusTarget: 'category',
        fontName: 'Roboto',
        fontSize: 14,
        hAxis: {
          format: 'MMMM yyyy',
          minorGridlines: { count: 0 },
        },
        height: 600,
        legend: {
          position: 'top',
          textStyle: { fontSize: 12 },
        },
        series: [],
        title: 'Umsatz [Linien] und Bestellungen [Säulen] pro Monat',
        titleTextStyle: {
          fontSize: 16,
        },
        vAxes: [
          {
            title: 'Bestellungen',
            minorGridlines: { count: 0 },
            viewWindow: { min: 0 },
          },
          {
            title: 'Umsatz (brutto)',
            minorGridlines: { count: 0 },
            viewWindow: { min: 0 },
          },
        ],
      }

      var data = new google.visualization.DataTable()

      data.addColumn('date', 'Monat')
      $ctrl.systems.forEach((system) => {
        data.addColumn('number', 'Bestellungen ' + system)
        data.addColumn('number', 'Umsatz ' + system)

        options.series.push(
          {
            color: ChartService.getColor(system, true),
            type: 'bars',
            targetAxisIndex: 0,
            visibleInLegend: false,
          },
          {
            color: ChartService.getColor(system),
            type: 'line',
            targetAxisIndex: 1,
            labelInLegend: system,
          }
        )
      })

      $ctrl.table.forEach((row) => {
        var cells = [{ v: row.month, f: $filter('date')(row.month, 'MMMM yyyy') }]
        row.values.forEach((values) => {
          cells.push({
            v: values.count,
            f: $filter('number')(values.count),
          })
          cells.push({
            v: values.sales,
            f: $filter('currency')(values.sales, $scope.filter.customer),
          })
        })
        data.addRow(cells)
      })

      $ctrl.chart = {
        type: 'ComboChart',
        data: data,
        options: options,
      }
    }

    deregistrators.push(
      $scope.$watch('filter.customer', function (customer) {
        $ctrl.systemId = undefined
        if (customer) {
          $ctrl.month.init(customer)
        }
      })
    )

    $scope.$on('$destroy', () => {
      deregistrators.forEach((fn) => fn())
    })
  }
})()
