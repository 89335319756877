;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.management')
    .constant('IP_CLIENT_URL', 'https://invoiceplane.ottomatik.de/index.php/clients/view/')
    .service('DebitorService', DebitorService)
    .controller('DebitorListController', DebitorListController)
    .controller('DebitorFormController', DebitorFormController)
    .controller('DebitorExportController', DebitorExportController)

  function DebitorService(CONFIG, $resource) {
    var service = this

    service.getAccountingDebitors = getAccountingDebitors
    service.getAccountingDebitor = getAccountingDebitor
    service.createAccountingDebitors = createAccountingDebitors

    service.getDebitors = getDebitors
    service.editDebitor = editDebitor
    service.getDebitorStores = getDebitorStores
    service.editDebitorStoreAssociation = editDebitorStoreAssociation

    // ACCOUNTING

    var ACCOUNTING_BASE_URL = CONFIG.API_URL + '/accounting/:customerId/debitors'
    var AccountingDebitor = $resource(ACCOUNTING_BASE_URL + '/:debitorId', { customerId: 0 })
    var AccountingDebitorCreate = $resource(
      ACCOUNTING_BASE_URL + '/create',
      { customerId: 0 },
      { create: { method: 'POST', isArray: true } }
    )

    function getAccountingDebitors(customerId) {
      var params = { customerId: customerId }
      return AccountingDebitor.query(params).$promise
    }

    function getAccountingDebitor(customerId, debitorId) {
      var params = { customerId: customerId, debitorId: debitorId || 0 }
      return AccountingDebitor.get(params).$promise
    }

    function createAccountingDebitors(customerId, debitors) {
      var params = { customerId: customerId }
      return AccountingDebitorCreate.create(params, debitors).$promise
    }

    // CUSTOMER

    var CUSTOMER_BASE_URL = CONFIG.API_URL + '/customers/:customerId/debitors'
    var Debitor = $resource(CUSTOMER_BASE_URL + '/:debitorId', { customerId: 0 })
    var DebitorStore = $resource(CUSTOMER_BASE_URL + '/:action/:debitorStoreId', { customerId: 0 })

    function getDebitors(customerId) {
      var params = { customerId: customerId }
      return Debitor.query(params).$promise
    }

    function editDebitor(customerId, debitorId, debitor) {
      var params = { customerId: customerId, debitorId: debitorId }
      return Debitor.save(params, debitor).$promise
    }

    function getDebitorStores(customerId) {
      var params = { customerId: customerId, action: 'stores' }
      return DebitorStore.query(params).$promise
    }

    function editDebitorStoreAssociation(customerId, debitorStoreId, dsa) {
      var params = { customerId: customerId, action: 'associations', debitorStoreId: debitorStoreId }
      return DebitorStore.save(params, dsa).$promise
    }
  }

  function DebitorListController(IP_CLIENT_URL, $scope, $state, CustomerService, DebitorService) {
    $scope.ipClientUrl = IP_CLIENT_URL

    $scope.customerSelect = function (customerId, customer) {
      $scope.customerId = customerId
      $scope.customer = customer
      $state.go('.', { customerId: customerId })
      loadDebitors(customerId)
    }

    $scope.save = function (dsa, accountingEnd) {
      if (!(accountingEnd instanceof Date)) {
        return false
      }
      var data = {
        accountingEnd: moment(accountingEnd).format('YYYY-MM-DD'),
      }
      DebitorService.editDebitorStoreAssociation($scope.customerId, dsa.debitorStoreId, data).then(function (response) {
        dsa.accountingEnd = response.accountingEnd
      })
      return true
    }

    $scope.searchDebitors = function (event) {
      var query = $scope.searchQuery.toLowerCase()
      if (!query) {
        $scope.filteredDebitors = $scope.debitors
        return
      }
      $scope.filteredDebitors = $scope.debitors.filter(function (debitor) {
        if (debitor.debitorId == query) {
          return true
        }
        if (debitor.clientId == query) {
          return true
        }
        for (var i = 0, len = debitor.debitorStoreAssociations.length; i < len; i++) {
          var store = debitor.debitorStoreAssociations[i].store
          if (store.storeId == query) {
            return true
          }
          if (store.name.toLowerCase().includes(query)) {
            return true
          }
          if (store.foreignIdent) {
            var foreignIdent = [store.foreignIdent.toLowerCase(), store.foreignIdent.toLowerCase().replace(' ', '')]
            if (foreignIdent.includes(query)) {
              return true
            }
          }
        }
        return false
      })
    }

    $scope.resetSearch = function (event) {
      $scope.searchQuery = ''
      $scope.searchDebitors()
    }

    function initCustomerSelect(selectId) {
      CustomerService.getCustomers().$promise.then(function (customers) {
        $scope.customers = customers
        $scope.customerSelect(selectId)
      })
    }

    function loadDebitors(customerId) {
      $scope.searchQuery = ''
      if (!customerId) {
        delete $scope.debitors
        return
      }
      DebitorService.getAccountingDebitors(customerId).then(function (response) {
        $scope.debitors = response
        $scope.searchDebitors()
      })
    }

    initCustomerSelect($state.params.customerId)
  }

  function DebitorFormController($scope, $state, CustomerService, DebitorService) {
    $scope.debitorSelect = function (debitor) {
      $scope.debitor = debitor
    }

    $scope.storeSelect = function (storeId, store) {
      $scope.store = store
    }

    $scope.addDSA = function (event, store, accountingBegin) {
      accountingBegin = moment(accountingBegin)
      if (!accountingBegin.isValid()) {
        return
      }
      var index = $scope.associations.findIndex(function (dsa) {
        return dsa.store.storeId == store.storeId
      })
      if (index == -1) {
        index = $scope.associations.push({ store: store }) - 1
      }
      $scope.associations[index].accountingBegin = accountingBegin.format('YYYY-MM-DD')

      // reset form
      delete $scope.store
      delete $scope.storeId
      delete $scope.accountingBegin
      $scope.newDSA.$setPristine()
      $scope.newDSA.$setUntouched()
    }

    $scope.delDSA = function (event, storeId) {
      var index = $scope.associations.findIndex(function (dsa) {
        return dsa.store.storeId == storeId
      })
      if (index >= 0) {
        $scope.associations.splice(index, 1)
      }
    }

    $scope.associations = []
    $scope.save = function (event) {
      var data = {
        debitorStoreAssociations: $scope.associations.map(function (dsa) {
          return {
            storeId: dsa.store.storeId,
            accountingBegin: dsa.accountingBegin,
          }
        }),
        foreignIdent: $scope.debitor.foreignIdent,
      }
      DebitorService.editDebitor($scope.customerId, $scope.debitor.debitorId, data).then(function () {
        $state.go('^', null, { reload: true })
      })
    }

    function loadDebitor(debitorId) {
      $scope.debitor = { debitorId: debitorId }
      DebitorService.getAccountingDebitor($scope.customerId, debitorId).then(function (response) {
        $scope.debitorSelect(response)
      })
    }

    function loadDebitors() {
      DebitorService.getAccountingDebitors($scope.customerId).then(function (response) {
        $scope.debitors = response
      })
    }

    function loadStores() {
      DebitorService.getDebitorStores($scope.customerId).then(function (response) {
        $scope.stores = response
      })
    }

    $scope.customerId = $state.params.customerId
    if ($state.params.debitorId) {
      loadDebitor($state.params.debitorId)
    } else {
      $scope.isNew = true
      loadDebitors()
    }
    loadStores()
  }

  function DebitorExportController(IP_CLIENT_URL, $filter, $mdDialog, $scope, $state, CustomerService, DebitorService) {
    $scope.accountingType = 'all'
    $scope.creationMode = 'single'

    $scope.storeSelect = function (storeId, store) {
      $scope.store = store
      store.accountingBegin = new Date()
    }

    $scope.save = function (event) {
      var confirmTitle = 'Debitoren in InvoicePlane anlegen'
      var confirmText = 'Daten jetzt an InvoicePlane senden?'
      var confirm = $mdDialog.confirm().title(confirmTitle).textContent(confirmText).ok('Ja').cancel('Nein')
      confirm._options.targetEvent = event
      $mdDialog.show(confirm).then(createAccountingDebitors)
    }

    function loadCustomer() {
      CustomerService.getCustomer($scope.customerId).$promise.then(function (response) {
        $scope.customer = response
      })
    }

    function loadStores() {
      CustomerService.getStores($scope.customerId).$promise.then(function (response) {
        $scope.stores = response
      })
    }

    function createAccountingDebitors() {
      var data = {
        accountingType: $scope.accountingType,
        creationMode: $scope.creationMode,
      }

      if (data.accountingType == 'all') {
        data.debitors = [createDebitorFromCustomer($scope.customer)]
      } else {
        if (data.creationMode == 'all') {
          data.debitors = $scope.stores.map(createDebitorFromStore)
        } else {
          data.debitors = [createDebitorFromStore($scope.store)]
        }
      }

      DebitorService.createAccountingDebitors($scope.customerId, data).then(function (response) {
        var alertTitle = 'Debitoren in InvoicePlane anlegen'
        var alertHTML = 'Folgende Debitoren wurden angelegt: <ul>'
        response.forEach(function (debitor) {
          alertHTML += '<li>'
          alertHTML += 'Debitor&nbsp;' + debitor.debitorId + ' '
          alertHTML += '<small class="muted">IP#&nbsp;'
          alertHTML += '<a target="_blank" href="' + IP_CLIENT_URL + debitor.clientId + '">' + debitor.clientId + '</a>'
          alertHTML += '</small>'
          alertHTML += '</li>'
        })
        alertHTML += '</ul>'
        var alert = $mdDialog.alert().title(alertTitle).htmlContent(alertHTML).ok('Okay')
        $mdDialog.show(alert).then(function () {
          $state.go('^')
        })
      })
    }

    function createDebitorFromCustomer(customer) {
      return {
        client_active: 1,
        client_name: customer.firm,
        client_address_1: customer.contactName ? customer.contactName : customer.street,
        client_address_2: customer.contactName ? customer.street : '',
        client_zip: customer.zipcode,
        client_city: customer.city,
        client_state: '',
        client_country: 'DE',
        client_phone: $filter('tel')(customer.telephone),
        client_email: customer.email,
        client_custom_ansprechpartner: '',
        client_custom_storeident: '',
      }
    }

    function createDebitorFromStore(store) {
      return {
        storeId: store.storeId,
        accountingBegin: store.accountingBegin ? moment(store.accountingBegin).format('YYYY-MM-DD') : null,
        client_active: 1,
        client_name: store.name,
        client_address_1: store.contactName ? store.contactName : store.street,
        client_address_2: store.contactName ? store.street : '',
        client_zip: store.zip,
        client_city: store.city,
        client_state: $filter('state')(store.state),
        client_country: 'DE',
        client_phone: $filter('tel')(store.phone),
        client_email: store.emailGeneral,
        client_custom_ansprechpartner: store.contactName ? store.contactName : '',
        client_custom_storeident: store.foreignIdent ? store.foreignIdent.replace(' ', '') : '',
      }
    }

    $scope.customerId = $state.params.customerId
    loadCustomer()
    loadStores()
  }
})()
