(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager')
    .component('omSpinner', {
      template: '<div class="spinner" ng-show="$ctrl.showCount > 0" md-whiteframe="24"></div>',
      bindings: {
        name: '@',
      },
      controller: function SpinnerCtrl (spinnerService) {
        var self = this

        self.$onInit = function () {
          self.showCount = 0
          spinnerService.register({
            name: self.name,
            hide: function () {
              if (self.showCount) {
                self.showCount--
              }
            },
            show: function () {
              self.showCount++
            },
          })
        }

        self.$onDestroy = function () {
          spinnerService.unregister(self.name)
        }
      },
    })
    .factory('spinnerService', function SpinnerService () {
      var neededProps = {
        name: 'string',
        hide: 'function',
        show: 'function',
      }
      var spinners = {}
      return {
        register: function (spinner) {
          for (var prop in neededProps) {
            if (!Object.prototype.hasOwnProperty.call(spinner, prop) || typeof spinner[prop] !== neededProps[prop]) {
              throw new Error('Spinner-Objekte müssen die Eigenschaft "' + prop + '" (' + neededProps[prop] + ') definieren.')
            }
          }
          if (spinners[spinner.name]) {
            throw new Error('Ein Spinner mit dem Namen "' + spinner.name + '" wurde bereits registriert.')
          }
          spinners[spinner.name] = spinner
        },
        unregister: function (name) {
          delete spinners[name]
        },
        hide: function (name) {
          var spinner = spinners[name]
          if (spinner) {
            spinner.hide()
          }
        },
        show: function (name) {
          var spinner = spinners[name]
          if (spinner) {
            spinner.show()
          }
        },
      }
    })
})()
