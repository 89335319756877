'use strict';

/**
 * Dependencies: ServicecenterService
 *
 * Usage:
 * As element: <om-toolbar></om-toolbar>
 */
(function () {
  // define the om-toolbar module
  angular.module('om-toolbar', ['om-toolbar-templates']).directive('omToolbar', function () {
    function Controller($scope, $interval, AudioService, ServicecenterService, LocalStorageService) {
      var self = this;
      self.soundnotify = {};
      self.timerbutton = {};
      self.ordertables = [];

      // ########## SOUNDNOTIFY ##########

      self.soundnotify.selectorArray = [
        'om-ordertable section:not(.mute) om-servicecenterstatus .scstatus-attention', // om-servicecenterstatus attention orders
        'om-ordertable section:not(.mute) .messages .error', // om-ordertable error messages
      ];
      self.soundnotify.sound = AudioService('sound/sc.mp3');
      self.soundnotify.fnCheckMs = 1000;
      self.soundnotify.fnPlayMs = 5000;

      /* define controller methods */

      self.soundnotify.init = function () {
        self.soundnotify.allUnmuted = true;
        self.soundnotify.allMuted = true;
        self.soundnotify.sound_is_playing = false;
        self.soundnotify.check_interval = $interval(self.soundnotify.fnCheck, self.soundnotify.fnCheckMs);
      };

      self.soundnotify.muteAll = function (state) {
        angular.forEach(self.ordertables, function (ordertable) {
          ordertable.muted = state;
          if (state) {
            LocalStorageService.set('ordertable_'+ordertable.customer.customerId+'_sound_muted', 'true');
          } else {
            LocalStorageService.remove('ordertable_'+ordertable.customer.customerId+'_sound_muted');
          }
        });
      };

      self.soundnotify.fnCheck = function () {
        if (!self.soundnotify.allMuted && document.querySelector(self.soundnotify.selectorArray.join()) !== null) {
          if (!self.soundnotify.sound_is_playing) {
            self.soundnotify.fnPlay();
            self.soundnotify.sound_interval = $interval(self.soundnotify.fnPlay, self.soundnotify.fnPlayMs);
            self.soundnotify.sound_is_playing = true;
          }
        } else {
          if (self.soundnotify.sound_is_playing) {
            $interval.cancel(self.soundnotify.sound_interval);
            self.soundnotify.sound_is_playing = false;
          }
        }
      };

      self.soundnotify.fnPlay = function () {
        self.soundnotify.sound.play();
      };

      // ########## TIMERBUTTON ##########

      self.timerbutton.init = function () {
        self.timerbutton.allEnabled = true;
        self.timerbutton.allDisabled = true;
      };

      self.timerbutton.reloadCountdownEnableAll = function () {
        $scope.$broadcast('RELOADCOUNTDOWNENABLEALL');
      };

      self.timerbutton.reloadCountdownDisableAll = function () {
        $scope.$broadcast('RELOADCOUNTDOWNDISABLEALL');
      };

      /* define scope functions */

      $scope.registerOrdertable = function (ordertable) {
        self.ordertables.push(ordertable);

        var ordertable_sound_muted = !!LocalStorageService.get('ordertable_'+ordertable.customer.customerId+'_sound_muted', false);
        self.soundnotify.allUnmuted = self.soundnotify.allUnmuted && !ordertable_sound_muted;
        self.soundnotify.allMuted = self.soundnotify.allMuted && ordertable_sound_muted;

        self.timerbutton.allEnabled = self.timerbutton.allEnabled && ordertable.reloadCountdown.enabled;
        self.timerbutton.allDisabled = self.timerbutton.allDisabled && !ordertable.reloadCountdown.enabled;

        return ordertable_sound_muted;
      };

      self.unbindWatchGroup = $scope.$watchGroup([
        /* SOUNDNOTIFY */
        function () {
          var allUnmuted = true;
          var allMuted = true;
          angular.forEach(self.ordertables, function (ordertable) {
            if (ordertable.muted) {
              allUnmuted = false;
              if (!LocalStorageService.get('ordertable_'+ordertable.customer.customerId+'_sound_muted')) {
                LocalStorageService.set('ordertable_'+ordertable.customer.customerId+'_sound_muted', 'true');
              }
            } else {
              allMuted = false;
              if (LocalStorageService.get('ordertable_'+ordertable.customer.customerId+'_sound_muted')) {
                LocalStorageService.remove('ordertable_'+ordertable.customer.customerId+'_sound_muted');
              }
            }
          });
          return allMuted ? 0 : allUnmuted ? 2 : 1;
        },
        /* TIMERBUTTON */
        function () {
          var allEnabled = true;
          var allDisabled = true;
          angular.forEach(self.ordertables, function (ordertable) {
            if (ordertable.reloadCountdown.enabled) {
              allDisabled = false;
            } else {
              allEnabled = false;
            }
          });
          return allDisabled ? 0 : allEnabled ? 2 : 1;
        }
      ], function (newValues, oldValues) {
        /* SOUNDNOTIFY */
        if (newValues[0] !== oldValues[0]) {
          switch (newValues[0]) {
            case 0:
              self.soundnotify.allUnmuted = false;
              self.soundnotify.allMuted = true;
              break;
            case 1:
              self.soundnotify.allUnmuted = false;
              self.soundnotify.allMuted = false;
              break;
            case 2:
              self.soundnotify.allUnmuted = true;
              self.soundnotify.allMuted = false;
              break;
            default: break;
          }
        }
        /* TIMERBUTTON */
        if (newValues[1] !== oldValues[1]) {
          switch (newValues[1]) {
            case 0:
              self.timerbutton.allEnabled = false;
              self.timerbutton.allDisabled = true;
              break;
            case 1:
              self.timerbutton.allEnabled = false;
              self.timerbutton.allDisabled = false;
              break;
            case 2:
              self.timerbutton.allEnabled = true;
              self.timerbutton.allDisabled = false;
              break;
            default: break;
          }
        }
      });

      $scope.$on('$destroy', function () {
        $interval.cancel(self.soundnotify.check_interval);
        if (self.soundnotify.sound_is_playing) {
          $interval.cancel(self.soundnotify.sound_interval);
        }
        self.unbindWatchGroup();
        self = {};
      });

      // initial request
      self.soundnotify.init();
      self.timerbutton.init();
    }

    return {
      restrict: 'E',
      templateUrl: 'om-toolbar.html',
      bindToController: true,
      controller: Controller,
      controllerAs: 'omToolbar',
      compile: function (element, attrs) {
        element.addClass('om-toolbar');
      }
    };
  });

  angular.module('om-toolbar-templates', []).run(['$templateCache', function ($templateCache) {
    $templateCache.put('om-toolbar.html',
      // SOUNDNOTIFY
      '<div class="soundnotify" style="float: left;">' +
        '<div class="icons">' +
          '<md-icon ng-hide="omToolbar.soundnotify.allUnmuted" md-colors="{color: \'warn\'}">volume_off</md-icon>' +
          '<md-icon ng-hide="omToolbar.soundnotify.allMuted" md-colors="{color: \'accent\'}">volume_up</md-icon>' +
        '</div>' +
        '<div class="turn-on" ng-hide="omToolbar.soundnotify.allUnmuted">' +
          '<md-button class="md-primary" ng-click="omToolbar.soundnotify.muteAll(false)" aria-label="Hinweiston global aktivieren" title="Hinweiston global aktivieren">AN</md-button>' +
        '</div>' +
        '<div class="turn-off" ng-hide="omToolbar.soundnotify.allMuted">' +
          '<md-button class="md-primary" ng-click="omToolbar.soundnotify.muteAll(true)" aria-label="Hinweiston global deaktivieren" title="Hinweiston global deaktivieren">AUS</md-button>' +
        '</div>' +
      '</div>' +
      // TIMERBUTTON
      '<div class="timerbutton" style="float: right;">' +
        '<div class="icons">' +
          '<md-icon ng-hide="omToolbar.timerbutton.allEnabled" md-colors="{color: \'warn\'}">alarm_off</md-icon>' +
          '<md-icon ng-hide="omToolbar.timerbutton.allDisabled" md-colors="{color: \'accent\'}">alarm</md-icon>' +
        '</div>' +
        '<div class="turn-on" ng-hide="omToolbar.timerbutton.allEnabled">' +
          '<md-button class="md-primary" ng-click="omToolbar.timerbutton.reloadCountdownEnableAll()" aria-label="Alle Timer aktivieren" title="Alle Timer aktivieren">AN</md-button>' +
        '</div>' +
        '<div class="turn-off" ng-hide="omToolbar.timerbutton.allDisabled">' +
          '<md-button class="md-primary" ng-click="omToolbar.timerbutton.reloadCountdownDisableAll()" aria-label="Alle Timer deaktivieren" title="Alle Timer deaktivieren">AUS</md-button>' +
        '</div>' +
      '</div>'
    );
  }]);
})();
