/**
 * Dependencies: ServicecenterService
 *
 * Usage:
 * As element: <om-otsdetail om-orderid="order.orderId" om-otsdetail="order.otsdetail"></om-otsdetail>
 *
 * Attributes:
 * om-orderid: order ID for which the OTS detail should be displayed
 * om-otsdetail: OTS detail data property
 */
(function () {
  'use strinct'

  function Controller ($attrs, $element, $mdSidenav, $rootScope, $timeout, ServicecenterService) {
    var $ctrl = this
    $ctrl.loading = false
    $ctrl.lastOrderId = undefined
    $ctrl.highlightCodes = [200, 290, 499]
    $ctrl.toggleBtnLabelShow = 'Infos aufklappen'
    $ctrl.toggleBtnLabelHide = 'Infos zuklappen'

    $ctrl.$onInit = function () {
      $element.addClass('om-otsdetail')
      $ctrl.onlyHighlight = 'omOnlyHighlight' in $attrs

      $ctrl.info = {
        type: 'info',
        msg: 'Lädt...',
      }
      $ctrl.infoMore = false
      $ctrl.detail = undefined
      if ($ctrl.lastOrderId !== $ctrl.orderId) {
        $ctrl.lastOrderId = $ctrl.orderId
        $ctrl.toggleBtnStatus = false
        $ctrl.toggleBtnLabel = $ctrl.toggleBtnLabelShow
        setContentMinHeight(false)
      }
    }

    $ctrl.loadDetail = function () {
      if ($ctrl.loading) {
        return
      }
      if ($ctrl.orderId == null) {
        $timeout($ctrl.loadDetail, 1000)
        return
      }
      $ctrl.loading = true
      ServicecenterService.getOtsDetail($ctrl.orderId).then(
        function (data) {
          $ctrl.info = undefined
          processJson(data)
        },
        function () {
          $ctrl.$onInit()
          $ctrl.info = {
            type: 'warning',
            msg: 'Fehler beim Laden!',
          }
        }
      ).finally(
        function () {
          $ctrl.loading = false
          setContentMinHeight(false)
        }
      )
    }

    $ctrl.btnReload = function () {
      setContentMinHeight()
      $ctrl.$onInit()
      $ctrl.loadDetail()
    }

    $ctrl.toggleAllDetails = function () {
      $ctrl.toggleBtnStatus = !$ctrl.toggleBtnStatus
      if ($ctrl.toggleBtnStatus) {
        $ctrl.toggleBtnLabel = $ctrl.toggleBtnLabelHide
      } else {
        $ctrl.toggleBtnLabel = $ctrl.toggleBtnLabelShow
      }
      angular.forEach($ctrl.detail, function (entry) {
        entry.toggleStatus = $ctrl.toggleBtnStatus
      })
    }

    function processJson (json) {
      if (!('details' in json) || json.details.length === 0) {
        $ctrl.info = {
          type: 'info',
          msg: 'Keine Daten vorhanden!',
        }
        $ctrl.detail = undefined
        return
      }
      $ctrl.info = undefined
      $ctrl.detail = []

      angular.forEach(json.details, function (obj) {
        var entry = {}
        entry.timestamp = ts2iso(obj.timestamp)
        entry.code = obj.statuscode
        entry.color = color4Code(entry.code)
        entry.highlight = $ctrl.highlightCodes.includes(entry.code)
        entry.status = obj.status
        processInfo(entry, obj.statusinfo)
        entry.toggleStatus = $ctrl.toggleBtnStatus
        if (!$ctrl.onlyHighlight || entry.color) {
          this.push(entry)
        }
      }, $ctrl.detail)

      if ($ctrl.detail.length === 0) {
        $ctrl.info = {
          type: 'info',
          msg: 'Keine Daten vorhanden!',
        }
        $ctrl.detail = undefined
      }
    }

    function ts2iso (timestamp) {
      // yyyy-MM-dd HH:mm:ss => ISO 8601
      var regexp = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/
      var m
      if ((m = regexp.exec(timestamp)) !== null && m.length === 7) {
        return new Date(m[1], parseInt(m[2]) - 1, m[3], m[4], m[5], m[6]).toISOString()
      }
      // fallback
      return new Date(Date.parse(timestamp)).toISOString()
    }

    function color4Code (code) {
      if ((code >= 200 && code <= 299) || (code >= 600 && code <= 699)) {
        return 'success'
      } else if (code >= 400 && code <= 499) {
        return 'warning'
      }
      return undefined
    }

    function processInfo (entry, info) {
      try {
        // try to parse json
        var data = angular.fromJson(info)
        if (Object.keys(data).length === 0) {
          entry.infoStr = '—'
        } else if (Object.keys(data).length === 1) {
          entry.infoObj = data
        } else if (Object.keys(data).length >= 2) {
          // cut off first property
          // data:{firstKey: firstVal, key: val, ...} => infoObj:{firstKey: firstVal} & infoObjMore:{key: val, ...}
          var firstKey = Object.keys(data)[0]
          var firstVal = data[firstKey]
          entry.infoObj = {}
          entry.infoObj[firstKey] = firstVal
          delete data[firstKey]
          entry.infoObjMore = data
          $ctrl.infoMore = true
        }
      } catch (e) {
        // else return as is
        if (info === '-') {
          info = '—'
        }
        entry.infoStr = info
      }
    }

    function setContentMinHeight (set) {
      var content = document.querySelector('.om-otsdetail-content')
      if (set !== undefined && !set) {
        // reset min-height
        content.style.minHeight = ''
      } else {
        // set min-height
        content.style.minHeight = content.clientHeight + 'px'
      }
    }

    $rootScope.$watch('isSidenavOrderDetailsOpen', function (isOpen) {
      if (isOpen) {
        $ctrl.$onInit()
        $ctrl.loadDetail()
      }
    })
  }

  angular
    .module('om-otsdetail', [])
    .component('omOtsdetail', {
      bindings: {
        orderId: '<omOrderid',
        detail: '=omOtsdetail',
      },
      controller: Controller,
      templateUrl: 'src/components/om-otsdetail.html',
    })
})()
