;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.tutorials', [])
    .config(function ($sceDelegateProvider) {
      $sceDelegateProvider.resourceUrlWhitelist(['self', 'https://player.vimeo.com/**'])
    })
    .config(function ($stateRegistryProvider, moduleRegisterProvider) {
      $stateRegistryProvider.register({
        name: 'tutorials',
        views: {
          '@': {
            templateUrl: 'src/tutorials/views/layout.html',
          },
        },
      })

      $stateRegistryProvider.register({
        name: 'tutorials.admin',
        url: '/tutorials/admin',
        views: {
          '': {
            templateUrl: 'src/tutorials/views/admin.html',
            controller: 'TutorialAdminController',
            controllerAs: 'vm',
          },
        },
      })

      moduleRegisterProvider.add({
        name: 'Tutorial-Videos',
        materialIcon: 'play_circle',
        url: 'tutorials.admin',
        accessRoles: ['admin', 'tutorials_admin'],
      })
    })
})()
