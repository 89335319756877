;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.administration').controller('OejwtController', OejwtController)

  function OejwtController(CONFIG, $mdDialog, $resource, $scope) {
    var AuthPubKey = $resource(CONFIG.BASE_URL + '/jwt/oejwt/:id', { id: '@id' })
    var AuthPasswords = $resource(CONFIG.BASE_URL + '/jwt/oejwt/passwords')

    $scope.load = function () {
      if ($scope.loading) {
        return
      }
      $scope.loading = true

      var promise = AuthPubKey.query().$promise
      promise
        .then(function (result) {
          $scope.scopeRoutes = result.shift()
          $scope.list = result.reduce(function (list, key) {
            if (!list[key.scope]) {
              list[key.scope] = []
            }
            list[key.scope].push(key)
            return list
          }, {})
        })
        .finally(function () {
          $scope.loading = false
        })
      return promise
    }

    $scope.delete = function (key) {
      $mdDialog
        .show(
          $mdDialog
            .confirm()
            .title('Schlüssel löschen?')
            .textContent(
              'Möchtest du den Schlüssel ' +
                (key.comment ? '"' + key.comment + '" ' : '') +
                '(' +
                key.scope +
                '/' +
                key.scopeId +
                ') löschen?'
            )
            .ok('Ja, endgültig löschen')
            .cancel('Nein, abbrechen')
        )
        .then(function () {
          key.$delete().then(function () {
            var index = $scope.list[key.scope].findIndex(function (k) {
              return k.id === key.id
            })
            if (index >= 0) {
              $scope.list[key.scope].splice(index, 1)
            }
          })
        })
    }

    $scope.dialogPasswords = function (event) {
      $mdDialog.show({
        templateUrl: 'src/administration/views/oejwt-dialog-passwords.html',
        targetEvent: event,
        controller: function ($mdDialog, $scope) {
          var dialog = this
          $scope.add = function () {
            dialog.passwords.value.push({
              pw: '',
              comment: '',
              edit: true,
              new: true,
            })
          }
          $scope.edit = function (password, turnOn) {
            if (turnOn) {
              password.bak = {
                pw: password.pw,
                comment: password.comment,
              }
            } else {
              password.pw = password.bak.pw
              password.comment = password.bak.comment
              delete password.bak
            }
            password.edit = turnOn
          }
          $scope.remove = function (index) {
            dialog.passwords.value.splice(index, 1)
          }
          $scope.close = function () {
            $mdDialog.cancel()
          }
          $scope.save = function () {
            $scope.loading = true
            dialog.passwords
              .$save()
              .then($mdDialog.hide)
              .finally(function () {
                $scope.loading = false
              })
          }
        },
        bindToController: true,
        resolve: {
          passwords: function () {
            return AuthPasswords.get().$promise
          },
        },
        controllerAs: '$ctrl',
      })
    }

    $scope.load()
  }
})()
