(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager')
    .directive('fileModel', FileModel)

  function FileModel ($parse) {
    var directive = {
      link: link,
      restrict: 'A',
      scope: true,
    }
    return directive

    function link (scope, element, attrs) {
      element.addClass('ng-hide')
      element.on('change', onChange)
      scope.$on('$destroy', function () {
        element.off('change', onChange)
      })

      function onChange (event) {
        var files = event.target.files
        for (var i = 0, len = files.length, file; i < len; i++) {
          file = files[i]
          file.src = URL.createObjectURL(file)
        }
        if (!attrs.multiple) {
          files = files[0]
        }
        $parse(attrs.fileModel).assign(scope, files)
        scope.$apply()
      }
    }
  }
})()
