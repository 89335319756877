;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.transmissions').service('TransmissionsService', TransmissionsService)

  function TransmissionsService(CONFIG, $resource, helperService) {
    var Transmissions = $resource(
      CONFIG.API_URL + '/orders/:storeId/transmissions',
      { storeId: 0 },
      {
        get: {
          hideGlobalSpinner: true,
          transformResponse: helperService.appendTransform(transformResponse),
        },
      }
    )

    this.getTransmissions = getTransmissions
    this.checkForNewTransmissions = checkForNewTransmissions
    this.setState = setState

    function getTransmissions(params) {
      return Transmissions.get(params).$promise
    }
    function checkForNewTransmissions(params) {
      params.checkNew = 1
      return Transmissions.get(params).$promise
    }
    function setState(params, data) {
      return Transmissions.save(params, data).$promise
    }

    function transformResponse(response) {
      if (response.payload && angular.isObject(response.payload)) {
        for (var category in response.payload) {
          if (angular.isArray(response.payload[category])) {
            helperService.orderTransformation('order')(response.payload[category])
          }
        }
      }
      return response
    }
  }
})()
