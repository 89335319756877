(function () {
  'use strict'

  var DEFAULT_LIMIT_OPTIONS = [20, 50, 100]
  var STORAGE_KEY = 'pagination.limit'

  angular
    .module('ottomatikStoreManager')
    .component('omPagination', {
      templateUrl: 'src/components/om-pagination.html',
      bindings: {
        page: '<',
        total: '<',
        onChange: '&',
        limitOptions: '<?',
        distribution: '<?',
        disabled: '<?ngDisabled',
      },
      controller: PaginationController,
    })

  function PaginationController ($element, LocalStorageService, $state) {
    var $ctrl = this

    var storageKey = STORAGE_KEY + '[' + $state.current.name + ']'

    $ctrl.$onInit = function () {
      $element.addClass('nobox')

      if (!$ctrl.limit.options) {
        $ctrl.limit.update(DEFAULT_LIMIT_OPTIONS)
      }
    }

    $ctrl.$onChanges = function (changesObj) {
      var pagesUpdate = {}
      if (changesObj.page) {
        pagesUpdate.page = changesObj.page.currentValue
      }
      if (changesObj.total) {
        pagesUpdate.total = changesObj.total.currentValue
      }
      if (Object.keys(pagesUpdate).length > 0) {
        $ctrl.pages.update(pagesUpdate)
      }

      if (
        changesObj.limitOptions &&
        angular.isArray(changesObj.limitOptions.currentValue) &&
        changesObj.limitOptions.currentValue.length > 0
      ) {
        $ctrl.limit.update(changesObj.limitOptions.currentValue)
      }
    }

    $ctrl.notifyChange = function () {
      if ($ctrl.limit.value === undefined) {
        return
      }
      $ctrl.onChange({
        pagination: {
          limit: $ctrl.limit.value,
          page: $ctrl.pages.value,
        },
      })
      $ctrl.pages.input = $ctrl.pages.value
    }

    $ctrl.limit = {
      value: undefined,
      options: undefined,
      change: function () {
        LocalStorageService.set(storageKey, this.value)
        $ctrl.pages.update({
          limit: {
            old: this.prev,
            new: this.value,
          },
        })
        this.prev = this.value
      },
      update: function (options) {
        this.options = options
        var _limit = LocalStorageService.get(storageKey, options[0])
        if (!options.includes(_limit)) {
          var _bestAbsDiff
          var _newLimit
          options.forEach(function (option) {
            var _absDiff = Math.abs(option - _limit)
            if (_bestAbsDiff === undefined || _absDiff < _bestAbsDiff) {
              _bestAbsDiff = _absDiff
              _newLimit = option
            }
          })
          _limit = _newLimit
        }
        this.value = _limit
        this.prev = _limit
        $ctrl.notifyChange()
      },
    }

    $ctrl.pages = {
      input: 1,
      value: 1,
      total: undefined,
      first: function () {
        if (this.value > 1) {
          this.value = 1
          $ctrl.notifyChange()
        }
      },
      prev: function () {
        if (this.value > 1) {
          this.value = this.value - 1
          $ctrl.notifyChange()
        }
      },
      next: function () {
        if (this.value < this.total) {
          this.value = this.value + 1
          $ctrl.notifyChange()
        }
      },
      last: function () {
        if (this.value < this.total) {
          this.value = this.total
          $ctrl.notifyChange()
        }
      },
      change: function (event) {
        if (event.type !== 'blur' && (event.type !== 'keydown' || event.key !== 'Enter')) {
          return
        }
        var page
        if (isNaN(+this.input)) {
          this.input = this.value
        } else {
          page = Math.min(Math.max(+this.input, 1), this.total)
        }
        event.target.blur()
        if (page != null && this.value !== page) {
          this.value = page
          $ctrl.notifyChange()
        }
      },
      update: function (changes) {
        var notifyChange = false

        if (Object.prototype.hasOwnProperty.call(changes, 'limit')) {
          this.value = 1 + Math.floor((this.value - 1) * changes.limit.old / changes.limit.new)
          this.total = Math.ceil($ctrl.total / changes.limit.new)
          notifyChange = true
        }

        if (
          Object.prototype.hasOwnProperty.call(changes, 'total') &&
          changes.total != null
        ) {
          this.total = Math.ceil(changes.total / $ctrl.limit.value)
        }

        if (
          Object.prototype.hasOwnProperty.call(changes, 'page') &&
          changes.page != null &&
          changes.page !== this.value
        ) {
          this.value = changes.page
          notifyChange = true
        }

        if (this.total > 0 && this.value > this.total) {
          this.value = this.total
          notifyChange = true
        }

        if (notifyChange) {
          $ctrl.notifyChange()
        }
      },
    }
  }
})()
