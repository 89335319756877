(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.technicalservice', ['ngSanitize'])
    .constant('ROLES', {
      TD: ['technical_service'],
      SC: ['servicecenter'],
    })
    .constant('TEXTS', {
      PANIC_ON: 'Bist du sicher, dass du den Panic Button benutzen möchtest?',
      PANIC_OFF: 'Bist du sicher, dass du den Panic Button ausmachen möchtest?',
      NOTICE_PANIC_ON: 'Hallo Du vom Service Center vor dem Monitor!<br><br>Im Moment brennt bei der Technik die Luft, eine dringende Baustelle braucht gerade die gesamte Aufmerksamkeit.<br><br>Daher:<br><br>&emsp;1. Notiere alle gewünschten Änderungen und gib sie am Ende der heißen Phase telefonisch oder per Mail an die Technik weiter.<br><br>&emsp;2. Informiere deine Anrufer über die Verzögerung bei der Bearbeitung und bitte um Verständnis, dass es bei uns gerade etwas dauert.<br><br>Über das Ende der Ruhephase wirst du informiert.',
      NOTICE_PANIC_OFF: 'Unsere Technik hat die Baustelle wieder geschlossen und jetzt ein offenes Ohr für deine Belange.<br><br>Du kannst gewünschte Änderungen daher wieder telefonisch oder per Mail an die Technik weiterreichen.',
      NOT_WORKING: 'Achtung! Fehler mit dem Panic Button, versuch\'s nochmal.',
    })
    .factory('TechnicalService', function (CONFIG, ROLES, $resource, $filter, UserService) {
      var api = {
        panic: $resource(CONFIG.API_URL + '/technicalservice/panic', {}, {
          get: { hideGlobalSpinner: true },
          save: { method: 'POST', hideGlobalSpinner: true },
        }),
      }

      var serviceInterface = {}
      serviceInterface.panic = api.panic
      serviceInterface.isAllowed = function (key) {
        return $filter('intersection')(ROLES[key], UserService.getRoles()).length > 0
      }

      return serviceInterface
    })
    .run(function ($rootScope, $compile, $mdDialog, $interval, TEXTS, LocalStorageService, TechnicalService) {
      var id = 'panic'
      var div
      var $scope = $rootScope.$new()

      var notify = function (oldValue, newValue) {
        if (!TechnicalService.isAllowed('SC')) {
          return
        }
        if (oldValue !== newValue) {
          var text = newValue ? TEXTS.NOTICE_PANIC_ON : TEXTS.NOTICE_PANIC_OFF
          $mdDialog.show(
            $mdDialog.alert()
              .title('ACHTUNG! BITTE LESEN! ACHTUNG! ZUR KENNTNIS NEHMEN! ACTUNG! BITTE LESEN! ACHTUNG!')
              .htmlContent(text)
              .ok('Okay')
          )
        }
      }

      var update = function (response) {
        var oldValue = $scope.active
        $scope.active = response.panic
        if ($scope.active) {
          LocalStorageService.set('panic', true)
        } else {
          LocalStorageService.remove('panic')
        }
        notify(oldValue, $scope.active)
      }

      $rootScope.$watch('globals.currentUser', function () {
        $scope.active = LocalStorageService.get('panic', false)

        // code for TD
        if (TechnicalService.isAllowed('TD')) {
          if (div) {
            return
          }
          div = document.createElement('div')
          div.id = id
          div.setAttribute('ng-show', 'globals.currentUser && globals.sessionChecked')
          div.setAttribute('ng-include', '\'src/technicalservice/views/panicbutton.html\'')
          document.body.appendChild(div)
          document.body.classList.add('panic')

          $scope.panic = function (event) {
            var text = $scope.active ? TEXTS.PANIC_OFF : TEXTS.PANIC_ON
            var confirm = $mdDialog.confirm().title('ACHTUNG!').textContent(text).ok('Ja').cancel('Nein').targetEvent(event)
            $mdDialog.show(confirm).then(
              function () {
                TechnicalService.panic.save({ panic: !$scope.active }).$promise.then(update).catch(
                  function () {
                    $mdDialog.show($mdDialog.alert().title('ACHTUNG!').textContent(TEXTS.NOT_WORKING).ok('Okay'))
                  }
                )
              }
            )
          }

          $compile(angular.element(div))($scope)
        } else {
          div = document.querySelector('#' + id)
          if (div) {
            document.body.removeChild(div)
          }
          div = undefined
        }

        if (TechnicalService.isAllowed('TD') || TechnicalService.isAllowed('SC')) {
          TechnicalService.panic.get().$promise.then(update)
          $rootScope.panicInterval = $interval(
            function () {
              TechnicalService.panic.get().$promise.then(update)
            },
            60 * 1000
          )
        } else {
          $interval.cancel($rootScope.panicInterval)
        }
      })
    })
})()
