(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager')
    .directive('omStore', StoreDirective)

  /**
   * @ngdoc directive
   * @name ottomatikStoreManager.directive:omStore
   * @restrict E
   * @element om-store
   *
   * @param {object} store The store object
   *
   * @example
   * <om-store store="store"></om-store>
   */
  function StoreDirective () {
    return {
      restrict: 'E',
      scope: {
        store: '<',
      },
      templateUrl: 'src/components/om-store.html',
    }
  }
})()
