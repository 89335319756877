(function () {
  'use strict'

  angular
    .module('om-otsstatus', [])
    .constant('COLORS', {
      green: 'green',
      red: 'red',
      grey: 'grey',
      lightgrey: 'grey-300',
      white: 'grey-A100',
      black: 'grey-A200',
    })
    .constant('DEVICES', [
      { code: 201, name: 'Kassensystem' },
      { code: 204, name: 'OSM Bestellannahme' },
      { code: 203, name: 'Fax' },
    ])
    .constant('SHOULD_BE_FINISHED_TIME', 5)
    .constant('TRANSMISSION_STATES', {
      noData: 'Keine Daten',
      loadingError: 'Datenfehler',
      inTransmission: 'In Vermittlung',
      success: 'Erfolg',
      failure: 'Fehlgeschlagen',
    })
    .component('omOtsstatus', {
      bindings: {
        omOrderid: '<',
        omOtsdata: '<',
        omTransmission: '<?',
        omWaitForData: '<?',
      },
      controller: Controller,
      controllerAs: 'omOtsstatus',
      templateUrl: 'src/components/om-otsstatus.html',
    })

  function Controller (COLORS, DEVICES, SHOULD_BE_FINISHED_TIME, TRANSMISSION_STATES, ServicecenterService, $rootScope) {
    var $ctrl = this
    $ctrl.show = 'loader'
    $ctrl.colors = COLORS

    $ctrl.$onInit = function () {
      $ctrl.devices = DEVICES.map(
        function (device) {
          return Object.assign({
            id: device.name[0],
            success: false,
          }, device)
        }
      )

      $ctrl.status = {
        display: TRANSMISSION_STATES.noData,
        backgroundColor: COLORS.lightgrey,
        message: undefined,
      }

      $ctrl.loading = false
      $ctrl.generalSuccess = false
      $ctrl.userInteraction = false
      $ctrl.someDeviceSuccess = false
      $ctrl.countErrors = 0
    }

    $ctrl.$onChanges = function (changesObj) {
      if ($ctrl.omTransmission === false) {
        $ctrl.show = 'notrans'
      } else if ($ctrl.omWaitForData && changesObj.omOtsdata && changesObj.omOtsdata.currentValue) {
        setStatus(changesObj.omOtsdata.currentValue)
      } else if (!$ctrl.omWaitForData && changesObj.omOrderid && changesObj.omOrderid.currentValue) {
        $ctrl.loadStatus()
      }
    }

    $ctrl.loadStatus = function () {
      if ($ctrl.loading) {
        return
      }
      $ctrl.loading = true
      $ctrl.show = 'loader'
      var promise = ServicecenterService.getOtsStatus($ctrl.omOrderid)
      promise.then(function (response) {
        setStatus(response[$ctrl.omOrderid])
      })
      promise.catch(function (error) {
        switch (error.status) {
          case 943:
            var unbind = $rootScope.$watch(
              function () { return ServicecenterService.allowRestart() },
              function (newValue, oldValue) {
                if (!angular.equals(newValue, oldValue)) {
                  $ctrl.loadStatus()
                  unbind()
                }
              }
            )
            break
          default:
            setStatus({
              error: true,
              cancel: error.status === 942,
            })
            break
        }
      })
      promise.finally(function () {
        $ctrl.loading = false
      })
    }

    $ctrl.evaluateDeviceStyles = function (device) {
      var backgroundColor = COLORS.lightgrey
      if (device.success) {
        backgroundColor = COLORS.green
      }
      return {
        color: COLORS.white,
        backgroundColor: backgroundColor,
      }
    }

    $ctrl.evaluateErrorStyles = function () {
      var backgroundColor = COLORS.lightgrey
      if ($ctrl.countErrors) {
        backgroundColor = COLORS.red
      }
      return {
        color: COLORS.white,
        backgroundColor: backgroundColor,
      }
    }

    function setStatus (data) {
      $ctrl.$onInit()

      if (data.error) {
        if (data.cancel) {
          $ctrl.show = 'cancel'
          return
        }
        showStatus(
          TRANSMISSION_STATES.loadingError,
          COLORS.red,
          'Die OTS Statusabfrage für ' + $ctrl.omOrderid + ' ist fehlgeschlagen!'
        )
        return
      }

      if (angular.isArray(data.states)) {
        for (var i = 0, len = data.states.length, state; i < len; i++) {
          state = data.states[i]
          if (state === 200 || state === 290) {
            $ctrl.generalSuccess = true
          } else if (state >= 200 && state < 300) {
            var device = $ctrl.devices.find(function (device) {
              return device.code === state
            })
            if (device) {
              device.success = true
              $ctrl.someDeviceSuccess = true
            }
          } else if (state >= 400 && state < 500) {
            $ctrl.countErrors++
          } else if (state >= 600 && state < 700) {
            $ctrl.userInteraction = true
          }
        }
      }

      if ($ctrl.generalSuccess || $ctrl.userInteraction || $ctrl.someDeviceSuccess) {
        showStatus(
          TRANSMISSION_STATES.success,
          COLORS.green
        )
      } else if (data.timestamp > (SHOULD_BE_FINISHED_TIME * 60)) {
        showStatus(
          TRANSMISSION_STATES.failure,
          COLORS.red,
          'Es liegen nach ' + SHOULD_BE_FINISHED_TIME + ' Minuten noch keine OTS Erfolgsmeldungen vor!'
        )
      } else if (data.timestamp === -1) {
        showStatus(
          TRANSMISSION_STATES.noData,
          COLORS.lightgrey
        )
      } else {
        showStatus(
          TRANSMISSION_STATES.inTransmission,
          COLORS.grey
        )
      }
    }

    function showStatus (display, color, message) {
      $ctrl.status.display = display
      $ctrl.status.backgroundColor = color
      $ctrl.status.message = message
      $ctrl.show = 'status'
    }
  }
})()
