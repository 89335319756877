;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.hrm')
    .value('DEFAULT_SETTINGS', {
      // drivers
      drivers: {
        dateFrom: null,
        dateTo: null,
        toursPerHour: 3,
        toursSurcharge: null,
        inhousePercentage: null,
        salesSurcharge: null,
      },

      // weeks
      currentWeekIndex: 0,
      weeks: [],
    })
    .value('DEFAULT_WEEK', {
      dateStart: null,

      // staff
      staff: {
        list: {},
        jobs: null,
        surcharge: {
          night: null,
          nightFrom: '23:00',
          nightTo: '06:00',
          sunday: null,
          holiday: null,
        },
      },

      // costs
      costs: {
        planDistributionCosts: null,
        planSales: [],
        planSalesDay: [],
        planSalesEvening: [],
        actualSales: [],
        actualHours: [],
        targetProductivity: null,
        targetStaffCosts: null,
      },

      // roster
      roster: {
        holidays: [],
        day: [],
        evening: [],
      },
    })
    .value('DEFAULT_JOBS', {
      FP: 'Franchise-Partner/in',
      BL: 'Betriebsleiter/in',
      SL: 'Schichtleiter/in',
      ID: 'Innendienst',
      IF: 'Innendienst Fest',
      B: 'Bäcker/in',
      T: 'Telefonist/in',
      FO: 'Fahrer/in',
      FE: 'Fahrer/in eig. KFZ',
      A1: 'Azubi 1. Lehrjahr',
      A2: 'Azubi 2. Lehrjahr',
      A3: 'Azubi 3. Lehrjahr',
    })
    .service('HRMService', HRMService)

  function HRMService(CONFIG, DEFAULT_SETTINGS, DEFAULT_WEEK, DEFAULT_JOBS, $filter, $resource, UUID) {
    var HRM = $resource(CONFIG.API_URL + '/hrm/:storeId/:action', { storeId: '@storeId' })

    return {
      getSettings: getSettings,
      getDriversData: getDriversData,
      getAvgShiftTotals: getAvgShiftTotals,
      getDailyTotals: getDailyTotals,
      getHours: getHours,
      newWeek: newWeek,
    }

    function getSettings(storeId) {
      return HRM.get({ storeId: storeId }).$promise.then(function (settings) {
        return applyDefaults(settings)
      })
    }

    function getDriversData(settings) {
      var params = {
        action: 'drivers',
        storeId: settings.storeId,
        dateFrom: $filter('date')(settings.drivers.dateFrom),
        dateTo: $filter('date')(settings.drivers.dateTo),
      }
      return HRM.get(params).$promise
    }

    function getAvgShiftTotals(storeId, week, qtyLastWeeks) {
      var dateFrom = week.dateStart
      var dateTo = moment(dateFrom).add(6, 'days').toDate()

      var params = {
        action: 'avg_totals',
        storeId: storeId,
        dateFrom: $filter('date')(dateFrom),
        dateTo: $filter('date')(dateTo),
        qtyLastWeeks: qtyLastWeeks,
      }
      return HRM.query(params).$promise
    }

    function getDailyTotals(storeId, week) {
      var dateFrom = week.dateStart
      var dateTo = moment(dateFrom).add(6, 'days').toDate()

      var params = {
        action: 'daily_totals',
        storeId: storeId,
        dateFrom: $filter('date')(dateFrom),
        dateTo: $filter('date')(dateTo),
      }
      return HRM.query(params).$promise
    }

    function getHours(week, from, to) {
      // ASSUMING: all dates are on the same day (e.g 1970-01-01 from inputs)

      var nightFrom = new Date(week.staff.surcharge.nightFrom)
      var nightTo = new Date(week.staff.surcharge.nightTo)
      if (nightTo < nightFrom) {
        nightTo.setDate(nightTo.getDate() + 1)
      }

      var dateFrom = new Date(from)
      var dateTo = new Date(to)
      if (dateTo < dateFrom) {
        dateTo.setDate(dateTo.getDate() + 1)
      }

      var totalHours = (dateTo - dateFrom) / 3600000
      var nightHours = 0
      if (nightFrom <= dateTo && nightTo >= dateFrom) {
        nightHours = (Math.min(nightTo, dateTo) - Math.max(nightFrom, dateFrom)) / 3600000
      }

      return {
        total: totalHours,
        day: totalHours - nightHours,
        night: nightHours,
      }
    }

    function newWeek(date) {
      if (!date) {
        date = moment().startOf('week').toDate()
      }
      var week = angular.copy(DEFAULT_WEEK)
      week.dateStart = date
      week.staff.jobs = Object.keys(DEFAULT_JOBS).reduce((jobs, tag) => {
        jobs[UUID.v4()] = {
          tag: tag,
          name: DEFAULT_JOBS[tag],
        }
        return jobs
      }, {})
      return week
    }

    function applyDefaults(settings) {
      v1_to_v2(settings)

      array_replace_recursive(settings, angular.copy(DEFAULT_SETTINGS), angular.fromJson(angular.toJson(settings)))

      if (settings.weeks.length == 0) {
        settings.weeks.push(newWeek())
      }

      return settings
    }

    function array_replace_recursive(arr) {
      // eslint-disable-line camelcase
      // https://github.com/locutusjs/locutus/blob/master/src/php/array/array_replace_recursive.js
      //  discuss at: https://locutus.io/php/array_replace_recursive/
      // original by: Brett Zamir (https://brett-zamir.me)
      //   example 1: array_replace_recursive({'citrus' : ['orange'], 'berries' : ['blackberry', 'raspberry']}, {'citrus' : ['pineapple'], 'berries' : ['blueberry']})
      //   returns 1: {citrus : ['pineapple'], berries : ['blueberry', 'raspberry']}

      var argl = arguments.length

      if (argl < 2) {
        throw new Error('There should be at least 2 arguments passed to array_replace_recursive()')
      }

      for (var i = 1, p; i < argl; i++) {
        for (p in arguments[i]) {
          if (p.startsWith('$')) {
            continue
          }
          if (arr[p] && typeof arr[p] === 'object') {
            arr[p] = array_replace_recursive(arr[p], arguments[i][p])
          } else {
            arr[p] = arguments[i][p]
          }
        }
      }

      return arr
    }

    function v1_to_v2(settings) {
      //         new     or     already v2
      if (settings.$init || settings.weeks) {
        return
      }

      var dateStart = settings.roster.start || moment().startOf('week').toDate()
      delete settings.roster.start

      var week = angular.copy({
        dateStart: dateStart,
        staff: settings.staff,
        costs: settings.costs,
        roster: settings.roster,
      })

      settings.currentWeekIndex = 0
      settings.weeks = [week]

      delete settings.staff
      delete settings.costs
      delete settings.roster
    }
  }
})()
