(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.ranking', [])
    .config(function ($stateRegistryProvider, moduleRegisterProvider) {
      var root = {
        name: 'ranking',
        url: '/ranking',
        templateUrl: 'src/ranking/views/ranking.html',
        controller: 'RankingController',
        data: {
          daysOffset: 0,
          months: {
            from: '2017-10',
          },
        },
      }

      var cap = {
        abstract: true,
        name: 'ranking_cap',
        url: '/ranking',
        templateUrl: 'src/ranking/views/cap/layout.html',
        controller: 'RankingCapController',
        data: {
          daysOffset: 1,
          months: {
            from: '2021-02',
          },
        },
      }

      var live = {
        name: 'ranking_cap.live',
        url: '/live',
        views: {
          list: {
            templateUrl: 'src/ranking/views/cap/live.html',
          },
        },
        data: {
          icon: 'speed',
          title: 'Live Performance',
          showLastLoad: true,
        },
      }

      var sales = {
        name: 'ranking_cap.sales',
        url: '/sales',
        views: {
          list: {
            templateUrl: 'src/ranking/views/cap/sales.html',
          },
        },
        data: {
          icon: 'euro',
          title: 'Online-Bestellungen und Umsatz',
          showDateRange: true,
        },
      }

      var times = {
        name: 'ranking_cap.times',
        url: '/times',
        views: {
          list: {
            templateUrl: 'src/ranking/views/cap/times.html',
          },
        },
        data: {
          icon: 'timer',
          title: 'Lieferzeiten',
          showDateRange: true,
        },
      }

      $stateRegistryProvider.register(root)
      $stateRegistryProvider.register(cap)
      $stateRegistryProvider.register(live)
      $stateRegistryProvider.register(sales)
      $stateRegistryProvider.register(times)

      moduleRegisterProvider.add({
        name: 'Store Ranking',
        materialIcon: 'leaderboard',
        accessRoles: ['admin', 'ranking'],
        url: 'ranking',
      })

      moduleRegisterProvider.add({
        name: 'Store Ranking',
        materialIcon: 'leaderboard',
        accessRoles: ['admin', 'ranking_cap'],
        arn: { 'Store Ranking (CaP)': ['admin', 'ranking'] },
        menu: [
          {
            name: 'Live Performance',
            url: 'ranking_cap.live',
          },
          {
            name: 'Bestellungen & Umsatz',
            url: 'ranking_cap.sales',
          },
          {
            name: 'Lieferzeiten',
            url: 'ranking_cap.times',
          },
        ],
      })
    })
})()
