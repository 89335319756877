;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.tutorials').service('TutorialService', TutorialService)

  function TutorialService(CONFIG, $resource, UserService) {
    this.getTutorials = getTutorials
    this.getTutorialsForState = getTutorialsForState
    this.createTutorial = createTutorial
    this.updateTutorial = updateTutorial
    this.registerEvent = registerEvent

    var Tutorial = $resource(CONFIG.API_URL + '/customers/:customerId/tutorials/:id/:action/:event', { id: '@id' })

    function getTutorials(customer) {
      var params = {
        customerId: customer.customerId,
      }
      return Tutorial.query(params).$promise
    }

    function getTutorialsForState(state) {
      var params = {
        customerId: UserService.getCustomerId(),
        state: state,
        hideGlobalSpinner: true,
      }
      return Tutorial.query(params).$promise
    }

    function createTutorial(customer, tutorial) {
      var params = {
        customerId: customer.customerId,
      }
      return Tutorial.create(params, tutorial).$promise
    }

    function updateTutorial(customer, tutorial) {
      var params = {
        customerId: customer.customerId,
        id: tutorial.id,
      }
      return Tutorial.update(params, tutorial).$promise
    }

    function registerEvent(tutorial, event) {
      var params = {
        customerId: tutorial.customer.customerId,
        id: tutorial.id,
        action: 'event',
        event: event,
        hideGlobalSpinner: true,
      }
      return Tutorial.get(params).$promise
    }
  }
})()
