(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager')
    .component('omOriginicons', {
      templateUrl: 'src/components/om-originicons.html',
      bindings: {
        customer: '<?',
        order: '<?',
        name: '<?',
        filter: '<?',
        type: '@?',
        omStyle: '@?',
      },
      controller: function ($attrs) {
        var $ctrl = this

        $ctrl.showInactive = Object.prototype.hasOwnProperty.call($attrs, 'showInactive')
        $ctrl.showSc = Object.prototype.hasOwnProperty.call($attrs, 'showSc')
        $ctrl.showTitle = Object.prototype.hasOwnProperty.call($attrs, 'showTitle')

        $ctrl.$onChanges = function () {
          $ctrl.update()
        }

        $ctrl.update = function () {
          $ctrl.margin = '0'
          $ctrl.error = false
          $ctrl.errReason = undefined
          try {
            if ($ctrl.name) {
              $ctrl.createFromName()
            } else if ($ctrl.order) {
              $ctrl.createFromOrder()
            } else {
              $ctrl.createFromCustomer()
            }
          } catch (err) {
            $ctrl.error = true
            $ctrl.errReason = err
          }
        }

        $ctrl.createFromName = function () {
          $ctrl.icons = [{
            filepath: $ctrl.getFilepath($ctrl.name),
            filepath2: $ctrl.getFilepath($ctrl.name, 2),
            style: $ctrl.getIconStyle(),
            title: $ctrl.name,
          }]
        }

        $ctrl.createFromOrder = function () {
          var filepath, filepath2, title
          if ($ctrl.order.customerSystemAssociation.system.origin.startsWith('ottomatik_')) {
            filepath = $ctrl.getFilepath($ctrl.customer.name)
            filepath2 = $ctrl.getFilepath($ctrl.customer.name, 2)
            title = $ctrl.customer.name
          } else {
            filepath = $ctrl.getFilepath($ctrl.order.customerSystemAssociation.system.origin)
            filepath2 = $ctrl.getFilepath($ctrl.order.customerSystemAssociation.system.origin, 2)
            title = $ctrl.order.customerSystemAssociation.system.name
          }
          $ctrl.icons = [{
            filepath: filepath,
            filepath2: filepath2,
            sc: $ctrl.order.customerSystemAssociation.sc,
            style: $ctrl.getIconStyle(),
            title: title,
          }]
        }

        $ctrl.createFromCustomer = function () {
          $ctrl.margin = $ctrl.type === 'logo' ? '1em' : '8px'
          var filter = $ctrl.filter || null
          $ctrl.icons = []
          for (var i = 0, len = $ctrl.customer.customerSystemAssociations.length; i < len; i++) {
            var csa = $ctrl.customer.customerSystemAssociations[i]
            if (!$ctrl.showInactive && !csa.active) {
              continue
            }
            if (angular.isObject(filter) && !angular.isArray(filter)) {
              var filtered = false
              angular.forEach(
                filter,
                function (value, key) {
                  if (key === 'id') {
                    key = 'customerSystemId'
                  }
                  value = angular.isArray(value) ? value : [value]
                  if (Object.prototype.hasOwnProperty.call(csa, key) && !value.includes(csa[key])) {
                    filtered = true
                  }
                }
              )
              if (filtered) {
                continue
              }
            }
            var filepath = null
            var filepath2 = null
            var title = null
            if (
              csa.system.origin === 'ottomatik_magento' &&
              (!angular.isArray(filter) || filter.indexOf('ottomatik') > -1)
            ) {
              filepath = $ctrl.getFilepath($ctrl.customer.name)
              filepath2 = $ctrl.getFilepath($ctrl.customer.name, 2)
              title = $ctrl.customer.name
            } else if (
              csa.system.origin !== 'ottomatik_magento' &&
              (!angular.isArray(filter) || (filter.indexOf('portal') > -1 && !csa.system.origin.endsWith('_pos')))
            ) {
              filepath = $ctrl.getFilepath(csa.system.origin)
              filepath2 = $ctrl.getFilepath(csa.system.origin, 2)
              title = csa.system.name
            }
            if (title) {
              var style = $ctrl.getIconStyle()
              if (!csa.active) {
                title += ' (inaktiv)'
                style.filter = 'brightness(0.5)'
              }
              $ctrl.icons.push({
                filepath: filepath,
                filepath2: filepath2,
                sc: csa.sc,
                style: style,
                title: title,
              })
            }
          }
        }

        var base = 'img/customers/'
        var ending = '.png'
        $ctrl.getFilepath = function (s, at) {
          if ($ctrl.type && $ctrl.type === 'logo' && at) {
            return null
          }
          var out = base
          if (s) {
            out += s.replace(/\W/g, '').toLowerCase().split('_')[0]
          } else {
            out += 'notfound'
          }
          out += ($ctrl.type && $ctrl.type === 'logo' ? '-logo' : '')
          out += (at ? '@' + at + 'x' : '')
          out += ending
          return out
        }

        $ctrl.getIconStyle = function () {
          var style
          if ($ctrl.type === 'logo') {
            style = { width: 'auto', height: '84px' }
            if ($ctrl.showTitle) {
              style['margin-right'] = '1em'
            }
          } else {
            style = { width: '32px', height: '32px' }
            if ($ctrl.showTitle) {
              style['margin-right'] = '.5em'
            }
          }
          return style
        }

        $ctrl.getStyle = function (first) {
          var style = {}
          if (!first) {
            style[$ctrl.omStyle === 'list' ? 'margin-top' : 'margin-left'] = $ctrl.margin
          }
          style.display = ($ctrl.omStyle === 'list' ? 'block' : 'inline-block')
          return style
        }
      },
    })
})()
