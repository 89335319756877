(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager')
    .component('omFeedbackStars', {
      bindings: {
        values: '<',
      },
      controller: FeedbackStarsController,
      controllerAs: '$ctrl',
      templateUrl: 'src/components/om-feedback-stars.html',
    })
    .constant('MAX_STARS', 5)
    .constant('STAR_COLOR', '#f7b627')

  function FeedbackStarsController (MAX_STARS, STAR_COLOR) {
    var $ctrl = this
    $ctrl.MAX_STARS = MAX_STARS
    $ctrl.STAR_COLOR = STAR_COLOR

    $ctrl.$onChanges = function (changesObj) {
      if (changesObj.values && changesObj.values.currentValue) {
        $ctrl.rating = calculateStarRating(changesObj.values.currentValue)
        $ctrl.percentage = calculateStarPercentage($ctrl.rating)
      }
    }

    function calculateStarRating (values) {
      var count = 0
      var sum = 0
      values.forEach(function (row) {
        count += +row.count
        sum += row.count * row.value
      })
      return count ? sum / count : null
    }

    function calculateStarPercentage (rating) {
      if (!rating) {
        return 0
      }
      return Math.round(rating * 2) / 2 / MAX_STARS * 100
    }
  }
})()
