;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.files').controller('FilesExplorerController', FilesExplorerController)

  function FilesExplorerController($scope, $state, $transitions, FileService) {
    var ctrl = this
    ctrl.files = []

    $scope.loadFiles = function () {
      FileService.getFiles().then(function (response) {
        ctrl.files = response
        scandir()
      })
    }

    $scope.cd = function (folder) {
      var path = ''
      if (folder) {
        path = (folder.dir ? folder.dir + '/' : '') + folder.name
      }
      $state.go('.', { folder: path })
    }

    $scope.download = function (file) {
      FileService.download(file.dir + '/' + file.name)
    }

    // initial loading
    changedir($state.params.folder || '')
    $scope.loadFiles()

    // listen for params change
    var deregisterTransitionHook = $transitions.onSuccess({ to: $state.current.name }, function (transition) {
      changedir(transition.params().folder || '')
      scandir()
    })

    $scope.$on('$destroy', function () {
      deregisterTransitionHook()
    })

    function generateBreadcrumbs(path) {
      if (!path) {
        return []
      }
      return path.split('/').map(function (dir, i, array) {
        if (array[i - 1]) {
          array[i] = array[i - 1] + '/' + dir
        }
        return {
          dir: array[i - 1] || '',
          name: dir,
        }
      })
    }

    function changedir(path) {
      ctrl.dir = path
      $scope.breadcrumbs = generateBreadcrumbs(path)
    }

    function scandir() {
      var exist = false
      $scope.explorer = ctrl.files.filter(function (object) {
        var path = object.dir + '/' + object.name
        exist = exist || path.startsWith(ctrl.dir)
        return object.dir === ctrl.dir
      })

      // if the directory does not exist, maybe the structure changed
      if (!exist && ctrl.dir !== '') {
        changedir('')
        scandir()
      }
    }
  }
})()
