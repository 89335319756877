(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager')
    .directive('omOpenSelect', omOpenSelect)

  function omOpenSelect ($timeout) {
    var directive = {
      link: link,
      require: 'ngModel',
      restrict: 'A',
    }
    return directive

    function link (scope, element, attrs, ngModel) {
      scope.$watch(attrs.omOpenSelect, function (value) {
        if (value && ngModel.$modelValue == null) {
          $timeout(function () {
            element[0].click()
          })
        }
      })
    }
  }
})()
