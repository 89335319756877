(function () {
  'use strict'

  angular
    .module('om-faxjobs', [])
    .component('omFaxjobs', {
      bindings: {
        orderId: '<omOrderid',
      },
      controller: FaxjobsController,
      templateUrl: 'src/components/om-faxjobs.html',
    })

  function FaxjobsController (CONFIG, $resource) {
    var $ctrl = this

    var Faxjobs = $resource(CONFIG.API_URL + '/orders/faxjobs/:orderId', { hideGlobalSpinner: true }, {
      cancel: { method: 'POST', isArray: true },
    })

    $ctrl.$onChanges = function (changesObj) {
      if (changesObj.orderId.currentValue == null) {
        $ctrl.info = undefined
        $ctrl.faxjobs = []
        return
      }
      $ctrl.load()
    }

    $ctrl.load = function () {
      $ctrl.info = {
        type: 'info',
        msg: 'Lädt…',
      }
      Faxjobs.get({ orderId: $ctrl.orderId }).$promise.then(
        function (result) {
          $ctrl.info = undefined
          $ctrl.faxjobs = result.fax_jobs.filter(
            function (faxjob) {
              return faxjob.is_active
            }
          )
          if ($ctrl.faxjobs.length === 0) {
            $ctrl.info = {
              type: 'info',
              msg: 'Keine laufenden Fax-Jobs!',
            }
          }
        }
      ).catch(
        function () {
          $ctrl.info = {
            type: 'warn',
            msg: 'Fehler beim Laden!',
          }
        }
      )
    }

    $ctrl.cancel = function () {
      $ctrl.info = {
        type: 'info',
        msg: 'Lädt…',
      }
      Faxjobs.cancel({ orderId: $ctrl.orderId }, null).$promise.finally($ctrl.load)
    }
  }
})()
