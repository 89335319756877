;(function () {
  'use strict'

  angular.module('ottomatikStoreManager').component('omStoreFastclose', {
    bindings: {
      store: '<',
      onUpdate: '<?',
    },
    controller: StoreFastclose,
    controllerAs: '$ctrl',
    templateUrl: 'src/components/om-store-fastclose.html',
    transclude: true,
  })

  function StoreFastclose($attrs, $element, $mdDialog, $scope, StoreService) {
    var $ctrl = this
    $ctrl.loading = false
    $ctrl.oph = undefined
    $ctrl.fastclose = undefined
    $ctrl.reasons = [
      {
        title: 'Überlastung',
      },
      {
        title: 'Personalmangel',
      },
      {
        title: 'technische Probleme',
      },
      {
        title: 'Freitext',
        select: function (event) {
          var self = this
          delete self.text
          var dialog = $mdDialog.prompt({
            title: 'Schließung: Freitext',
            placeholder: 'Grund',
            required: true,
            ok: 'Setzen',
            cancel: 'Abbrechen',
            targetEvent: event,
          })
          $mdDialog
            .show(dialog)
            .then(function (reason) {
              self.text = reason
            })
            .catch(function () {
              delete self.text
            })
        },
      },
    ]
    $ctrl.reason = $ctrl.reasons[0]

    $ctrl.hourOptions = [0, 1, 2, 3, 4]
    $ctrl.minuteOptions = {
      0: ':00',
      15: ':15',
      30: ':30',
      45: ':45',
    }

    $ctrl.transclude = $attrs.hasOwnProperty('transclude')

    $ctrl.$postLink = function () {
      $element.css({
        display: 'block',
      })
      $element.find('md-select').css({
        display: 'inline-block',
        margin: '8px 0 10px 0',
      })
    }

    $ctrl.$onChanges = function (changesObj) {
      if (changesObj.store && changesObj.store.currentValue) {
        if (changesObj.store.currentValue.api === 'Magento') {
          loadOpeningHours()
        } else {
          $ctrl.oph = undefined
          $ctrl.fastclose = undefined
          triggerOnUpdate()
        }
      }
    }

    $ctrl.open = function ($event) {
      return update(false)
    }

    $ctrl.close = function ($event, data) {
      if (data.hours != null && data.minutes != null) {
        return update({
          hours: data.hours,
          minutes: data.minutes,
        })
      }
      return update(true)
    }

    function loadOpeningHours() {
      $ctrl.loading = true
      triggerOnUpdate()
      return responseHandler(StoreService.getOpeningHours(getServiceParams()))
    }

    function update(fastclose) {
      var data = {
        fastclose: fastclose,
        createReport: true,
      }
      if (fastclose) {
        data.reason = $ctrl.reason.text || $ctrl.reason.title
      }
      $ctrl.loading = true
      triggerOnUpdate()
      return responseHandler(StoreService.updateOpeningHours(getServiceParams(), data))
    }

    function getServiceParams() {
      return {
        customerId: $ctrl.store.customer.customerId,
        storeId: $ctrl.store.storeId,
        hideGlobalSpinner: true,
      }
    }

    function responseHandler(promise) {
      promise
        .then(function (oph) {
          $ctrl.oph = oph
          $ctrl.fastclose = getFastcloseDate(oph.fastclose)

          // reset form
          $ctrl.hours = undefined
          $ctrl.minutes = undefined
          $ctrl.reason = $ctrl.reasons[0]
          if ($scope.form) {
            $scope.form.$setPristine()
            $scope.form.$setUntouched()
          }
        })
        .finally(function () {
          $ctrl.loading = false
          triggerOnUpdate()
        })
    }

    function triggerOnUpdate() {
      var output = {
        loading: $ctrl.loading,
        oph: $ctrl.oph,
      }
      if (angular.isFunction($ctrl.onUpdate)) {
        $ctrl.onUpdate(output)
      }
    }
  }

  function getFastcloseDate(uts) {
    if (!angular.isNumber(uts)) {
      return
    }
    var end = moment(uts * 1000)
    if (end.isBefore()) {
      return
    }
    return end.toDate()
  }
})()
