;(function () {
  'use strict'

  angular
    .module('om-servicecenterstatus', [])
    .constant('SC_STATES', {
      default: 'Offen',
      attention: 'Achtung',
      attention_check_sum: {
        icon: 'person_search',
        name: 'Bestellwert',
      },
      attention_check_sum_in_progress: 'In Arbeit (Bestellwert)',
      in_progress: 'In Arbeit',
      decision_is_fraud: 'Erledigt (Betrug)',
      decision_not_fraud: 'Offen (Kein Betrug)',
      finished_ok: 'Erledigt',
      finished_called: 'Erledigt (Anruf)',
      error_request: 'Request Fehler',
      error_response: 'Response Fehler',
      unknown: 'Status unbekannt',
      not_sc_covered: 'Live – Kein SC',
    })
    .constant('SC_COLORS', {
      green: 'green',
      blue: 'blue',
      purple: 'purple',
      orange: 'orange',
      red: 'red',
      grey: 'grey',
      lightgrey: 'grey-300',
      white: 'grey-A100',
      black: 'grey-A200',
    })
    .component('omServicecenterstatus', {
      bindings: {
        orderId: '<omOrderid',
        active: '<omScActive',
        data: '<omScdata',
        waitForData: '<omWaitForData',
      },
      controller: ServicecenterStatusController,
      templateUrl: 'src/components/om-servicecenterstatus.html',
    })

  function ServicecenterStatusController(
    $filter,
    $mdDialog,
    $q,
    $scope,
    SC_COLORS,
    SC_STATES,
    OrderService,
    ServicecenterService
  ) {
    var $ctrl = this
    $ctrl.colors = SC_COLORS

    $ctrl.$onChanges = function (changes) {
      if ($ctrl.data) {
        updateVariables($ctrl.data)
        return
      }

      if ($ctrl.active) {
        if ($ctrl.waitForData) {
          return
        }
        $ctrl.loadScStatus()
        return
      }

      setViewInactive()
    }

    function setScStatus(scStatus) {
      if (!$ctrl.orderId || !$ctrl.data) {
        setErrorRequest()
        return $q.reject()
      }

      var data = {
        sc_status: scStatus,
        visible_sc_status: $ctrl.data.scStatus,
      }

      setViewLoader()
      return ServicecenterService.setServicecenterStatus($ctrl.orderId, data)
        .then((response) => {
          if ('success' in response && response.success === 1) {
            $ctrl.data.scStatus = scStatus
            $ctrl.data.scUser = $ctrl.data.currentUser
            if ('sc_updated_at' in response) {
              $ctrl.data.scUpdatedAt = response.sc_updated_at
            }
            updateVariables($ctrl.data)
            return $q.resolve()
          }

          if ('success' in response && response.success === 0) {
            var alert = $mdDialog
              .alert()
              .title('Achtung!')
              .textContent(response.error || 'Ein unbekannter Fehler ist aufgetreten.')
              .ok('OK')
            $mdDialog.show(alert).then(() => {
              if ('refresh' in response) {
                $ctrl.data.scStatus = response.refresh.sc_status
                $ctrl.data.scUser = response.refresh.sc_user
                $ctrl.data.scUpdatedAt = response.refresh.sc_updated_at
                updateVariables($ctrl.data)
              }
            })
            return $q.reject()
          }

          setErrorResponse()
          return $q.reject()
        })
        .catch(() => {
          setErrorResponse()
          return $q.reject()
        })
    }

    $ctrl.loadScStatus = function () {
      if (!$ctrl.orderId) {
        setErrorRequest()
        return
      }

      setViewLoader()
      ServicecenterService.getServicecenterStatus($ctrl.orderId)
        .then((response) => {
          if ('sc_data' in response) {
            var scData = response.sc_data[$ctrl.orderId] || {}
            var data = {
              orderId: scData.increment_id,
              scStatus: scData.sc_status,
              scUser: scData.sc_user,
              scUpdatedAt: scData.sc_updated_at,
              currentUser: response.current_user,
            }
            updateVariables(data)
            return
          }

          setErrorResponse()
        })
        .catch((error) => {
          switch (error.status) {
            case 942:
              setViewCancel()
              break
            case 943:
              var deregisterWatch = $scope.$watch(
                () => ServicecenterService.allowRestart(),
                (newValue, oldValue) => {
                  if (newValue !== oldValue) {
                    $ctrl.loadScStatus()
                    deregisterWatch()
                  }
                }
              )
              break
            default:
              setErrorResponse()
              break
          }
        })
    }

    $ctrl.btnClickProgress = function (event) {
      if (!$ctrl.btnActiveInProgress) {
        return
      }
      var status = 'in_progress'
      switch ($ctrl.data.scStatus) {
        case 'attention_check_sum':
          status = 'attention_check_sum_in_progress'
          break
      }
      setScStatus(status)
    }

    $ctrl.btnClickFinishedCalled = function (event) {
      if (!$ctrl.btnActiveFinishedCalled) {
        return
      }
      var status = 'finished_called'
      setScStatus(status)
    }

    $ctrl.btnClickFinishedOk = function (event) {
      if (!$ctrl.btnActiveFinishedOk) {
        return
      }
      var status = 'finished_ok'
      setScStatus(status)
    }

    $ctrl.btnClickFraudCheckDecision = function (event) {
      if (!$ctrl.btnActiveFraudCheckDecision) {
        return
      }

      $mdDialog
        .show({
          templateUrl: 'src/components/om-servicecenterstatus-fraud.html',
          targetEvent: event,
          focusOnOpen: false,
          controller: function () {
            this.sendMail = true
            this.isFraud = (isFraud) => {
              $mdDialog.hide({
                isFraud: isFraud,
                sendMail: isFraud ? this.sendMail : undefined,
              })
            }
            this.cancel = () => {
              $mdDialog.cancel()
            }
          },
          locals: {
            orderId: $ctrl.orderId,
          },
          bindToController: true,
          controllerAs: 'dialog',
        })
        .then((result) => {
          var status = result.isFraud ? 'decision_is_fraud' : 'decision_not_fraud'
          setScStatus(status).then(() => {
            if (result.isFraud) {
              var params = {
                cancel: true,
                storno: false,
                create_report: true,
                send_email: result.sendMail,
                reason: 'fraud',
                reason_title: 'Betrug',
              }
              OrderService.cancelOrder($ctrl.orderId, params)
            }
          })
        })
    }

    $ctrl.btnClickCancel = function (event) {
      if (!$ctrl.btnActiveCancel) {
        return
      }
      var status = 'default'
      switch ($ctrl.data.scStatus) {
        case 'attention_check_sum_in_progress':
          status = 'attention_check_sum'
          break
      }
      setScStatus(status)
    }

    // UTILS

    function updateVariables(data) {
      // var data = {
      //   [error,]
      //   [cancel,]
      //   orderId,
      //   scStatus,
      //   scUser,
      //   scUpdatedAt,
      //   currentUser,
      // }

      if (data.error) {
        if (data.cancel) {
          data.scStatus = 'cancel'
        }
        if (!data.scStatus) {
          data.scStatus = 'error_response'
        }
      }

      resetVariables()
      $ctrl.name = getDisplayName(data)
      $ctrl.nameBgColor = getBackgroundColor(data)

      if (data.scUpdatedAt) {
        var user = data.scUser || 'Unbekannt'
        var date = moment(data.scUpdatedAt + '+00:00').toDate()
        $ctrl.title = 'Von ' + strToTitleCase(user) + ' am ' + $filter('date')(date, 'medium') + ' aktualisiert'
      }

      var fraudCheckReason
      switch (data.scStatus) {
        case 'default':
          $ctrl.btnActiveInProgress = true
          $ctrl.btnActiveFinishedOk = true
          break

        case 'attention':
          $ctrl.isAttention = true
          $ctrl.btnActiveInProgress = true
          $ctrl.btnActiveFinishedOk = true
          break

        case 'in_progress':
          $ctrl.btnActiveFinishedCalled = true
          $ctrl.btnActiveFinishedOk = true
          $ctrl.btnActiveCancel = true
          break

        case 'finished_ok':
        case 'finished_called':
          $ctrl.btnActiveInProgress = true
          break

        case 'attention_check_sum':
          $ctrl.isAttention = true
          $ctrl.isFraudCheck = true
          fraudCheckReason = 'hoher Bestellwert'
          $ctrl.btnActiveInProgress = true
          break

        case 'attention_check_sum_in_progress':
          $ctrl.isFraudCheck = true
          fraudCheckReason = 'hoher Bestellwert'
          if ($ctrl.data.scUser == $ctrl.data.currentUser) {
            $ctrl.btnActiveFraudCheckDecision = true
            $ctrl.btnActiveCancel = true
          }
          break

        case 'not_sc_covered':
        case 'decision_is_fraud':
        case 'decision_not_fraud':
          break

        case 'cancel':
          setViewCancel()
          return

        case 'inactive':
          setViewInactive()
          return

        default:
          $ctrl.btnShowReload = true
          break
      }

      if ($ctrl.isFraudCheck) {
        $ctrl.title +=
          '\n\n⚠\n' +
          'Die Bestellung wurde als verdächtig eingestuft.' +
          (fraudCheckReason ? ' (Grund: ' + fraudCheckReason + ')' : '') +
          '\nDie Vermittlung wurde vorerst unterbunden. Die Bestellung bitte auf Betrug überprüfen.'
      }

      setViewStatus()
    }

    function resetVariables() {
      delete $ctrl.name
      delete $ctrl.nameBgColor
      delete $ctrl.title

      $ctrl.btnActiveInProgress = false
      $ctrl.btnActiveFinishedCalled = false
      $ctrl.btnActiveFinishedOk = false
      $ctrl.btnActiveFraudCheckDecision = false
      $ctrl.btnActiveCancel = false
      $ctrl.btnShowReload = false

      $ctrl.isAttention = false
      $ctrl.isFraudCheck = false
    }

    function getDisplayName(data) {
      var name = SC_STATES[data.scStatus] || SC_STATES.unknown
      if (angular.isObject(name)) {
        $ctrl.nameIcon = name.icon
        name = name.name
      } else {
        delete $ctrl.nameIcon
      }
      return name
    }

    function getBackgroundColor(data) {
      var color = SC_COLORS.blue

      switch (data.scStatus) {
        case 'attention':
        case 'attention_check_sum':
        case 'error_request':
        case 'error_response':
          color = SC_COLORS.red
          break

        case 'in_progress':
        case 'attention_check_sum_in_progress':
          if (data.scUser == data.currentUser) {
            color = SC_COLORS.purple
          } else {
            color = SC_COLORS.orange
          }
          break

        case 'finished_ok':
        case 'finished_called':
        case 'not_sc_covered':
        case 'decision_is_fraud':
          color = SC_COLORS.green
          break
      }

      return color
    }

    function strToTitleCase(str) {
      return str.match(/(?:[0-9]+|cron|redirect)/g)
        ? str
        : str
            .replace(/_/g, ' ')
            .replace(/(?!von\b)\b\S+/g, (part) => part.charAt(0).toUpperCase() + part.substr(1).toLowerCase())
    }

    // ERROR

    function setErrorRequest() {
      var data = {
        error: true,
        scStatus: 'error_request',
      }
      updateVariables(data)
    }

    function setErrorResponse() {
      var data = {
        error: true,
        scStatus: 'error_response',
      }
      updateVariables(data)
    }

    // CHANGE VIEW FUNCTIONS

    function setViewStatus() {
      $ctrl.view = 'status'
    }
    function setViewCancel() {
      $ctrl.view = 'cancel'
    }
    function setViewInactive() {
      $ctrl.view = 'inactive'
    }
    function setViewLoader() {
      $ctrl.view = 'loader'
    }
  }
})()
