;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.transmissions').controller('TransmissionsController', TransmissionsController)

  function TransmissionsController(
    $filter,
    $interval,
    $mdDialog,
    $mdSidenav,
    $scope,
    AudioService,
    CatalogService,
    CustomerService,
    DeliveryTimesService,
    TransmissionsService,
    UserService,
    TRANSMISSIONS_NOTIFICATION_ID,
    TRANSMISSIONS_RELOAD_SECONDS,
    TRANSMISSIONS_WARN_THRESHOLD
  ) {
    var NOW = {
      BE: null,
      FE: null,
    }

    $scope.allStores = UserService.hasRole(['admin', 'storesall'])
    $scope.readOnly = !UserService.hasRole('transmissions')
    $scope.userHasStoresettings = UserService.hasRole(['admin', 'storesettings'])
    $scope.warnThreshold = TRANSMISSIONS_WARN_THRESHOLD * 60 * 1000 // minutes to milliseconds

    function reset() {
      $scope.transmissions = {}
      $scope.times = null
      $scope.error = false
    }
    reset()

    function initCustomerSelect() {
      CustomerService.getCustomers().$promise.then((response) => {
        $scope.customers = response.filter((customer) => customer.customerSystemAssociations.length)
      })

      if (!$scope.customerSelect) {
        $scope.customerSelect = function (customerId, customer) {
          $scope.customerId = customerId
          $scope.customer = customer
          reset()
          reload.stop()
          notify.stop()
          timers.stop()
          delete $scope.store
          initStoreSelect(customer)
        }
      }
    }

    function initStoreSelect(customer) {
      CustomerService.getStores(customer.customerId).$promise.then((response) => {
        $scope.stores = response
        if (response.length === 1) {
          var store = response[0]
          $scope.storeSelect(store.storeId, store)
        }
      })

      if (!$scope.storeSelect) {
        $scope.storeSelect = function (storeId, store) {
          $scope.storeId = storeId
          $scope.store = store
          reset()
          reload.stop()
          notify.stop()
          timers.stop()
          loadTransmissions()
        }
      }
    }

    function loadTransmissions() {
      if (!$scope.store || $scope.loading || !$scope.filter.limit) {
        return
      }

      loadDeliveryTimes()
      loadCatalogSellout()

      var params = {
        storeId: $scope.store.storeId,
        limit: $scope.filter.limit,
        page: $scope.filter.page,
      }

      $scope.loading = true
      $scope.transmissionsPromise = TransmissionsService.getTransmissions(params)
      $scope.transmissionsPromise
        .then((response) => {
          $scope.error = false
          $scope.finishedTotal = response.finishedTotal
          NOW.BE = new Date(response.now)
          NOW.FE = new Date()
          $scope.transmissions = {
            pending: response.pending,
            finished: response.finished,
          }
          $scope.maps = response.maps
          updateMapAddresses()
        })
        .catch(() => {
          $scope.error = true
        })
        .finally(() => {
          $scope.loading = false
          reload.init()
          notify.checkForNewTransmissions()
          timers.init()
          reloadOrderDetails()
        })

      return $scope.transmissionsPromise
    }

    function loadDeliveryTimes() {
      var timesParams = {
        customerId: $scope.customer.customerId,
        storeId: $scope.store.storeId,
        hideGlobalSpinner: true,
      }
      DeliveryTimesService.getTimes(timesParams).then((response) => {
        $scope.times = response
      })
    }

    function loadCatalogSellout() {
      CatalogService.getSellout($scope.customerId, $scope.storeId).$promise.then((response) => {
        $scope.hasCatalogSellout = response.items.length > 0
      })
    }

    function reloadOrderDetails() {
      var root = $scope.$root
      if (
        root.isSidenavOrderDetailsOpen &&
        root.transmissions[root.current].order &&
        root.transmissions[root.current].order.orderId
      ) {
        var transmissions = $scope.transmissions.pending.concat($scope.transmissions.finished)
        transmissions.some((transmission) => {
          if (transmission.order.orderId === root.transmissions[root.current].order.orderId) {
            fillRootWithData(transmission)
            return true
          }
          return false
        })
      }
    }

    function fillRootWithData(transmission, action) {
      var root = $scope.$root
      root.current = 0
      root.total = 1
      root.customer = $scope.customer
      root.hideSku = $scope.customer.options.hideSku === 1 && UserService.getCustomerId() !== 999999999
      root.transmissions = [transmission]
      if (action) {
        root.action = action
      }
      root.readOnly = $scope.readOnly
      root.warnThreshold = $scope.warnThreshold
      root.hasState = $scope.hasState
      root.getState = $scope.getState
      root.setState = $scope.setState
    }

    var reload = {
      current: 0,
      init: function () {
        this.current = TRANSMISSIONS_RELOAD_SECONDS
        if (!this.interval) {
          this.interval = $interval(() => {
            this.current--
            if (this.current === 0) {
              this.stop()
              loadTransmissions()
            }
          }, 1000)
        }
      },
      stop: function () {
        $interval.cancel(this.interval)
        delete this.interval
        this.current = 0
      },
    }

    var notify = {
      sound: AudioService('sound/sc.mp3'),
      playMs: 5000,
      init: function (readOnly) {
        this.enabled = !readOnly
      },
      play: function () {
        if (this.enabled && !this.playing) {
          this.sound.play()
          this.interval = $interval(() => {
            this.sound.play()
          }, this.playMs)
          this.playing = true
        }
      },
      stop: function () {
        $interval.cancel(this.interval)
        this.playing = false
      },
      checkForNewTransmissions: function () {
        if (
          $scope.transmissions &&
          $scope.transmissions.pending &&
          $scope.transmissions.pending.some &&
          $scope.transmissions.pending.some(
            (transmission) => transmission.currentState && transmission.currentState.state === 'loaded'
          )
        ) {
          this.play()
        } else {
          this.stop()
        }
      },
    }

    var timers = {
      init: function () {
        var length = 0
        if ($scope.transmissions && $scope.transmissions.pending) {
          length = $scope.transmissions.pending.length
        }
        if (length === 0) {
          this.stop()
          return
        }
        $scope.transmissions.pending.forEach((transmission) => {
          $filter('timer')(transmission, NOW.FE - NOW.BE)
        })
        if (!this.interval) {
          this.interval = $interval(() => {
            $scope.transmissions.pending.forEach((transmission) => {
              if (transmission.remainingTime && transmission.remainingTime.diff.getTime() > 0) {
                var newDate = new Date(transmission.remainingTime.goal - new Date(Date.now() - (NOW.FE - NOW.BE)))
                if (newDate.getTime() < 0) {
                  transmission.remainingTime.diff = new Date(0)
                } else {
                  transmission.remainingTime.diff = newDate
                }
              }
            })
          }, 1000)
        }
      },
      stop: function () {
        $interval.cancel(this.interval)
        delete this.interval
      },
    }

    $scope.filter = {
      updatePagination: function (pagination) {
        this.limit = pagination.limit
        this.page = pagination.page
        loadTransmissions()
      },
    }

    $scope.hasState = function (transmission, state) {
      return transmission.states.some((ts) => ts.state.startsWith(state))
    }

    $scope.getState = function (transmission, state) {
      return transmission.states.filter((ts) => ts.state.startsWith(state))[0] || {}
    }

    $scope.setState = function (transmission, state) {
      if ($scope.readOnly) {
        return
      }

      var params = {
        storeId: $scope.store.storeId,
      }
      var data = {
        orderId: transmission.order.orderId,
        state: state,
      }

      reload.stop()
      TransmissionsService.setState(params, data).finally(loadTransmissions)
    }

    $scope.showDate = function (dt) {
      var datetime = new Date(dt)
      var midnight = new Date()
      midnight.setHours(0, 0, 0, 0)
      return datetime < midnight
    }

    var mapIsOpen = false
    function updateMapAddresses() {
      if (mapIsOpen && $scope.updateMapAddresses) {
        $scope.updateMapAddresses($scope.transmissions.pending.map((transmission) => transmission.order))
      }
    }

    $scope.openMap = function (event) {
      $mdDialog
        .show({
          templateUrl: 'src/transmissions/views/dialog.map.html',
          targetEvent: event,
          controller: TransmissionsMapDialogController,
          locals: {
            customer: $scope.customer,
            apiKey: $scope.maps.apiKey,
            marker: $scope.maps.marker,
            orders: $scope.transmissions.pending.map((transmission) => transmission.order),
            parentScope: $scope,
          },
          bindToController: true,
          controllerAs: 'mapCtrl',
          fullscreen: true,
        })
        .finally(() => {
          mapIsOpen = false
        })
      mapIsOpen = true
    }

    $scope.openDetails = function (transmission, action) {
      if (transmission.currentState && transmission.currentState.state === 'loaded') {
        $scope.setState(transmission, 'progress')
      }
      fillRootWithData(transmission, action)
      $mdSidenav('orderDetails')
        .toggle()
        .then(() => {
          document.querySelector('#orderdetails > md-content').scrollTop = 0
        })
    }

    notify.init($scope.readOnly)

    $scope.$on('$destroy', () => {
      reload.stop()
      notify.stop()
      timers.stop()
    })

    if ($scope.allStores) {
      initCustomerSelect()
    } else {
      $scope.customerId = UserService.getCustomerId()
      CustomerService.getCustomer($scope.customerId).$promise.then((response) => {
        $scope.customer = response
        initStoreSelect(response)
      })
    }

    var notificationDiv = document.getElementById(TRANSMISSIONS_NOTIFICATION_ID)
    if (notificationDiv) {
      notificationDiv.style.display = 'none'
    }
  }

  function TransmissionsMapDialogController($mdDialog, GoogleMapsService) {
    var $ctrl = this
    var MAP_ELEMENT_ID = 'transmissions-map'

    $ctrl.closeDialog = function () {
      $mdDialog.hide()
    }

    $ctrl.parentScope.updateMapAddresses = function (orders) {
      updateAddresses($ctrl.map, $ctrl.marker, orders)
    }

    GoogleMapsService.load($ctrl.apiKey)
      .then(() => GoogleMapsService.importLibrary('visualization'))
      .then(() => {
        $ctrl.map = new google.maps.Map(document.getElementById(MAP_ELEMENT_ID), {
          rotateControl: false,
          streetViewControl: false,
        })

        if ($ctrl.marker) {
          $ctrl.marker = createCustomerMarker($ctrl.map, $ctrl.marker)
        }

        updateAddresses($ctrl.map, $ctrl.marker, $ctrl.orders)
      })

    function createCustomerMarker(map, data) {
      var icon
      var customer = $ctrl.customer
      if (customer.options.geocodingMarkerIcon) {
        icon = {
          url: customer.options.geocodingMarkerIcon,
          scaledSize: new google.maps.Size(48, 48),
        }
      }
      return new google.maps.Marker({
        position: new google.maps.LatLng(data.latitude, data.longitude),
        title: data.title,
        icon: icon,
        map: map,
      })
    }

    function createMarker(map, latitude, longitude, title, info) {
      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(latitude, longitude),
        title: title,
        map: map,
      })
      createInfoWindow(map, marker, info)
      return marker
    }

    function createInfoWindow(map, marker, content) {
      var info = new google.maps.InfoWindow({
        content: content,
      })
      marker.addListener('click', () => {
        info.open({
          anchor: marker,
          map: map,
        })
      })
      return info
    }

    var markerCache = {}
    function updateAddresses(map, storeMarker, orders) {
      var bounds = new google.maps.LatLngBounds()
      if (storeMarker) {
        bounds.extend(storeMarker.getPosition())
      }

      // create/update markers
      orders.reverse().forEach((order, index) => {
        if (!order.address) {
          return
        }

        if (!markerCache[order.orderId]) {
          markerCache[order.orderId] = createMarker(
            map,
            order.address.latitude,
            order.address.longitude,
            order.orderId,
            '<b>' + order.orderId + '</b><hr>' + getOrderAddress(order)
          )
        }
        var marker = markerCache[order.orderId]

        marker.setLabel(String(index + 1))
        bounds.extend(marker.getPosition())
      })

      // remove obsolete markers
      var orderIds = orders.map((order) => order.orderId)
      Object.keys(markerCache)
        .filter((orderId) => !orderIds.includes(orderId))
        .forEach((orderId) => {
          markerCache[orderId].setMap(null)
          delete markerCache[orderId]
        })

      // update bounds
      map.fitBounds(bounds)
    }

    function getOrderAddress(order) {
      var address = order.address
      return address.streetName + ' ' + address.streetNumber + '<br>' + address.postalCode + ' ' + address.city
    }
  }
})()
