;(function () {
  'use strict'

  angular.module('ottomatikStoreManager').component('omStepper', {
    bindings: {
      steps: '<',
      current: '<',
      proceed: '<',
      onClick: '&?',
    },
    controller: StepperController,
    controllerAs: 'stepper',
    templateUrl: 'src/components/om-stepper.html',
  })

  function StepperController() {
    var stepper = this

    stepper.goto = function (event, step) {
      if (step == stepper.current) {
        return
      }
      if (step > stepper.current + (stepper.proceed ? 1 : 0)) {
        return
      }
      if (angular.isFunction(stepper.onClick)) {
        stepper.onClick({ $event: event, step: step })
      }
    }
  }
})()
