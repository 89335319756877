(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager')
    .component('omTrend', {
      bindings: {
        past: '<',
        now: '<',
        moreBad: '<?',
      },
      templateUrl: 'src/components/om-trend.html',
    })
})()
