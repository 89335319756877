(function () {
  'use strict';

  angular
    .module('ottomatikStoreManager.dpos', [])
    .config(function ($stateProvider, moduleRegisterProvider) {
      $stateProvider.state('dpos', {
        url: '/dpos',
        views: {
          '@': { templateUrl: 'src/dpos/views/layout.html' },
          'colMain@dpos': {
            templateUrl: 'src/dpos/views/dpos.html',
            controller: 'DPosController',
          },
        },
      });

      moduleRegisterProvider.add({
        id: 'dpos',
        title: 'Kassensystem Status',
        defaultUrl: 'dpos',
        materialIcon: 'dvr',
        accessRoles: ['admin', 'servicecenter'],
      });
    })
    .controller('DPosController', function ($scope, UserService, CustomerService) {
      var self = this;

      self.reset = function () {
        $scope.statusData = undefined;
      };
      self.reset();

      self.initCustomerSelect = function () {
        CustomerService.getCustomers().$promise.then(function (customers) {
          $scope.customers = customers.filter(function (customer) {
            return customer.active;
          });
        });

        if (!$scope.customerSelect) {
          $scope.customerSelect = function (customerId) {
            $scope.customerId = customerId;
            self.reset();
            $scope.loadDPosStatus();
          };
        }
      };

      $scope.loadDPosStatus = function () {
        CustomerService.getDPos($scope.customerId).$promise.then(
          function (data) {
            $scope.statusData = data;
          },
          function () {
            $scope.statusData = [];
          }
        ).finally(
          function () {
            $scope.lastLoaded = new Date();
          }
        );
      };

      $scope.getColor4Status = function (status) {
        var color = 'grey-400';
        switch (status) {
          case true: color = 'green'; break;
          case false: color = 'red'; break;
        }
        return { color: color };
      };

      $scope.allStores = UserService.hasRole('admin') || UserService.hasRole('storesall');
      if ($scope.allStores) {
        self.initCustomerSelect();
      } else {
        $scope.customerId = UserService.getCustomerId();
        $scope.loadDPosStatus();
      }
    });
})();
