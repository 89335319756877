;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.administration', [])
    .config(function ($stateRegistryProvider, moduleRegisterProvider) {
      var root = {
        abstract: true,
        name: 'administration',
        url: '/administration',
        templateUrl: 'src/administration/views/layout.html',
      }

      var users = {
        name: 'administration.users',
        url: '/:customerId/users',
        params: {
          customerId: {
            dynamic: true,
            squash: true,
            type: 'int',
            value: null,
          },
        },
        views: {
          'colLeft@administration': {
            templateUrl: 'src/administration/views/user-list.html',
            controller: 'UserListController',
          },
        },
        resolve: {
          dataCustomers: function (CustomerService) {
            'ngInject'
            return CustomerService.getCustomers({ all: true }).$promise
          },
        },
      }

      var userDetails = {
        name: 'administration.users.details',
        url: '/:memberId/details',
        views: {
          'colRight@administration': {
            templateUrl: 'src/administration/views/user-details.html',
            controller: function ($scope, dataUser) {
              'ngInject'
              $scope.user = dataUser
            },
          },
        },
        resolve: {
          dataUser: function ($transition$, UserAdminService) {
            'ngInject'
            return UserAdminService.getUser($transition$.params().memberId)
          },
        },
      }

      var userEdit = {
        name: 'administration.users.edit',
        url: '/:memberId/edit',
        views: {
          'colRight@administration': {
            templateUrl: 'src/administration/views/user-form.html',
            controller: 'UserEditController',
          },
        },
        resolve: {
          dataUser: function ($transition$, UserAdminService) {
            'ngInject'
            return UserAdminService.getUser($transition$.params().memberId)
          },
          dataRoles: function (UserAdminService) {
            'ngInject'
            return UserAdminService.getRoles()
          },
          dataSystems: function ($transition$, CustomerService) {
            'ngInject'
            return CustomerService.getSystems($transition$.params().customerId).$promise
          },
          dataDebitors: function ($transition$, DebitorService) {
            'ngInject'
            return DebitorService.getDebitors($transition$.params().customerId)
          },
          dataStoregroups: function ($transition$, CustomerService) {
            'ngInject'
            return CustomerService.getStoregroups($transition$.params().customerId).$promise
          },
          dataStores: function ($transition$, CustomerService) {
            'ngInject'
            return CustomerService.getStores($transition$.params().customerId).$promise
          },
        },
      }

      var userroles = {
        name: 'administration.userroles',
        url: '/userroles',
        views: {
          'colLeft@administration': {
            templateUrl: 'src/administration/views/userrole-list.html',
            controller: function ($scope, $state, $mdDialog, UserAdminService, dataRoles) {
              'ngInject'
              $scope.roles = dataRoles

              $scope.roleDelete = function (roleId) {
                var dialogTitle = 'Benutzerrolle löschen'
                var dialogText =
                  'Achtung! Das kann dazu führen das die zugewiesenen Benutzer keine Berechtigungen mehr haben! Soll diese Benutzerrolle wirklich gelöscht werden?'
                var confirm = $mdDialog
                  .confirm()
                  .title(dialogTitle)
                  .ariaLabel(dialogTitle)
                  .textContent(dialogText)
                  .ok('OK')
                  .cancel('Abbrechen')

                $mdDialog.show(confirm).then(function () {
                  UserAdminService.deleteRole(roleId).then(function (response) {
                    if (response.deleted) {
                      $state.go('administration.userroles', {}, { reload: true })
                    }
                  })
                })
              }
            },
          },
        },
        resolve: {
          dataRoles: function (UserAdminService) {
            'ngInject'
            return UserAdminService.getRoles()
          },
        },
      }

      var userroleDetails = {
        name: 'administration.userroles.details',
        url: '/:roleId/details',
        views: {
          'colRight@administration': {
            templateUrl: 'src/administration/views/userrole-details.html',
            controller: function ($scope, dataRole) {
              'ngInject'
              dataRole.users = dataRole.users.filter(function (user) {
                return user.state === 'active'
              })
              $scope.role = dataRole
            },
          },
        },
        resolve: {
          dataRole: function ($transition$, UserAdminService) {
            'ngInject'
            return UserAdminService.getRole($transition$.params().roleId)
          },
        },
      }

      var userroleNew = {
        name: 'administration.userroles.new',
        url: '/new',
        views: {
          'colRight@administration': {
            templateUrl: 'src/administration/views/userrole-form.html',
            controller: 'UserroleFormController',
          },
        },
        resolve: {
          dataRole: function () {
            return undefined
          },
          dataRoles: function (UserAdminService) {
            'ngInject'
            return UserAdminService.getRoles()
          },
          dataPermissions: function (UserAdminService) {
            'ngInject'
            return UserAdminService.getPermissions()
          },
        },
      }

      var userroleEdit = {
        name: 'administration.userroles.edit',
        url: '/:roleId/edit',
        views: {
          'colRight@administration': {
            templateUrl: 'src/administration/views/userrole-form.html',
            controller: 'UserroleFormController',
          },
        },
        resolve: {
          dataRole: function ($transition$, UserAdminService) {
            'ngInject'
            return UserAdminService.getRole($transition$.params().roleId)
          },
          dataRoles: function (UserAdminService) {
            'ngInject'
            return UserAdminService.getRoles()
          },
          dataPermissions: function (UserAdminService) {
            'ngInject'
            return UserAdminService.getPermissions()
          },
        },
      }

      var oejwt = {
        name: 'administration.oejwt',
        url: '/oejwt',
        views: {
          '@': {
            templateUrl: 'src/administration/views/oejwt.html',
            controller: 'OejwtController',
          },
        },
      }

      var reporting = {
        name: 'administration.reporting',
        url: '/reporting',
        views: {
          '@': {
            templateUrl: 'src/administration/views/reporting.html',
            controller: 'ReportingConfigController',
          },
        },
      }

      $stateRegistryProvider.register(root)

      $stateRegistryProvider.register(users)
      $stateRegistryProvider.register(userDetails)
      $stateRegistryProvider.register(userEdit)

      $stateRegistryProvider.register(userroles)
      $stateRegistryProvider.register(userroleDetails)
      $stateRegistryProvider.register(userroleNew)
      $stateRegistryProvider.register(userroleEdit)

      $stateRegistryProvider.register(oejwt)

      $stateRegistryProvider.register(reporting)

      moduleRegisterProvider.add({
        name: 'Administration',
        materialIcon: 'build',
        accessRoles: ['admin'],
        menu: [
          { name: 'Benutzerverwaltung', url: 'administration.users' },
          { name: 'Benutzerrollen', url: 'administration.userroles' },
          { name: 'OEJWT Schlüssel', url: 'administration.oejwt' },
          { name: 'Benachrichtigungen', url: 'administration.reporting' },
        ],
      })
    })
})()
