;(function () {
  'use strict'

  angular.module('ottomatikStoreManager.storesettings').controller('OpeningHoursController', OpeningHoursController)

  function OpeningHoursController($scope) {
    var $ctrl = this

    $scope.$on('STORE_SETTINGS_LOAD', (event, data) => {
      $ctrl.store = data.store
    })

    $ctrl.updateOph = (update) => {
      if (angular.isObject(update)) {
        if ('loading' in update) {
          $ctrl.loading = update.loading
        }
        if ('oph' in update) {
          $ctrl.oph = update.oph
        }
      }
    }

    $ctrl.info =
      'Schließe den Store für die angegebene Dauer oder (ohne Angabe) für den restlichen heutigen Tag bis 6 Uhr am nächsten Tag.\n' +
      'Die Popup-Message wird keine Möglichkeit zur Vorbestellung oder zum Anrufen ausweisen.\n' +
      'Die Öffnungszeiten werden nur außerhalb der Öffnungszeiten aufgeführt.'
  }
})()
