(function () {
  'use strict'

  angular
    .module('om-ordertable', ['om-otsstatus', 'om-servicecenterstatus', 'om-otsdetail'])
    .component('omOrdertable', {
      bindings: {
        customer: '<omCustomer',
        orderDetails: '<omOrderDetails',
      },
      controller: OrdertableController,
      controllerAs: 'omOrdertable',
      templateUrl: 'src/components/om-ordertable.html',
    })
    .constant('STATUS_COMBINED_LOADING', true)

  function OrdertableController (STATUS_COMBINED_LOADING, $interval, $scope, $state, $window, ServicecenterService) {
    var $ctrl = this
    $ctrl.filter = {
      limit: undefined,
      page: undefined,
      updatePagination: function (pagination) {
        this.limit = pagination.limit
        this.page = pagination.page
        $ctrl.loadData()
      },
    }
    $ctrl.messages = []
    $ctrl.muted = false

    $ctrl.$onInit = function () {
      if ($scope.$parent.registerOrdertable) {
        $ctrl.muted = $scope.$parent.registerOrdertable(this)
      }
    }

    $ctrl.$onDestroy = function () {
      $ctrl.reloadCountdown.reloadCountdownDisable()
    }

    $ctrl.loadData = function (params) {
      if ($ctrl.ordersPromise && !$ctrl.ordersPromise.$$state.status) {
        ServicecenterService.cancelAll('silent', 'attention')
      }

      var customerId = $ctrl.customer.customerId
      var storeId = $ctrl.storeId
      if (params && Object.prototype.hasOwnProperty.call(params, 'storeId')) {
        $ctrl.filter.page = 1
        storeId = params.storeId || undefined
      }
      var page = $ctrl.filter.page
      var limit = $ctrl.filter.limit

      $ctrl.ordersPromise = ServicecenterService.getAttentionOrders(customerId, page, limit, storeId)
      $ctrl.ordersPromise
        .then(
          function (response) {
            $ctrl.storeId = storeId
            $ctrl.orders = response.records
            $ctrl.ordersTotal = response.recordTotal
            $ctrl.messages = response.messages
            $ctrl.magentoBaseUrl = response.magentoBaseUrl
            $ctrl.matchingFirstLast = response.matchingFirstLast
            $ctrl.orderIdsNotFound = response.orderIdsNotFound
            getCombinedOtsStatus()
          }
        )
        .catch(
          function (error) {
            $ctrl.messages = []
            if (error.status === -1) {
              $ctrl.messages.push({
                type: 'error',
                message: 'Keine Verbindung!',
              })
            } else {
              $ctrl.messages.push({
                type: 'error',
                message: 'Die Datenabfrage für ' + $ctrl.customer.name + ' ist fehlgeschlagen!',
              })
            }
          }
        )
        .finally(
          function () {
            $ctrl.reloadCountdown.reloadCountdownStart()
          }
        )
    }

    $ctrl.btnReload = $ctrl.loadData

    $ctrl.btnDetails = function (order) {
      if (typeof $ctrl.orderDetails === 'function') {
        ServicecenterService.setMagentoBaseUrl($ctrl.magentoBaseUrl)
        $ctrl.orderDetails($ctrl.customer, order)
      }
    }

    $ctrl.btnDetailsNew = function (order) {
      $window.open($state.href('servicecenter.archive', { id: order.orderId }), '_blank')
    }

    $scope.$on('RELOADCOUNTDOWNENABLEALL', function () {
      if (!$ctrl.reloadCountdown.enabled) {
        $ctrl.reloadCountdown.reloadCountdownEnable()
      }
    })
    $scope.$on('RELOADCOUNTDOWNDISABLEALL', function () {
      if ($ctrl.reloadCountdown.enabled) {
        $ctrl.reloadCountdown.reloadCountdownDisable()
      }
    })

    // RELOAD COUNTDOWN
    $ctrl.reloadCountdown = {
      enabled: true,
      max: 60,
      current: 0,
      showLabel: false,
      promise: undefined,
      reloadCountdownToggle: function () {
        if (!this.enabled) {
          this.reloadCountdownEnable()
        } else {
          this.reloadCountdownDisable()
        }
      },
      reloadCountdownStart: function () {
        if (!this.enabled) {
          return
        }
        this.showLabel = true
        this.current = this.max
        if (this.promise) {
          return
        }
        this.promise = $interval(function () {
          if ($ctrl.reloadCountdown.current === 0) {
            $interval.cancel($ctrl.reloadCountdown.promise)
            $ctrl.reloadCountdown.promise = undefined
            $ctrl.loadData()
          } else {
            $ctrl.reloadCountdown.current--
          }
        }, 1000)
      },
      reloadCountdownStop: function () {
        if (this.promise) {
          $interval.cancel(this.promise)
          this.promise = undefined
        }
        this.showLabel = false
        this.current = 0
      },
      reloadCountdownEnable: function () {
        this.enabled = true
        $ctrl.loadData()
      },
      reloadCountdownDisable: function () {
        this.enabled = false
        this.reloadCountdownStop()
      },
    }

    // OTS STATUS
    $ctrl.statusCombinedLoading = STATUS_COMBINED_LOADING
    function getCombinedOtsStatus () {
      if (!STATUS_COMBINED_LOADING) {
        return
      }
      var orderIds = []
      angular.forEach($ctrl.orders, function (order) {
        orderIds.push(order.orderId)
      })
      if (!orderIds.length) {
        return
      }
      ServicecenterService.getOtsStatus(orderIds.join())
        .then(
          function (response) {
            angular.forEach($ctrl.orders, function (order) {
              if (Object.prototype.hasOwnProperty.call(response, order.orderId)) {
                order.otsstatus = response[order.orderId]
              } else {
                order.otsstatus = {
                  error: true,
                }
              }
            })
          }
        ).catch(
          function (error) {
            switch (error.status) {
              case 943:
                var deregisterWatch = $scope.$watch(
                  ServicecenterService.allowRestart,
                  function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                      getCombinedOtsStatus()
                      deregisterWatch()
                    }
                  }
                )
                break
              default:
                angular.forEach($ctrl.orders, function (order) {
                  order.otsstatus = {
                    error: true,
                    cancel: error.status === 942,
                  }
                })
            }
          }
        )
    }
  }
})()
