;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.administration')
    .controller('UserListController', UserListController)
    .controller('UserEditController', UserEditController)
    .controller('UserroleFormController', UserroleFormController)

  function UserListController(
    $filter,
    $mdDialog,
    $scope,
    $transition$,
    dataCustomers,
    LocalStorageService,
    UserAdminService,
    UserService
  ) {
    $scope.customers = dataCustomers

    if ($transition$.params().customerId) {
      LocalStorageService.set('customer', $transition$.params().customerId)
    }

    $scope.customerId = LocalStorageService.get('customer') || UserService.getCustomerId()

    $scope.filter = {
      user: { id: '', state: true },
      member: { id: '', name: '', email: '' },
      clear: function () {
        this.user.id = this.member.id = this.member.name = this.member.email = ''
        this.user.state = true
      },
      updateUsers: function () {
        $scope.users = $filter('filter')($scope.allUsers, function (user) {
          var show = true
          var filter = $scope.filter
          var member = user.member
          show = show && (!filter.user.id || user.userId === +filter.user.id)
          show = show && (!filter.user.state || user.state === 'active')
          show = show && (!filter.member.id || member.memberId === +filter.member.id)
          show =
            show &&
            (!filter.member.name ||
              member.name.toLowerCase().indexOf(filter.member.name.toLowerCase()) > -1 ||
              (member.addition || '').toLowerCase().indexOf(filter.member.name.toLowerCase()) > -1)
          show =
            show && (!filter.member.email || member.email.toLowerCase().indexOf(filter.member.email.toLowerCase()) > -1)
          return show
        })
      },
    }

    $scope.customerSelect = function () {
      $scope.filter.clear()
      $scope.loadUsers()
    }

    $scope.loadUsers = function () {
      UserAdminService.getUsers($scope.customerId).then(function (users) {
        $scope.allUsers = users
        $scope.filter.updateUsers()
      })
    }

    $scope.userDelete = function (user) {
      if (user.member.memberId) {
        var dialogTitle = 'Benutzer löschen?'
        var dialogText = 'Soll der Benutzer-Zugang für ' + user.member.name
        if (user.member.addition) {
          dialogText += ' (' + user.member.addition + ')'
        }
        dialogText += ' wirklich gelöscht werden?'
        var confirm = $mdDialog.confirm().title(dialogTitle).textContent(dialogText).ok('OK').cancel('Abbrechen')

        $mdDialog.show(confirm).then(function () {
          UserAdminService.deleteUser(user.member.memberId).finally(function () {
            $scope.loadUsers()
          })
        })
      }
    }
  }

  function UserEditController(
    $filter,
    $mdDialog,
    $scope,
    $state,
    dataDebitors,
    dataRoles,
    dataStores,
    dataStoregroups,
    dataSystems,
    dataUser,
    helperService,
    ClipboardService,
    Notification,
    PasswordService,
    UserAdminService
  ) {
    var self = this
    dataUser.roles = dataUser.roles.map(function (role) {
      return role.roleId
    })
    dataUser.systems = dataUser.systems.map(function (system) {
      return system.systemId
    })
    dataUser.storegroups = dataUser.storegroups.map(function (storegroup) {
      return storegroup.storegroupId
    })
    var userBackup = angular.copy(dataUser)

    $scope.formTitle =
      'Benutzer-Zugang bearbeiten :: ' +
      dataUser.member.forename +
      ' ' +
      dataUser.member.lastname +
      (dataUser.member.addition !== null ? ' (' + dataUser.member.addition + ')' : '')
    $scope.user = dataUser
    $scope.user.state = dataUser.state === 'active'
    $scope.roles = dataRoles
    $scope.systems = dataSystems
    $scope.setRefUser = function () {
      helperService.setRefUser($scope.user, $scope.systems)
    }
    $scope.selectUserSystem = function () {
      helperService.selectUserSystem($scope.user)
    }
    $scope.debitorsAll = dataDebitors
    $scope.debitors = dataDebitors
    $scope.storegroups = dataStoregroups
    $scope.storesAll = dataStores
    $scope.stores = dataStores
    $scope.storeAssignmentVariant = dataUser.debitors.length > 0 ? 'byDebitor' : 'byStore'

    $scope.generatePassword = function () {
      var password = PasswordService.generateRandomPassword(8, { incUpper: false, incSymbols: false })
      $scope.user.password = password
      $scope.user.passwordVerify = password
      var message = '<p><b>Passwort:</b> <i>' + password + '</i></p>'
      if (ClipboardService.copy(password)) {
        message += '<p>In die Zwischenablage kopiert!</p>'
      }
      Notification.info(message)
    }

    // init array with selected store IDs
    self.initStoresSelected = function () {
      $scope.debitorsSelected = []
      $scope.storesSelectedAllow = []
      $scope.storesSelectedDeny = []

      for (var debitor of dataUser.debitors) {
        $scope.debitorsSelected.push(debitor.debitorId)
      }

      for (var allow of dataUser.storesAllow) {
        $scope.storesSelectedAllow.push(allow.storeId)
      }

      for (var deny of dataUser.storesDeny) {
        $scope.storesSelectedDeny.push(deny.storeId)
      }
    }

    // reset data in previous tab if the new one becomes active
    $scope.changeStoreAssignmentVariant = function (tabIndexNew) {
      var tabIndexOld = $scope.storeAssignmentVariant

      if (tabIndexOld !== tabIndexNew) {
        var dialogTitle = 'Store-Zuordnung ändern'
        var dialogText =
          'Achtung: beim Ändern der Store-Zuordnungsmethode werden die alten/vorherigen Werte gelöscht! Sollen die bisherigen Store-Zuordnungen entfernt werden?'
        var confirm = $mdDialog
          .confirm()
          .title(dialogTitle)
          .ariaLabel(dialogTitle)
          .textContent(dialogText)
          .ok('OK')
          .cancel('Abbrechen')

        $mdDialog.show(confirm).then(
          function () {
            self.initStoresSelected()

            switch (tabIndexNew) {
              case 0:
                break
              case 1:
                $scope.user.storegroups = undefined
                break
            }
            $scope.storeAssignmentVariant = tabIndexNew
          },
          function () {
            $scope.storeAssignmentVariant = tabIndexOld
          }
        )
      }
    }

    $scope.updateStores = function (term) {
      if (!term) {
        $scope.stores = $scope.storesAll
        return
      }
      $scope.stores = $filter('filter')($scope.storesAll, function (store) {
        return (
          store.name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
          (store.foreignIdent || '').toLowerCase().indexOf(term.toLowerCase()) > -1 ||
          (store.foreignIdent || '').toLowerCase().replace(' ', '').indexOf(term.toLowerCase()) > -1
        )
      })
    }

    $scope.updateDebitors = function (term) {
      if (!term) {
        $scope.debitors = $scope.debitorsAll
        return
      }
      $scope.debitors = $filter('filter')($scope.debitorsAll, function (debitor) {
        var show = debitor.debitorId.toString() === term
        for (var i = 0, len = debitor.debitorStoreAssociations.length; i < len; i++) {
          var store = debitor.debitorStoreAssociations[i].store
          show =
            show ||
            store.name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
            (store.foreignIdent || '').toLowerCase().indexOf(term.toLowerCase()) > -1 ||
            (store.foreignIdent || '').toLowerCase().replace(' ', '').indexOf(term.toLowerCase()) > -1
        }
        return show
      })
    }

    $scope.addDebitor = function (debitorId) {
      if ($scope.debitorsSelected.indexOf(debitorId) === -1) {
        $scope.debitorsSelected.push(debitorId)
      }
    }

    $scope.removeDebitor = function (debitorId) {
      var debitorsSelectedNew = []
      for (var debitor of $scope.debitorsSelected) {
        if (debitor !== debitorId) {
          debitorsSelectedNew.push(debitor)
        }
      }
      $scope.debitorsSelected = debitorsSelectedNew
    }

    $scope.addStoreAllow = function (storeId) {
      if ($scope.storesSelectedAllow.indexOf(storeId) === -1) {
        $scope.storesSelectedAllow.push(storeId)
      }
    }

    $scope.removeStoreAllow = function (storeId) {
      var storesSelectedAllowNew = []
      for (var allow of $scope.storesSelectedAllow) {
        if (allow !== storeId) {
          storesSelectedAllowNew.push(allow)
        }
      }
      $scope.storesSelectedAllow = storesSelectedAllowNew
    }

    $scope.addStoreDeny = function (storeId) {
      if ($scope.storesSelectedDeny.indexOf(storeId) === -1) {
        $scope.storesSelectedDeny.push(storeId)
      }
    }

    $scope.removeStoreDeny = function (storeId) {
      var storesSelectedDenyNew = []
      for (var deny of $scope.storesSelectedDeny) {
        if (deny !== storeId) {
          storesSelectedDenyNew.push(deny)
        }
      }
      $scope.storesSelectedDeny = storesSelectedDenyNew
    }

    $scope.cancel = function () {
      $state.go('administration.users.details', { memberId: userBackup.member.memberId })
    }

    $scope.reset = function () {
      $scope.user = angular.copy(userBackup)
      $scope.user.state = userBackup.state === 'active'
      self.initStoresSelected()
      $scope.userForm.$setPristine()
      $scope.userForm.$setUntouched()
    }

    $scope.save = function () {
      $scope.user.storeAssignmentVariant = $scope.storeAssignmentVariant
      // submit only the values for the current store-assignment variant
      if ($scope.storeAssignmentVariant === 'byStore') {
        $scope.user.debitors = []
        $scope.user.storegroups = $scope.user.storegroups === undefined ? [] : $scope.user.storegroups
        $scope.user.storesAllow = $scope.storesSelectedAllow
        $scope.user.storesDeny = $scope.storesSelectedDeny
      } else if ($scope.storeAssignmentVariant === 'byDebitor') {
        $scope.user.debitors = $scope.debitorsSelected === undefined ? [] : $scope.debitorsSelected
        $scope.user.storegroups = []
        $scope.user.storesAllow = []
        $scope.user.storesDeny = []
      }

      UserAdminService.editUser($scope.user.member.memberId, $scope.user).then(function (user) {
        if (user.userId !== null) {
          $state.go('administration.users', {}, { reload: true })
        }
      })
    }

    self.initStoresSelected()
  }

  function UserroleFormController($scope, $state, dataPermissions, dataRole, dataRoles, UserAdminService) {
    var self = this

    if (dataRole === undefined) {
      // new role
      dataRole = { roleId: 0, permissions: [] }
      $scope.formTitle = 'Neue Benutzerrolle'
    } else {
      // edit role
      $scope.formTitle = 'Benutzerrolle bearbeiten :: ' + dataRole.name
      if (dataRole.parents.length > 0) {
        dataRole.parents = dataRole.parents[0].roleId
      } // zZ kann jede rolle erstmal nur ein parent haben
    }

    var roleBackup = angular.copy(dataRole)
    $scope.role = dataRole
    $scope.rolesAll = dataRoles
    $scope.permissionsAll = dataPermissions

    // init array with selected permission IDs
    self.initPermissionsSelected = function () {
      $scope.permissionsSelected = []
      for (var perm of dataRole.permissions) {
        $scope.permissionsSelected.push(perm.permissionId)
      }
    }

    $scope.addPermission = function (permissionId) {
      $scope.permissionsSelected.push(permissionId)
    }

    $scope.removePermission = function (permissionId) {
      var permissionsSelectedNew = []
      for (var perm of $scope.permissionsSelected) {
        if (perm !== permissionId) {
          permissionsSelectedNew.push(perm)
        }
      }
      $scope.permissionsSelected = permissionsSelectedNew
    }

    $scope.cancel = function () {
      if (dataRole.roleId === 0) {
        $state.go('administration.userroles', {})
      } else {
        $state.go('administration.userroles.details', { roleId: dataRole.roleId })
      }
    }

    $scope.reset = function () {
      $scope.role = angular.copy(roleBackup)
      self.initPermissionsSelected()
      $scope.roleForm.$setPristine()
      $scope.roleForm.$setUntouched()
    }

    $scope.save = function () {
      var role = $scope.role
      role.permissions = $scope.permissionsSelected

      if (role.roleId === 0) {
        // new role
        UserAdminService.createRole(role).then(function (role) {
          if (role.roleId !== null) {
            $state.go('administration.userroles.details', { roleId: role.roleId }, { reload: true })
          }
        })
      } else {
        // edit role
        UserAdminService.editRole(role.roleId, role).then(function (role) {
          if (role.roleId !== null) {
            $state.go('administration.userroles.details', { roleId: role.roleId }, { reload: true })
          }
        })
      }
    }

    self.initPermissionsSelected()
  }
})()
