;(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.help', [])
    .config(function ($stateProvider, moduleRegisterProvider) {
      $stateProvider.state('help', {
        url: '/help',
        views: {
          '@': { templateUrl: 'src/help/views/layout.html' },
          'colMain@help': {
            templateUrl: 'src/help/views/index.html',
            controller: 'HelpIndexController',
          },
        },
      })

      moduleRegisterProvider.add({
        name: 'Hilfe & Service',
        url: 'help',
        icon: 'help',
        accessRoles: ['user', '^minimal-ui'],
      })
    })
    .controller('HelpIndexController', function ($mdSidenav, $scope, UserService) {
      $scope.customerId = UserService.getCustomerId()

      $scope.userHasReporting = document.body.classList.contains('reporting')
      if ($scope.userHasReporting) {
        $scope.openReporting = function (event) {
          $mdSidenav('reporting').open()
        }
      }
    })
})()
