(function () {
  'use strict'

  angular
    .module('ottomatikStoreManager.ranking')
    .factory('RankingService', RankingService)

  function RankingService (CONFIG, $filter, $resource, googleChartApiPromise, ChartService) {
    var Ranking = $resource(CONFIG.API_URL + '/customers/:customerId/ranking/:type', { customerId: 0 })
    var TransmissionStatus = $resource(CONFIG.API_URL + '/orders/transmissionstatus')

    var service = {
      get: function (params) {
        if (params.customerId === 2 && params.type) {
          return Ranking.query(params).$promise
        }
        return TransmissionStatus.query(params).$promise
      },
      addCharts: addCharts,
      getSalesChart: getSalesChart,
    }
    return service

    function addCharts (ranking, customer) {
      googleChartApiPromise.then(
        function () {
          ranking.forEach(
            function (data) {
              data.charts = {
                sales: getSalesChart(data, customer, { startHour: 6 }),
              }
            }
          )
        }
      )
    }

    function getSalesChart (data, customer, config) {
      config = Object.assign({
        startHour: 0,
        endHour: 24,
      }, config)
      if (config.startHour >= config.endHour) {
        config.endHour += 24
      }

      var multipleSystems = data.systems.length > 1
      var chart = {
        type: 'ComboChart',
        data: new google.visualization.DataTable(),
        view: undefined,
        options: {
          backgroundColor: 'transparent',
          curveType: 'function',
          focusTarget: 'category',
          fontName: 'Roboto',
          fontSize: 14,
          hAxis: {
            format: 'H:mm',
            minorGridlines: { count: 0 },
          },
          legend: {
            position: 'top',
            textStyle: { fontSize: 12 },
          },
          series: [],
          title: config.title || 'Umsatz [Linien] und Bestellungen [Säulen] pro Stunde',
          titleTextStyle: {
            fontSize: 16,
          },
          vAxes: [
            {
              title: 'Bestellungen',
              minorGridlines: { count: 0 },
              viewWindow: { min: 0 },
            },
            {
              title: 'Umsatz (brutto)',
              minorGridlines: { count: 0 },
              viewWindow: { min: 0 },
            },
          ],
          $systemColumnIndexes: {},
        },
      }

      chart.data.addColumn('timeofday', 'Stunde')

      // prepare sum columns
      if (multipleSystems) {
        chart.data.addColumn('number', 'Bestellungen Gesamt')
        chart.data.addColumn('number', 'Umsatz Gesamt')

        chart.options.vAxes.push(
          {
            textPosition: 'none',
            gridlines: { count: 0 },
            minorGridlines: { count: 0 },
            viewWindow: { min: -2, max: -1 },
          }
        )

        var targetAxisIndex = chart.options.vAxes.length - 1
        chart.options.series.push(
          {
            color: 'transparent',
            targetAxisIndex: targetAxisIndex,
            visibleInLegend: false,
          },
          {
            color: 'transparent',
            targetAxisIndex: targetAxisIndex,
            visibleInLegend: false,
          }
        )
      }

      var rows = []
      for (var hour = config.startHour; hour < config.endHour; hour++) {
        rows.push([
          {
            v: [hour, 30, 0],
            f: (hour % 24) + '–' + (hour % 24 + 1) + ' Uhr',
          },
        ])

        // init sum columns
        if (multipleSystems) {
          rows[rows.length - 1].push(
            0,
            {
              v: 0,
              f: $filter('currency')(0, customer),
            }
          )
        }
      }

      Object.values(data.systems)
        .forEach(function (_) {
          if (!_.system) {
            return
          }

          var columnIndexes = []
          columnIndexes.push(chart.data.addColumn('number', 'Bestellungen ' + _.system.name))
          columnIndexes.push(chart.data.addColumn('number', 'Umsatz ' + _.system.name))
          chart.options.$systemColumnIndexes[_.system.systemId] = columnIndexes

          angular.copy(_.hours)
            .rotate(config.startHour)
            .filter(function (_, index) {
              return index < rows.length
            })
            .forEach(function (data, index) {
              rows[index].push(
                data.count,
                {
                  v: data.total,
                  f: $filter('currency')(data.total, customer),
                }
              )

              // add to sum columns
              if (multipleSystems) {
                rows[index][1] += data.count
                rows[index][2].v += data.total
                rows[index][2].f = $filter('currency')(rows[index][2].v, customer)
              }
            })

          chart.options.series.push(
            {
              color: ChartService.getColor(_.system.name, true),
              type: 'bars',
              targetAxisIndex: 0,
              visibleInLegend: false,
            },
            {
              color: ChartService.getColor(_.system.name),
              type: 'line',
              targetAxisIndex: 1,
              labelInLegend: _.system.name,
            }
          )
        })

      chart.data.addRows(rows)
      chart.view = new google.visualization.DataView(chart.data)
      return chart
    }
  }
})()
